import { z } from "zod";

const orderFormSchema = z.object({
    firstName: z.string().nonempty("First name is required"),
    lastName: z.string().nonempty("Last name is required"),
    company: z.string().optional(),
    country: z.string().nonempty("Country is required"),
    address1: z.string().nonempty("Address line 1 required"),
    address2: z.string().optional(),
    city: z.string().nonempty("Town / City is required"),
    state: z.string().nonempty("State is required"),
    zip: z.string().nonempty("Zip code is required"),
    phone: z.string().nonempty("Phone is required"),
    email: z.string().email("Invalid email address").nonempty("Email is required"),
    cardNumber: z.string()
        .nonempty("Card Number is required")
        .regex(/^\d{4}\d{4}\d{4}\d{4}$/, "Invalid format (XXXXXXXXXXXXXXXX)"),
    expDate: z.string()
        .nonempty("Expiry Date is required")
        .regex(/^(0[1-9]|1[0-2])\/\d{2}$/, "Invalid format (MM/YY)"),
    cardCode: z.string().nonempty("CVV is required").regex(/^\d{3}$/, "Invalid CVV"),
    isOtherAddress: z.boolean(),
    new_shipping: z.object({
        new_shipping_city: z.string().optional(),
        new_shipping_state: z.string().optional(),
        new_shipping_country: z.string().optional(),
        new_shipping_zip: z.string().optional(),
        new_shipping_address1: z.string().optional(),
        new_shipping_address2: z.string().optional(),
        new_shipping_company: z.string().optional(),
        new_shipping_firstname: z.string().optional(),
        new_shipping_lastname: z.string().optional(),
        new_shipping_phone: z.string().optional(),
    }),
    orderNotes: z.string().optional(),
}).refine((data) => {
    if (data.isOtherAddress) {
        return data.new_shipping.new_shipping_firstname && data.new_shipping.new_shipping_lastname
            ? true
            : "First name and Last name are required"
    }
    return true
});

const orderFormInitialValues = (user: any, total: number) => {
    return {
        firstName: user?.name?.split(" ")[0] || "",
        lastName: user?.name?.split(" ")[1] || "",
        company: "",
        country: user?.country || "United States",
        address1: user?.address_1 || "",
        address2: "",
        city: user?.city || "",
        state: user?.state || "",
        zip: user?.zip || "",
        phone: user?.phone || "",
        email: user?.email || "",
        cardNumber: "",
        expDate: "",
        cardCode: "",
        amount: total,
        currency: "USD",
        isOtherAddress: false,
        new_shipping: {
            new_shipping_city: "",
            new_shipping_state: "",
            new_shipping_country: "",
            new_shipping_zip: "",
            new_shipping_address1: "",
            new_shipping_address2: "",
            new_shipping_company: "",
            new_shipping_firstname: "",
            new_shipping_lastname: "",
            new_shipping_phone: "",
        },
        orderNotes: "",
    };
};

export interface OrderForm {
    firstName: string;
    lastName: string;
    company: string;
    country: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    email: string;
    cardNumber: string;
    expDate: string;
    cardCode: string;
    amount: number;
    currency: string;
    isOtherAddress: boolean;
    new_shipping: {
        new_shipping_city: string;
        new_shipping_state: string;
        new_shipping_country: string;
        new_shipping_zip: string;
        new_shipping_address1: string;
        new_shipping_address2: string;
        new_shipping_company: string;
        new_shipping_firstname: string;
        new_shipping_lastname: string;
        new_shipping_phone: string;
    },
    orderNotes: string | undefined | null;
}

export { orderFormSchema, orderFormInitialValues };
