import { useForm } from "react-hook-form";
import VendorAddressForm from "../../components/common/ui/forms/vendor-address-form";
import { useGetProfileQuery, useUpdateDataMutation } from "../../features/slices/api-slices";
import { useEffect, useState } from "react";
import Dialog from "../../components/common/ui/forms/dialog/dialog";
import DragDropFile from "../../components/common/ui/drag-drop-upload";
import { useToast } from "../../components/shared/ToastContext";

export default function VendorProfileSettingsPage() {
    const [updateData, { isLoading }] = useUpdateDataMutation();
    const { data: user } = useGetProfileQuery();
    const { showToast } = useToast();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(
        {
            mode: 'onBlur',
            defaultValues: {
                fname: '',
                lname: '',
                email: '',
                phone: '',
                bio: '',
                instagram: '',
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        }
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [avatar, setAvatar] = useState<File | null>(null);

    const [files, setFiles] = useState<File[]>([]);

    const handleFilesAdded = (newFiles: File[]) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const onSubmit = async (data: any) => {
        try {
            await updateData({
                ...data,
                avatar,
                first_name: data.fname,
                last_name: data.lname
            }).unwrap();
            showToast("Profile updated successfully", "success", "Profile updated successfully")
        } catch (error) {
            showToast("Failed to update profile!", "error", "Failed to update profile");
        }
    };

    useEffect(() => {
        if (user) {
            setValue('fname', user.name.split(' ')[0]);
            setValue('lname', user.name.split(' ')[1]);
            setValue('email', user.email);
            setValue('phone', user.phone);
            setValue('bio', user.bio);
            setValue('instagram', user.instagram);
            setValue('facebook', user.facebook);
            setValue('twitter', user.twitter);
            setValue('linkedin', user.linkedin);
        }
    }, [user, setValue]);

    // const openDialog = () => {
    //     setIsDialogOpen(true);
    // }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    console.log(errors);

    return (
        <>
            {isLoading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <img loading="lazy" src="/assets/images/gif/spinner.svg" className="size-28" alt="" />
                </div>
            ) : (
                <>
                    <div className="w-full bg-white p-8 rounded-3xl">
                        <DragDropFile onFileAdded={handleFilesAdded} user={user} setAvatar={setAvatar} />
                        {files.length > 0 && (
                    <div>
                        <h2 className="text-xl font-bold">Files</h2>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}
                        <div className="px-4">
                            <h2 className="text-black text-[22px] font-medium mt-6 capitalize leading-snug">{user.name}</h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.fname ? "text-red-500" : "text-zinc-800"}`}>First Name *</label>
                                <input type="text" {...register("fname", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.fname ? "border-red-500" : "border-gray-200"}`} />
                                {errors.fname?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">First Name is required</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.lname ? "text-red-500" : "text-zinc-800"}`}>Last Name *</label>
                                <input type="text" {...register("lname", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.lname ? "border-red-500" : "border-gray-200"}`} />
                                {errors.lname?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Last Name is required</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.email ? "text-red-500" : "text-zinc-800"}`}>Email Address *</label>
                                <input type="text" {...register("email", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.email ? "border-red-500" : "border-gray-200"}`} />
                                {errors.email?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Email address is required</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.phone ? "text-red-500" : "text-zinc-800"}`}>Contact Number *</label>
                                <input type="text" {...register("phone", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.phone ? "border-red-500" : "border-gray-200"}`} />
                                {errors.phone?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Contact Number is required</p>}
                            </div>
                            <div className="w-full relative 2xl:col-span-2 xl:col-span-2 col-span-1">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.bio ? "text-red-500" : "text-zinc-800"}`}> About Description *</label>
                                <textarea rows={5} cols={20} {...register("bio", { required: true })} placeholder="About Description Write Here" className={`w-full resize-none border border-solid p-4 rounded-md outline-none px-4 ${errors.bio ? "border-red-500" : "border-gray-200"}`} />
                                {errors.bio?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">About Description is required</p>}
                            </div>
                            <div className="flex justify-end gap-8 2xl:col-span-2 xl:col-span-2 col-span-1">
                                <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                    Save
                                </button>
                                <button type="button" className="w-fit h-[46px] 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <VendorAddressForm />

                    <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                        <div className="flex justify-between items-center">
                            <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Social Profile Links</h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                            <div className="w-full relative flex items-center">
                                <label htmlFor="facebook" className="w-1/4 text-sm bg-white font-normal">Facebook</label>
                                <input type="text" {...register("facebook", {
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/,
                                        message: "Invalid Facebook URL"
                                    }
                                })} placeholder="Add Link Here..." className="w-3/4 border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                                {errors.facebook && <p role="alert" className="text-sm text-red-500 mt-2">{errors.facebook.message}</p>}
                            </div>
                            <div className="w-full relative flex items-center">
                                <label htmlFor="instagram" className="w-1/4 text-sm bg-white font-normal">Instagram</label>
                                <input type="text" {...register("instagram", {
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(.?)?]/,
                                        message: "Invalid Instagram URL"
                                    }
                                })} placeholder="Add Link Here..." className="w-3/4 border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                                {errors.instagram && <p role="alert" className="text-sm text-red-500 mt-2">{errors.instagram.message}</p>}
                            </div>
                            <div className="w-full relative flex items-center">
                                <label htmlFor="twitter" className="w-1/4 text-sm bg-white font-normal">Twitter</label>
                                <input type="text" {...register("twitter", {
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9(.?)?]/,
                                        message: "Invalid Twitter URL"
                                    }
                                })} placeholder="Add Link Here..." className="w-3/4 border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                                {errors.twitter && <p role="alert" className="text-sm text-red-500 mt-2">{errors.twitter.message}</p>}
                            </div>
                            <div className="w-full relative flex items-center">
                                <label htmlFor="linkedin" className="w-1/4 text-sm bg-white font-normal">LinkedIn</label>
                                <input type="text" {...register("linkedin", {
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?linkedin.com\/[a-zA-Z0-9(.?)?]/,
                                        message: "Invalid LinkedIn URL"
                                    }
                                })} placeholder="Add Link Here..." className="w-3/4 border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                                {errors.linkedin && <p role="alert" className="text-sm text-red-500 mt-2">{errors.linkedin.message}</p>}
                            </div>
                            <div className="flex justify-end gap-8 2xl:col-span-2 xl:col-span-2 col-span-1">
                                <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                    Save
                                </button>
                                <button type="button" className="w-fit h-[46px] 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )}

            <Dialog open={isDialogOpen} onClose={closeDialog} title="Save Changes" onConfirm={() => { }}>
                <p className='text-gray-400'>Are you sure to want to save your changes.</p>
            </Dialog>
        </>
    )
}
