import React from 'react'

const BannerWrapper = ({
    children
}: {
    children: React.ReactNode
}) => {
  return (
    <main className="md:my-8 my-3 max-w-screen-2xl mx-auto">
        {children}
    </main>
  )
}

export default BannerWrapper