import { z } from "zod";

const couponSchema = z.object({
    businessId: z.string().nonempty('Business is required'),
    productTitle: z.string().nonempty('Product title is required'),
    description: z.string().nonempty('Description is required'),
    discountType: z.enum(['percentage', 'fixed', 'free']),
    discount: z.string().nonempty('Discount is required'),
    couponLimit: z.union([
        z.string().transform(val => parseFloat(val)).refine(val => !isNaN(val) && val > 0, 'Coupon limit must be a positive number').optional(),
        z.literal('').optional(),
    ]),
    image: z.string().optional(),
    couponExpiry: z.string().optional(),
    isUnlimitedLimit: z.boolean().default(false),
    isUnlimitedTime: z.boolean().default(false),
    terms: z.string().nonempty('Terms are required'),
    isAvailable: z.boolean().default(true),
}).refine(data => {
    if (data.discountType === 'percentage' && parseFloat(data.discount) > 100) {
        return false;
    }
    return true;
}, {
    message: 'Percentage discount cannot exceed 100%'
}).refine(data => {
    if (data.isUnlimitedLimit && data.couponLimit) {
        return false;
    }
    return true;
}, {
    message: 'Coupon limit should be empty when Unlimited Coupon Limit is checked'
});

export default couponSchema;
