import { Link } from "react-router-dom";
import { Navbar } from "../../components/shared/navbar";
import Footer from "../../components/common/footer/footer";

export default function SiteMapPage() {
    return (
        <>
            <Navbar />

            <main className="container mx-auto mb-14">
                <h1 className="text-2xl font-medium mb-8">
                    Sitemap
                </h1>
                <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10">
                    <div className="w-full">
                        <h1 className="text-base font-medium">
                            Categories
                        </h1>
                        <ul className="flex flex-col gap-5 list-none mt-5">
                            <li className="text-sm">
                                <Link to={`/user-panel/wishlist`} className="hover:underline text-black/50 font-medium hover:text-black">Wishlist</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/my-orders`} className="hover:underline text-black/50 font-medium hover:text-black">Orders</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/magic-club`} className="hover:underline text-black/50 font-medium hover:text-black">Magic clubs</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/my-cart`} className="hover:underline text-black/50 font-medium hover:text-black">My Cart</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/my-question`} className="hover:underline text-black/50 font-medium hover:text-black">My Question</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/my-refunds`} className="hover:underline text-black/50 font-medium hover:text-black">My Refunds</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h1 className="text-base font-medium">
                            Account
                        </h1>
                        <ul className="flex flex-col gap-5 list-none mt-5">
                            <li className="text-sm">
                                <Link to={`/user-panel/profile`} className="hover:underline text-black/50 font-medium hover:text-black">My Account</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/auth/forgot-password`} className="hover:underline text-black/50 font-medium hover:text-black">Change Password</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/profile`} className="hover:underline text-black/50 font-medium hover:text-black">Profile</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/auth/signin`} className="hover:underline text-black/50 font-medium hover:text-black">Log in</Link>
                            </li>
                            <li className="text-sm">
                                <button type='button' className='flex items-center gap-2 border-none outline-none bg-transparent hover:underline text-black/50 text-sm font-medium hover:text-black' onClick={() => {
                                    localStorage.removeItem("token");
                                    window.location.assign("/");
                                }}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h1 className="text-base font-medium">
                            About Damndeal
                        </h1>
                        <ul className="flex flex-col gap-5 list-none mt-5">
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">Track your order</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">Product Guides</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/privacy-policy`} className="hover:underline text-black/50 font-medium hover:text-black">Privacy Policy</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/terms-and-conditions`} className="hover:underline text-black/50 font-medium hover:text-black">Terms & Conditions</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h1 className="text-base font-medium">
                            Customer Support
                        </h1>
                        <ul className="flex flex-col gap-5 list-none mt-5">
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">Contact us</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/need-help`} className="hover:underline text-black/50 font-medium hover:text-black">Help center</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={`/user-panel/need-help`} className="hover:underline text-black/50 font-medium hover:text-black">Need Help</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h1 className="text-base font-medium">
                            Mobile Apps
                        </h1>
                        <ul className="flex flex-col gap-5 list-none mt-5">
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">IOS App</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">Android App</Link>
                            </li>
                            <li className="text-sm">
                                <Link to={``} className="hover:underline text-black/50 font-medium hover:text-black">Gallery App</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
