import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useBuyEbookMutation } from '../../../features/api/ebook-api';
import { OrderForm } from '../../../features/schemas/order-schema';
import { useToast } from '../../shared/ToastContext';

interface CheckoutConfirmButtonProps {
    subtotal: number;
}

const CheckoutConfirmButton: React.FC<CheckoutConfirmButtonProps> = ({
    subtotal,
}) => {
    const navigate = useNavigate()
    const [refId, setRefId] = useState("")
    const [isOkay, setIsOkay] = useState(false)
    const { getValues, reset, formState: { errors }, handleSubmit } = useFormContext<OrderForm>();
    const { showToast } = useToast();
    const [createOrder, { isLoading, isSuccess, isError, error }] = useBuyEbookMutation()
    let taxPrice = 0;
    const total = 10;

    useEffect(() => {
        if (isSuccess) {
            showToast("Ebook bought successfully. Please check your email address for download link.!", "success", "Ebook Bought has successfully")
            reset();
            navigate(`/`)
        }
        if (isError && error) {
            showToast("There was an error creating the order. Please try again!", "error", (error as any)?.data?.message || (error as any)?.message || (error as any)?.msg || (error as any)?.data?.msg ||  "Error Occured while creating order")
        }
    }, [isSuccess, isError, error, navigate, reset])

    useEffect(() => {
        let refId = localStorage.getItem("refId")
        if (refId) {
            setRefId(refId)
        }
    }, [])

    const onSubmit = async () => {
        const values = getValues();
        if (!isOkay) {
            return showToast("Please accept the terms and conditions!", "error", "Please try again");
        }

        if (Object.keys(errors).length > 0) {
            showToast("Please enter all fields!", "error", (Object.values(errors)[0])?.message || "Please try again");
            return;
        }

        let dataToSend = {
            ...values,
            cardCode: "" + values.cardCode + "",
            billingAddress: {
                address1: values.address1,
                address2: values.address2 || "abc",
                company: values.company,
                country: values.country,
                state: values.state,
                zip: values.zip,
                city: values.city,
            },
            isOtherAddress: values.isOtherAddress,
            shippingInfo: {
                address1: values.new_shipping.new_shipping_address1,
                address2: values.new_shipping.new_shipping_address2 || 'abc',
                company: values.new_shipping.new_shipping_company,
                country: values.new_shipping.new_shipping_country,
                state: values.new_shipping.new_shipping_state,
                zip: values.new_shipping.new_shipping_zip,
                city: values.new_shipping.new_shipping_city,
                firstName: values.new_shipping.new_shipping_firstname,
                lastName: values.new_shipping.new_shipping_lastname,
                phone: values.new_shipping.new_shipping_phone,
            },
            subtotal,
            tax: Number(taxPrice),
            shippingCharges: 0,
            discount: 0,
            total,
            newShippingAddress: values.new_shipping,
            referenceId: refId
        };
        createOrder(dataToSend)
    }

    return (
        <div>
            <div className='my-5'>
                <label htmlFor="checkout_confirm" className="font-light">
                    <input
                        type="checkbox"
                        id="checkout_confirm"
                        onChange={() => setIsOkay(!isOkay)}
                    />
                    <span className="ml-3">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</span>
                </label>
            </div>
            <div className="w-full my-4">
                <input
                    type="text"
                    placeholder="Refrence id"
                    className="p-4 border border-gray-500 border-opacity-70 outline-none rounded-md w-full"
                    value={refId}
                    onChange={(e) => setRefId(e.target.value)}
                />
            </div>
            <button
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
                className="bg-custom-yellow text-white w-full p-3 rounded-md"
            >
                {
                    isLoading ? "Processing..." : "Confirm Order"
                }
            </button>

        </div>
    )
}

export default CheckoutConfirmButton
