import { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { SelectOptions } from '../../../../interfaces/interface';
import Select from '../select/select';

const bankDropdownOptions = [
    { value: 'option1', label: 'United State Bank' },
    { value: 'option2', label: 'Pakistan State Bank' },
    { value: 'option3', label: 'India State Bank' },
];

export default function VendorPaymentInformationForm() {
    const [selectedOption, setSelectedOption] = useState<SelectOptions | null>(null);
    const { register, formState: { errors } } = useFormContext();
    return (
        <>
            <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>Payment Information</h2>

                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.accountholder ? "text-red-500" : "text-zinc-800"}`}>Account Holder Name *</label>
                        <input type="text" placeholder='Monica' {...register("accountholder", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.accountholder ? "border-red-500" : "border-gray-200"}`} />
                        {errors.accountholder?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Account Holder Name is required</p>}
                    </div>
                    <Select
                    placeholder='Choose your bank'
                    label='Bank Name *'
                        options={bankDropdownOptions}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                    <div className="w-full relative 2xl:col-span-2 xl:col-span-2 col-span-1">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.accountholderemail ? "text-red-500" : "text-zinc-800"}`}>Account Holder E-mail *</label>
                        <input type="text" placeholder='Monica@gamil.com' {...register("accountholderemail", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.accountholderemail ? "border-red-500" : "border-gray-200"}`} />
                        {errors.accountholderemail?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Account Holder E-mail is required</p>}
                    </div>
                    <div className="w-full relative 2xl:col-span-2 xl:col-span-2 col-span-1">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.accountnumber ? "text-red-500" : "text-zinc-800"}`}>Account Number *</label>
                        <input type="text" placeholder='3456787654323456789' {...register("accountnumber", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.accountnumber ? "border-red-500" : "border-gray-200"}`} />
                        {errors.accountnumber?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Account Number is required</p>}
                    </div>
                </div>
            </div>

        </>
    )
}
