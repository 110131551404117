import React from "react"
import { Link } from "react-router-dom"

const OnlineCouponsSection = () => {
    const gifts = [
        {
            title: "TP-Link Tapo 1080P Indoor Security Camera for Baby Monitor, Dog Camera w/Motion Detection, 2-Way Audio Siren, Night Vision, Cloud & SD Card Storage, Works w/Alexa & Google Home (Tapo C100)",
            img: "https://m.media-amazon.com/images/I/61gJcez9unL.AC_SL1500.jpg",
            platform: "Amazon",
            discount: "36%",
            link: "https://amzn.to/4bR3wpk"
        }, {
            title: "Old Spice Aluminum Free Deodorant for Men Swagger Lime & Cedarwood Scent Red Collection 3 Oz (Pack of 3)",
            img: "https://m.media-amazon.com/images/I/81M9MonSOaL._SL1500_.jpg",
            platform: "Amazon",
            discount: "56%",
            link: "https://amzn.to/3Vgqij0"
        }, {
            title: "Amazon Fire TV Stick, HD, sharp picture quality, fast streaming, free & live TV, Alexa Voice Remote with TV controls",
            img: "https://m.media-amazon.com/images/I/51TjJOTfslL._AC_SX425_.jpg",
            platform: "Amazon",
            discount: "45%",
            link: "https://amzn.to/3Vgqij0"
        }, {
            title: "DEWALT 20V MAX Cordless Drill and Impact Driver, Power Tool Combo Kit with 2 Batteries and Charger (DCK240C2)",
            img: "https://m.media-amazon.com/images/I/71aDTgJIkNL._AC_SX522_.jpg",
            platform: "Amazon",
            discount: "42%",
            link: "https://amzn.to/3Rfxhro"
        },
        {
            title: "Dreo Evaporative Air Cooler, 40” Cooling Fans that Blow Cold Air, 80° Oscillating, Removable Water Tank, Ice Packs, Remote Control, 4 Speeds, 7H Timer, Swamp Cooler for Bedroom, White",
            img: "https://m.media-amazon.com/images/I/71jA+tKBqLL._AC_SY300_SX300_.jpg",
            platform: "Amazon",
            discount: "13%",
            link: "https://amzn.to/3KyLWdB"
        },
        {
            title: "Fullstar Vegetable Chopper - Spiralizer Vegetable Slicer - Onion Chopper with Container - Pro Food Chopper - Slicer Dicer Cutter - (4 in 1, White)",
            img: "https://m.media-amazon.com/images/I/71rtu2v6-lL._AC_SX569_.jpg",
            platform: "Amazon",
            discount: "50",
            link: "https://amzn.to/3yYMFSC"
        },
        {
            title: "Van Heusen Mens Tall Fit Dress Shirt Flex Collar Stretch Solid (Big And Tall)",
            img: "https://m.media-amazon.com/images/I/71FsB0FJuWL._AC_SX466_.jpg",
            platform: "Amazon",
            discount: "20%",
            link: "https://amzn.to/3yLFltw"
        },
        {
            title: "Ninja Air Fryer Pro 4-in-1 with 5 QT Capacity, Air Fry, Roast, Reheat, Dehydrate, Air Crisp Technology with 400F for hot, crispy results in just minutes, Nonstick Basket & Crisper Plate, Grey, AF141",
            img: "https://m.media-amazon.com/images/I/71QwoGmcfUL._AC_SX569_.jpg",
            platform: "Amazon",
            discount: "25%",
            link: "https://amzn.to/4cbSTNE"
        },
        {
            title: "Under Armour Men's HOVR Turbulence 2 Running Shoe",
            img: "https://m.media-amazon.com/images/I/51IpRboZEYL._AC_SY500_.jpg",
            platform: "Amazon",
            discount: "32%",
            link: "https://amzn.to/3KwF14v"
        },
        {
            title: "RENPHO Eyeris 1 - Eye Massager for Migraines with Remote, Heat, Compression, Bluetooth, Heated Eye Massage Mask, Eye Care Device for Eye Relief, Dry Eyes, Improve Sleep, Father's Day Gifts for Dad",
            img: "https://m.media-amazon.com/images/I/61MuVB-AjoL._AC_SX522_.jpg",
            platform: "Amazon",
            discount: "55%",
            link: "https://amzn.to/3ySbEY1 "
        },

    ]

    const bannersPaths = ["/assets/images/banner1.png", "/assets/images/banner2.png"]
    return (
        <>
            <div className='w-full grid lg:grid-cols-3 grid-cols-1 gap-3 mt-8'>
                {
                    gifts.slice(0, 18).map((gift, i) => (
                        <React.Fragment key={i}>
                            <OnlineGiftCard gift={gift} />
                            {(i + 1) % 6 === 0 && i !== gifts.length - 1 && (
                                <div className="w-full col-span-1 lg:col-span-3 py-4">
                                    <img src={
                                        // show 1st banner then after next 2 rows show 2nd banner
                                        bannersPaths[(Math.floor(i / 6) % bannersPaths.length)]
                                    } alt="Ad" className="w-full" />
                                </div>
                            )}
                        </React.Fragment>
                    ))
                }

            </div>

        </>

    )
}

const OnlineGiftCard = ({
    gift
}: {
    gift: any
}) => {
    return (
        <>

            <div className='w-full bg-[#4ec987] rounded-xl p-5 flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-4 2 items-start py-4 relative animate-in'>
                <div className='w-full flex flex-col items-start'>
                    <h1 className='2xl:text-base 2xl:w-56 xl:w-56 xl:text-base md:text-base lg:text-base text-sm 2xl:font-bold xl:font-bold md:font-bold lg:font-bold font-medium'>{gift.title.slice(0, 50)}</h1>
                    <div className="flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-4 pt-4 w-full">
                        <div className="flex flex-col gap-1">
                            <p className='text-black text-xs'>Online Store: {gift.platform}</p>
                            <div className="text-black text-xs flex items-center gap-1">
                                Website: {'www.amazon.com'}
                            </div>
                        </div>
                        <Link to={gift.link} target='_blank' className='text-white bg-black px-6 py-2 rounded-full text-xs'>Redeem Now</Link>
                    </div>
                </div>
                {/* <div className='absolute right-0 top-0 bg-custom-yellow text-white px-7 py-1 rounded-full'>{gift.discount}</div> */}
            </div>
        </>

    )
}



export default OnlineCouponsSection