import { MdOutlineStarPurple500 } from "react-icons/md";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { setCartLength } from "../../features/slices/products-slice";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { useToast } from "../../components/shared/ToastContext";
import MainHeader from "../../components/common/header/main-header";
import Footer from "../../components/common/footer/footer";

export default function WishlistPage() {
    const [myWishlist] = useLocalStorage("my_wishlist", []);

    return (
        <>
            <MainHeader />
            <div className="max-w-screen-xl min-h-screen mx-auto pt-10">
                <div className='w-full bg-white p-8 rounded-3xl'>
                    <h3 className="text-black text-[22px] font-medium leading-snug">My Wishlists</h3>
                    {myWishlist?.length === 0 ? (
                        <div className="flex justify-center items-center h-[75vh]">
                            <div className="flex flex-col items-center gap-3">
                                <h6 className="text-black text-lg text-center font-normal">No Products In Your Wishlist</h6>

                                <Link to={`/`} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm flex items-center gap-2'>Back to Home <FaArrowRight /></Link>
                            </div>
                        </div>
                    ) : (
                        <>
                            {myWishlist.map((data: any) => (
                                <ProductRow data={data} key={data.id} />
                            ))}
                        </>
                    )}
                </div>
            </div>

            <Footer />
        </>
    )
}

export const ProductRow = ({ data }: any) => {
    const dispatch = useDispatch();
    const [myWishlist, setMyWishlist] = useLocalStorage("my_wishlist", []);
    const [cart, setCart] = useLocalStorage("my_cart", []);
    const [addedToWishlist, setAddedToWishlist] = useState(!!myWishlist.find((item: any) => item.id === data.id));
    const [addedToCart, setAddedToCart] = useState(!!cart.find((item: any) => item.id === data.id));
    const { showToast } = useToast();
    const { cartLength } = useSelector((state: AppState) => state?.product);

    const handleAddToCart = (product: any) => {
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item.id === product.id)) {
            console.log("Product removed from cart", product.id);

            setAddedToCart(false);
            dispatch(setCartLength(cartLength - 1));
            setCart((prev: any[]) => prev.filter((item) => item.id !== product.id));
            return showToast("Product Removed from cart", "success", "Product Removed");;
        }

        dispatch(setCartLength(cartLength + 1));
        setAddedToCart(true);
        setCart([...cart, product]);
        showToast("Product added to cart", "success", "Product added");;
    };

    const handleWishList = (product: any) => {
        console.log(product.id);

        let myWishlist = JSON.parse(localStorage.getItem("my_wishlist") || "[]");

        if (myWishlist.find((item: any) => item.id === product.id)) {
            setAddedToWishlist(false);
            setMyWishlist((prev: any[]) => prev.filter((item: any) => item.id !== product.id));
            showToast("Product Removed from Wishlist", "success", "Product Removed");
            return console.log("Product removed from wishlist");
        }
    };

    return (
        addedToWishlist ? <div key={data.id} className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start py-4 mt-6 border-b border-solid border-gray-200'>
            <div className='flex flex-row gap-4'>
                <img loading="lazy" className="w-[90px] h-[90px] rounded-[7px] border border-black/opacity-10" src={data?.src} alt="DefaultImage" />
                <div className='flex flex-col gap-1.5'>
                    <h6 className="text-zinc-800 text-lg font-normal flex items-center gap-2 leading-snug">{data?.title?.slice(0, 29) + "....."}
                        {myWishlist ? <img loading="lazy" src="/assets/images/svg/heart.svg" className="cursor-pointer" alt="" onClick={() => handleWishList(data)} /> : <FaRegHeart className="cursor-pointer" onClick={() => handleWishList(data)} />
                        }
                    </h6>

                    <h2 className="text-zinc-800 text-[26px] font-semibold">${data.price}</h2>
                </div>
            </div>
            <div className='flex flex-col items-end gap-5'>
                <div className="w-fit h-[27px] px-2.5 py-1 bg-yellow-300 rounded-sm justify-center items-center gap-[5px] inline-flex">
                    <div className="text-zinc-800 text-base font-normal">5.0</div>
                    <MdOutlineStarPurple500 />
                </div>

                <div className="w-full justify-start items-start gap-[25px] inline-flex">
                    <button type='button' className={`px-5 py-2 rounded-md border border-solid border-black justify-center items-center flex ${addedToCart ? 'bg-black' : "bg-white"}`}>
                        <span className={`text-sm font-normal ${addedToCart ? "text-white" : "text-black"}`} onClick={() => handleAddToCart(data)}>
                            {addedToCart ? "Remove" : "Add to Cart"}
                        </span>
                    </button>
                    {/* <button type='button' className="px-5 py-2 rounded-md border border-black justify-start items-start flex">
                    <span className="text-black text-sm font-normal">Remove</span>
                </button> */}
                </div>
            </div>
        </div> : null
    );
}