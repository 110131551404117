
import Divider from '../../components/common/ui/divider'
import QuestionBox from '../../components/shared/Box/question-box'

export default function FaqsPage() {
    return (
        <>
            <div className="w-full bg-white p-8 pt-7 rounded-3xl mb-8">
                <div className='flex 2xl:flex-row xl:flex-row flex-col justify-between gap-10'>
                    <h2 className="text-black text-[22px] font-medium leading-snug">FAQs</h2>
                    <div className="2xl:w-[544px] relative xl:w-[544px] w-full h-[46px] px-[25px] py-[15px] bg-neutral-100 rounded-[5px] border border-black/10 justify-start items-center gap-[350px] inline-flex">
                        <input type='text' className="w-full h-4 text-neutral-500 text-sm font-normal border-none outline-none bg-transparent" placeholder='Type your query and press enter' />
                        <img loading="lazy" src="/assets/DefaultImages/svg/search.svg" className='absolute right-5' alt="SearchImahe" />
                    </div>
                    <button type='button' className="px-5 py-2 bg-yellow-300 rounded-full border justify-center items-center flex">
                        + Add Faq
                    </button>
                </div>
            </div>
            <div className="w-full bg-white p-8 pt-7 rounded-3xl">
                <div className='flex flex-wrap gap-3 justify-between pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img loading="lazy" className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt='icons' />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Razero Wolverine</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
                <Divider className="mt-6" />
                <div className='flex justify-between flex-wrap gap-3 pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img loading="lazy" className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt="DefaultDefaultImage" />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Chocolate Chip Cookie</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
                <Divider className="mt-6" />
                <div className='flex justify-between flex-wrap gap-3 pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img loading="lazy" className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt="DefaultImage" />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Chocolate Chip Cookie</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
            </div>

        </>
    )
}
