"use client";
import React, { useState } from "react";
import { IoMdMenu } from "react-icons/io";

import { FiShoppingBag } from "react-icons/fi";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa6";
import { IoCartOutline } from "react-icons/io5";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetWalletQuery } from "../../../features/api/products-api";
import { RootState } from "../../../redux/store";
import { FaShopify } from "react-icons/fa";

const MobileMenu = ({ setMobileMenu, handleAccount }: {
    setMobileMenu: (arg: boolean) => void;
    handleAccount: () => void;
}) => {
    const { user, token } = useSelector((state: RootState) => state.auth);
    const { isLoading, data } = useGetWalletQuery();
    const [isMenuOpen] = useState(false);

    return (
        <div
            className="bg-black bg-opacity-30 overflow-hidden fixed h-screen w-full z-[5000]"
            onClick={() => setMobileMenu(false)}
        >
            <div
                className={`bg-white shadow-md h-screen w-80 transition-all ease-in-out duration-500 fixed text-black whitespace-nowrap overflow-hidden ${isMenuOpen ? "translate-x-" : "-translate-x-0"
                    }`}
                onClick={(e) => e.stopPropagation()}
            >
                <IoMdMenu
                    onClick={() => setMobileMenu(false)}
                    size={20}
                    className="absolute top-6 left-5"
                />
                <div className="flex justify-center items-center">
                    <img loading="lazy" src="/logo.gif" className="size-44 object-contain" alt="" />
                </div>
                <ul className="flex flex-col p-14 pl-8 pt-4 items-start justify-center gap-3 text-xl overflow-hidden">
                    <li
                        onClick={handleAccount}
                        className="flex gap-3 justify-center items-center cursor-pointer text-base mb-3 whitespace-nowrap"
                    >
                        <span>{token ? (user?.role === 1 ? "Admin" : user?.name) : "Signin"}</span>
                    </li>
                    {token && user?.role === 0 ? (
                        <>
                            <li>
                                <Link
                                    to="/user-panel/profile"
                                    className="flex gap-3 justify-center items-center text-lg  whitespace-nowrap"
                                >
                                    <IoPersonCircleOutline size={20} />
                                    <span>Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/user-panel/my-cart"
                                    className="flex gap-3 justify-center items-center text-lg  whitespace-nowrap"
                                >
                                    <IoCartOutline size={20} />
                                    <span>Cart</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/user-panel/my-orders"
                                    className="flex gap-3 justify-center items-center text-lg  whitespace-nowrap"
                                >
                                    <FiShoppingBag size={20} />
                                    <span>Orders</span>
                                </Link>
                            </li>
                            <li>

                                <Link
                                    to="/user-panel/wishlist"
                                    className="flex gap-3 justify-center items-center text-lg whitespace-nowrap"
                                >
                                    <FaRegHeart />
                                    <span>Wishlist</span>
                                </Link>
                            </li>
                        </>
                    ) : user?.role === 2 ? (
                        <>
                            <li className="mb-3">
                                <Link
                                    to="/vendor-panel/profile"
                                    className="flex gap-3 justify-center items-center text-base whitespace-nowrap"
                                >
                                    <IoPersonCircleOutline size={24} />
                                    <span>Profile</span>
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link
                                    to="/vendor-panel/business-management"
                                    className="flex gap-3 justify-center items-center text-base whitespace-nowrap"
                                >
                                    <FaShopify size={24} />
                                    <span>Business</span>
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link
                                    to="/vendor-panel/coupons"
                                    className="flex gap-3 justify-center items-center text-base whitespace-nowrap"
                                >
                                    <img loading="lazy" src="/assets/icons/side-bar/magic.svg" alt="" />
                                    <span>Coupons</span>
                                </Link>
                            </li>
                        </>
                    ) : null}

                    {!token && (
                        <Link
                            to="/user-panel/my-cart"
                            className="flex gap-3 justify-center items-center text-lg whitespace-nowrap"
                        >
                            <IoCartOutline />
                            <span>Cart</span>
                        </Link>
                    )}

                    {user?.role === 0 ? (
                        <Link
                            to={"/user-panel/magic-club"}
                            className="flex justify-between items-center w-full px-4 py-2 my-5 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <span>{"Magic Club"}</span>
                            <span className="text-md ml-3">
                                {!isLoading && token && data?.data?.totalAmount
                                    ? "$" + data?.data?.totalAmount?.toFixed(2)
                                    : "$0"}
                            </span>
                        </Link>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

export default MobileMenu;
