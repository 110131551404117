import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { MdOutlineAccountCircle, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { LuLogIn } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa6";
import { IoCartOutline, IoSearchOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDropdownLinks } from "../../../utils/links";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { RootState } from "../../../redux/store";
import MobileMenu from "./mobile-navbar";
import { useGetProfileQuery } from "../../../features/slices/api-slices";
import { logout, setToken, setUser } from "../../../features/slices/auth-slice";
import { setCartLength } from "../../../features/slices/products-slice";
import { IoMdMenu } from "react-icons/io";
import { Link } from "react-router-dom";

// const NAVBAR_PROMO = "Free Express Shipping on orders $200!";

const Navbar: React.FC = () => {
    const dispatch = useDispatch();
    const [mobileMenu, setMobileMenu] = useState(false);
    const [openProfileMenu, setOpenProfileMenu] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { cartLength } = useSelector((state: RootState) => state.product);
    const { token, user } = useSelector((state: RootState) => state.auth);
    const { isLoading: loadingUser, data: userData } = useGetProfileQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });


    const [cartItems] = useLocalStorage("my_cart", []);
    const userDropdownLinks = getDropdownLinks(user?.role);
    // const { isLoading, data } = useGetWalletQuery(undefined, {
    //     refetchOnMountOrArgChange: true,
    //     refetchOnReconnect: true,
    //     skip: !token || !user?.role,
    // });

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputVal(e.target.value);
    };

    const handleSearch = (e: FormEvent) => {
        e.preventDefault();
        let search = inputVal || "";
        let sort = "popular";
        let category = "";
        navigate(`/shop?search=${search}&sort=${sort}&category=${category}`);
    };

    const handleMobileMenu = () => {
        setMobileMenu(true);
    };

    useEffect(() => {
        dispatch(setCartLength(cartItems?.length));
    }, [cartItems, dispatch]);

    console.log(userData);

    useEffect(() => {
        if (!loadingUser && userData) {
            dispatch(setToken(localStorage.getItem("token") || ""));
            dispatch(setUser(userData));
        }
    }, [loadingUser, userData, dispatch]);

    const handleAccount = () => {
        if (!token) {
            window.location.assign("/auth/signin");
            return;
        }
        if (user?.role === 0) {
            window.location.assign("/profile");
            return;
        }
        if (user?.role === 1) {
            window.location.assign("/admin/dashboard");
        }
        if (user?.role === 2) {
            window.location.assign("/vendor/dashboard");
        }
    };

    const hiddenPaths = ["auth", "profile", "admin", "vendor"];
    const isNavBarHidden = hiddenPaths.some(path => location.pathname.includes(path));

    if (isNavBarHidden) {
        return null;
    }

    return (
        <>
            <nav className="w-full flex justify-center items-center flex-col bg-white">
                <div className="w-[95%] md:w-[90%] lg:w-[80%] py-2 md:py-2">
                    {/* <div className="flex justify-between w-full items-center md:py-1 md:text-sm">
                        <p className="hidden md:inline-flex font-light text-base">{NAVBAR_PROMO}</p>
                        <ul className="gap-x-3 items-center hidden md:inline-flex font-light text-xs">
                            <li className="flex gap-1 justify-center items-center">
                                <CustomSelect onChange={(e) => { }} placeholder="Currency" options={["USD", "CAD"]} />
                            </li>
                            <li className="flex gap-1 justify-center items-center">
                                <CustomSelect onChange={() => { }} placeholder="Help Center" options={["Help Center"]} />
                            </li>
                            <li><p className="">Follow Us</p></li>
                            <li><Link to={socialMediaLinks.facebook} target="_blank"><FaFacebookF /></Link></li>
                            <li><Link to={socialMediaLinks.twitter} target="_blank"><FaTwitter /></Link></li>
                            <li><Link to={socialMediaLinks.instagram} target="_blank"><FaInstagram /></Link></li>
                            <li><FaLinkedin /></li>
                        </ul>
                    </div> */}
                    <div className="flex justify-between items-center w-full">
                        <IoMdMenu onClick={handleMobileMenu} size={20} className="md:hidden cursor-pointer" />
                        <div className="flex justify-between items-center w-full gap-10">
                            <Link to="/">
                                <picture>
                                    <source srcSet={"/logo.gif"} className="w-[300px] h-[150px] md:h-[120px] object-cover" media="(min-width: 800px)" />
                                    <img loading="lazy" src={"/logo.gif"} className="w-[300px] h-[40px] md:h-[80px] object-cover" alt="logo" />
                                </picture>
                            </Link>
                            <div className="flex justify-between items-center w-full gap-2">
                                <form onSubmit={handleSearch} className="flex justify-center items-center w-full whitespace-nowrap mx-3 md:mx-0 rounded-full px-2 bg-[#F5F8FA] border border-gray-300">
                                    <input
                                        onChange={handleSearchChange}
                                        type="search"
                                        className="focus:outline-none border-none md:px-4 py-2 w-full md:ml-4 bg-transparent"
                                        placeholder="Search products..."
                                    />
                                    <span className="text-[#D6D6D6] mx-4 hidden md:inline-flex">|</span>
                                    <button type="submit">
                                        <IoSearchOutline size={20} className="md:mx-2 text-2xl md:text-4xl cursor-pointer" />
                                    </button>
                                </form>
                            </div>
                            <ul className="hidden md:flex justify-between items-center gap-5">
                                {(user?.role === 0 || !user) && <li>
                                    <Link to="/user-panel/wishlist" className="flex gap-3 justify-center items-center whitespace-nowrap">
                                        <FaRegHeart size={23} />
                                        <span>Wishlist</span>
                                    </Link>
                                </li>}
                                <li className="relative z-[500000] flex gap-3 justify-center items-center cursor-pointer whitespace-nowrap" onClick={() => handleAccount}>
                                    {token ? <MdOutlineAccountCircle size={23} /> : <LuLogIn size={23} />}
                                    <p className="flex items-center">
                                        {token ? (
                                            <>
                                                <span>{user?.name?.split(" ")[0]}</span>
                                                <MdOutlineKeyboardArrowDown size={25} onClick={() => setOpenProfileMenu(!openProfileMenu)} />
                                            </>
                                        ) : <Link to="/auth/signin">Signin</Link>}
                                    </p>
                                    {openProfileMenu && (
                                        <div id="dropdown" className="absolute top-10 right-0 z-[100000] bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                                            <ul className="py-2 text-sm text-gray-700" onMouseLeave={() => setOpenProfileMenu(false)}>
                                                {userDropdownLinks.map((link, index) => (
                                                    <li key={index}>
                                                        {link.path?.includes("logout") ? (
                                                            <button
                                                                onClick={() => {
                                                                    localStorage.removeItem("token");
                                                                    dispatch(logout());
                                                                    window.location.assign("/");
                                                                }}
                                                                className="block px-4 py-2 hover:bg-gray-100 w-full"
                                                            >
                                                                {link.title}
                                                            </button>
                                                        ) : (
                                                            <Link to={link.path} className="block px-4 py-2 hover:bg-gray-100">{link.title}</Link>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                                {(user?.role === 0 || !user) && <li>
                                    <Link to="/user-panel/my-cart" className="flex gap-3 justify-center items-center whitespace-nowrap relative">
                                        {cartLength > 0 && (
                                            <div className="absolute -top-2 left-3 bg-[#F5C34B] rounded-full w-5 h-5 p-2 flex justify-center items-center">
                                                <span className="text-xs font-extralight">{cartLength}</span>
                                            </div>
                                        )}
                                        <IoCartOutline size={23} />
                                        <span>Cart</span>
                                    </Link>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="bg-[#F5C34B] h-[2px] w-full" />
                    <div className="bg-[#6BD68D] h-[2px] w-full" />
                    <div className="bg-[#EC752F] h-[2px] w-full" />
                    <div className="bg-[#F5C34B] h-[2px] w-full" />
                    <div className="bg-[#6BD68D] h-[2px] w-full" />
                </div>
            </nav>
            {mobileMenu && <MobileMenu
                setMobileMenu={setMobileMenu}
                handleAccount={handleAccount}
            />}
        </>
    );
};

export default Navbar;
