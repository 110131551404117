import { useForm } from "react-hook-form";
import { useResetPasswordMutation } from "../../features/slices/api-slices";
import { useToast } from "../../components/shared/ToastContext";

export default function ChangePasswordPage() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [resetPassword, { isLoading, isSuccess, isError }] = useResetPasswordMutation();
    const { showToast } = useToast();
    const onSubmit = async (data: any) => {
        try {
            await resetPassword(data).unwrap();
        } catch (error) {
            if (isError) {
                showToast("Password Reset Failed ", "error", "We're sorry, but we couldn't reset your password.")
            }
        }
    };
    return (
        <>
            <div className='w-full h-fit p-8 pt-5 lg:rounded-3xl bg-white'>
                <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Change Password</h2>
                {isSuccess && <p>Password successfully changed!</p>}

                <div className='2xl:flex xl:flex md:flex lg:flex justify-between block pt-12'>
                    <div className='2xl:w-2/3 xl:w-2/3 space-y-8 md:w-2/3 lg:w-2/3 w-full'>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.password ? "text-red-500" : "text-zinc-800"}`}>Old Password *</label>
                                <input type="password" placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.password ? "border-red-500" : "border-gray-200"}`}
                                    {...register("password", { required: true })} aria-invalid={errors.password ? "true" : "false"} />
                                {errors.password?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Password is required</p>}
                                <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.newpass ? "text-red-500" : "text-zinc-800"}`}>New Password *</label>
                                <input type="password" {...register("newpass", { required: true })} placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.newpass ? "border-red-500" : "border-gray-200"}`} />
                                {errors.newpass?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">New Password is required</p>}
                                <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.repass ? "text-red-500" : "text-zinc-800"}`}>Re-enter Password *</label>
                                <input type="password" {...register("repass", { required: true })} placeholder='•••••••••••••' className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.repass ? "border-red-500" : "border-gray-200"}`} />
                                {errors.repass?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Re-enter Password is required</p>}
                                <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                            </div>
                            <div className="flex justify-start gap-8 2xl:pt-16 xl:pt-16 md:pt-16 lg:pt-16 pt-5">
                                <button type="submit" disabled={isLoading} className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                    {isLoading ? "Loading..." : "Save"}
                                </button>
                                <button type="button" className="w-fit h-[46px] 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    <img loading="lazy" src="/assets/images/image-2.png" className='h-64 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-8' alt="" />
                </div>

            </div>
        </>
    )
}
