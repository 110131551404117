import React from 'react'

export default function QuestionBox() {
    return (
        <>
            <div className="w-full mt-6 pl-[30px] pr-[11px] py-[25px] bg-neutral-100 rounded-[15px] flex-col justify-start items-start gap-[15px] inline-flex">
                <div className="text-zinc-800 text-base font-medium">Q: Are wireless gaming mice as reliable as wired ones?</div>
                <p className='text-sm text-zinc-800 font-normal'>
                    <b>A:</b> Modern wireless gaming mice have improved significantly and offer reliable performance comparable to wired mice.
                </p>
            </div>
        </>
    )
}
