import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as z from "zod";
import { useToast } from "../../../../components/shared/ToastContext";
import { useResetPasswordMutation } from "../../../../features/slices/api-slices";

const schema = z.object({
    email: z.string().email("Invalid email address").nonempty("Email is required"),
    password: z.string().min(6, "Password must be at least 6 characters").nonempty("Password is required"),
});

type FormData = z.infer<typeof schema>;

const ResetPasswordPage: React.FC = () => {
    const { token } = useParams()
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const { showToast } = useToast();


    const [reset, { isLoading, isSuccess, error }] = useResetPasswordMutation();

    useEffect(() => {
        if (!isLoading && isSuccess) {
            showToast("Password reset successfully", "success", "Password Reset");
        }
        if (!isLoading && error) {
            showToast(`Error while resetting password. Please try again`, "error", "Password Reset");
        }
    }, [isLoading, isSuccess]);

    const handleReset = (e: any) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            showToast("Password and Confirm Password are required", "error", "Password Required");
            return;
        }
        if (password !== confirmPassword) {
            showToast("Password and Confirm Password do not match", "error", "Password Mismatch");
            return;
        }

        reset({ password, activation_token: token! });
    };
    return (
        <div className="flex justify-between items-center h-screen w-full">
            <div className="w-full flex justify-center items-center">
                <div className="flex flex-col mx-auto gap-4 md:w-[500px] w-full p-5 lg:p-10 md:shadow-lg rounded-[10px]">
                    <Link to="/" className="w-full flex justify-center items-center">
                        <img
                            src={'/logo.gif'}
                            alt="logo"
                            className="w-[300px] h-[100px] object-cover"
                            width={150}
                            height={150}
                        />
                    </Link>
                    <h5 className="uppercase">
                        Welcome
                    </h5>
                    <h1 className="text-xl md:text-2xl font-bold tracking-wide capitalize my-2 mt-0">Reset Password</h1>

                    <form
                        className="w-full flex flex-col gap-6 my-6"
                        onSubmit={handleReset}
                    >
                        <label htmlFor="password">
                            Password
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                name="password"
                                id="password"
                                className="rounded p-2 mt-2 border border-gray-300 w-full"
                            />
                        </label>
                        <label htmlFor="confirm_password">
                            Confirm Password
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password"
                                name="confirm_password"
                                id="confirm_password"
                                className="rounded p-2 mt-2 border border-gray-300 w-full"
                            />
                        </label>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-[#FF5F00] text-white self-center rounded-xl px-5 py-3"
                        >
                            {isLoading ? "Loading..." : "Reset Password"}
                        </button>
                        <div className="flex justify-center text-sm gap-2">
                            <span>New User?</span>
                            <Link to="/auth/signup">
                                <span className="font-bold uppercase">Sign Up Here</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:inline-flex">
                <img
                    src="/auth.png"
                    alt="auth"
                    className="h-[100vh] w-[800px] object-cover"
                    width={400}
                    height={400}
                />
            </div>
        </div>
    );
};

export default ResetPasswordPage;
