import React, { useState, useEffect } from 'react';

const CountdownTimer: React.FC = () => {
    const calculateTimeLeft = () => {
        const totalSeconds =
            24 * 24 * 3600 -  // 24 days
            19 * 3600 -       // -19 hours
            32 * 60 -         // -32 minutes
            16;               // -16 seconds

        const days = Math.floor(totalSeconds / (3600 * 24));
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return { days, hours, minutes, seconds };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(prevTime => {
                const totalSeconds =
                    prevTime.days * 24 * 3600 +
                    prevTime.hours * 3600 +
                    prevTime.minutes * 60 +
                    prevTime.seconds -
                    1;

                if (totalSeconds <= 0) {
                    clearInterval(intervalId);
                    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
                }

                const days = Math.floor(totalSeconds / (3600 * 24));
                const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;

                return { days, hours, minutes, seconds };
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <div className='w-fit px-6 py-2 2xl:text-sm xl:text-sm text-[10px] text-white bg-red-500 rounded-full'>
                Expires in:  {timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s
            </div>
        </>
    );
};

export default CountdownTimer;
