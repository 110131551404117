import React, { useState } from 'react'
import MainHeader from '../../../components/common/header/main-header'
import Footer from '../../../components/common/footer/footer';
import { IoLocationOutline } from 'react-icons/io5';
import { LuPhoneCall } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { useGetMyEbooksQuery } from '../../../features/api/ebook-api';
import { AppState } from '../../../redux/store';
import moment from 'moment';
import { Link } from 'react-router-dom';


export default function BuyCoponsPage() {
    const [activeTab, setActiveTab] = useState(1);
    const user = useSelector((state: AppState) => state.auth.user)
    // do not fetch data if user is not logged in
    const ebooks = [
        {
            title: "Winter Wonderland Deals",
            description: "Enjoy cozy winter with discounts on winter apparel, accessories, and more. Includes 15 exclusive coupons.",
            expireOn: moment().add(1, 'year').format('DD MMM YYYY'),
            purchased: 10

        },
        {
            title: "Spring into Savings",
            description: "Spring cleaning has never been so rewarding. Get discounts on home goods, gardening tools, and more. 12 coupons included.",
            expireOn: moment().add(1, 'year').format('DD MMM YYYY'),
            purchased: 50
        },
        {
            title: "Summer Fun Savings",
            description: "Get ready for summer with discounts on outdoor gear, beachwear, and more. 20 coupons included.",
            expireOn: moment().add(1, 'year').format('DD MMM YYYY'),
            purchased: 30
        }
    ]
    const { isLoading, data, error } = useGetMyEbooksQuery(user?._id, {
        skip: !user?._id
    })

    const bannersPaths = ["/assets/images/banner1.png", "/assets/images/banner2.png"]
    return (
        <>
            <MainHeader />

            <main className='w-full relative'>
                <img src="/assets/images/buy-coupon-bg.png" className='w-full 2xl:h-auto xl:h-auto h-36' alt="" />
                <div className='absolute top-0 left-0 right-0 2xl:pt-32 xl:pt-28 pt-10'>
                    <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-10 px-5'>
                        <h1 className='2xl:text-5xl xl:text-5xl text-2xl font-bold 2xl:w-[582px] xl:w-[582px] 2xl:leading-[55px] xl:leading-[55px] w-full'>
                            Buy Coupons in Bulk and Save Big!
                        </h1>
                    </div>
                </div>
            </main>

            {/* ======= Tabs section code here ======= */}
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-10 px-5 pt-10 pb-24'>
                <div className='flex items-center gap-6 border-b border-solid border-primary'>
                    <button onClick={() => setActiveTab(1)} className={`2xl:px-5 xl:px-5 md:px-5 lg:px-5 px-3 py-2.5 text-sm rounded-t-xl border border-solid border-b-0 ${activeTab === 1 ? "active border-primary" : "bg-gray-100 border-gray-200"}`}>
                        E-Discount Coupon
                    </button>
                    <button onClick={() => setActiveTab(2)} className={`2xl:px-5 xl:px-5 md:px-5 lg:px-5 px-3 py-2.5 text-sm rounded-t-xl border border-solid border-b-0 ${activeTab === 2 ? "active border-primary" : "bg-gray-100 border-gray-200"}`}>
                        Business Directories
                    </button>
                </div>
                {/* ===== content here ========= */}
                {activeTab === 1 && (
                    <>
                        {isLoading ? (
                            <div className='w-full h-full flex justify-center items-center'>
                                <div className='w-10 h-10 border-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
                            </div>
                        ) : (
                            error ? (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <p className='text-red-600'>{JSON.stringify(error)}</p>
                                </div>
                            ) : data?.length === 0 ? (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <p className='text-gray-600'>Please Buy Ebook to see coupons.</p>
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 w-full'>
                                    <img src="/assets/images/banner3.png" className='w-full mt-8' alt="" />
                                    {ebooks?.map((ebook, index) => <div className='w-full border border-solid border-gray-200 rounded-2xl p-6 mt-6'>
                                        <React.Fragment key={index}>
                                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start'>
                                                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-6 w-1/2'>
                                                    <div className='size-24 bg-primary rounded'></div>
                                                    <div className='flex flex-col gap-1'>
                                                        <h4 className='font-medium text-lg'>
                                                            {ebook.title}
                                                        </h4>
                                                        <p className='text-gray-500 text-sm'>
                                                            {ebook.description}
                                                        </p>
                                                        <div className='flex 2xl:flex-nowrap xl:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3'>
                                                            <p className='text-sm'>
                                                                Expire on: {ebook.expireOn}
                                                            </p>
                                                            <div className='px-6 py-1 text-xs text-white rounded-full bg-red-500'>
                                                                {ebook.purchased} Purchased
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to={
                                                    user ? '/ebook/checkout' :
                                                        '/auth/signin?redirect=/ebook/checkout'
                                                } className='px-10 py-2.5 uppercase bg-primary rounded text-sm'>
                                                    Get E-Discount Coupon Book
                                                </Link>
                                            </div>
                                            {(index + 1) % 6 === 0 && index !== ebooks?.length - 1 && (
                                                <div className="w-full col-span-3 py-4">
                                                    <img src={
                                                        // show 1st banner then after next 2 rows show 2nd banner
                                                        bannersPaths[(Math.floor(index / 6) % bannersPaths.length)]
                                                    } alt="Ad" className="w-full" />
                                                </div>
                                            )}

                                        </React.Fragment>


                                    </div>)}
                                    <img src="/assets/images/banner4.png" className='w-full mt-8' alt="" />
                                </div>
                            ))}
                    </>
                )}

                {activeTab === 2 && (
                    <>
                     <img src="/assets/images/banner3.png" className='w-full mt-8' alt="" />
                        <div className='w-full border border-solid border-gray-200 rounded-2xl p-6 mt-8'>
                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-5'>
                                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-6 2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2 w-full'>
                                    <div className='size-24'>
                                        <img src="/assets/images/business-img.png" alt="" />
                                    </div>
                                    <div className='flex flex-col gap-1.5'>
                                        <h4 className='font-medium text-lg'>FreshBites Organic Grocery</h4>
                                        <p className='text-gray-500 text-sm'>
                                            Shop a wide range of organic and locally sourced products at FreshBites Organic Grocery.
                                        </p>
                                        <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-3'>
                                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-8 pt-2'>
                                                <div className='flex gap-2 items-center'>
                                                    <IoLocationOutline className='text-lg' />
                                                    <p>
                                                        321 Farm Lane, Austin, TX
                                                    </p>
                                                </div>
                                                <div className='flex gap-4 items-center'>
                                                    <LuPhoneCall className='text-lg' />
                                                    <p>
                                                        (512) 555-1234
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-5'>
                                    <button type="button" className='px-10 py-2.5 uppercase bg-primary rounded text-sm'>
                                        view profile
                                    </button>
                                    <button type="button" className='px-10 py-2.5 uppercase border border-solid border-black rounded text-sm'>
                                        call now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <img src="/assets/images/banner5.png" className='w-full mt-8' alt="" />
                    </>
                )}
            </div>
            <Footer />
        </>
    )
}
