import { FiMenu as Icon } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5';
export default function MenuBarMobile({ setter }: any) {
    return (
        <nav className="2xl:hidden xl:hidden z-[100] absolute md:top-8 lg:top-8 top-0 md:left-6 lg:left-6 left-0 right-0 h-[60px] flex [&>*]:my-auto px-4">
            <button
                className="text-4xl flex text-black"
                onClick={() => {
                    setter((oldVal: any) => !oldVal);
                }}
            >
                <Icon className='text-lg' />
            </button>
            <button type="button"  onClick={() => {
                    setter((oldVal: any) => !oldVal);
                }} className='absolute 2xl:hidden xl:hidden text-2xl right-4 top-4 text-white outline-none border-none bg-transparent size-5 flex justify-center items-center'>
                <IoClose className='' />
                </button>
        </nav>
    )
}