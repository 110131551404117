import React from 'react';
import { Navbar } from '../../components/shared/navbar';
import { Link } from 'react-router-dom';
import Footer from '../../components/shared/footer';

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Navbar />

            <div className="container mx-auto p-6">
                <h1 className="text-left pt-1 text-xl font-medium">Privacy Policy</h1>
                <p className="pt-3 text-left">
                    <p className='text-base'>
                        Protecting your private information is our priority. This Statement of Privacy applies to the www.damndeal.com
                        and Damndeal and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise
                        noted, all references to Damndeal include
                    </p>
                    <Link to="http://www.damndeal.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                        www.damndeal.com
                    </Link>
                    . The Damndeal website is an ecommerce site. By using the Damndeal website, you consent to the data practices
                    described in this statement.
                </p>
                <h2 className="pt-3 text-left font-medium mt-4 mb-4 text-xl">Collection of your Personal Information</h2>
                <p className="pl-1 text-left">
                    Damndeal may collect personally identifiable information, such as your name. If you purchase Damndeal's products
                    and services, we collect billing and credit card information. This information is used to complete the purchase
                    transaction. Damndeal may also collect anonymous demographic information, which is not unique to you, such as
                    your age, gender, household income, political affiliation, race and religion. We may gather additional personal
                    or non-personal information in the future.
                </p>
                <p className="pl-1 text-left mt-4">
                    Information about your computer hardware and software may be automatically collected by Damndeal. This
                    information can include: your IP address, browser type, domain names, access times and referring website
                    addresses. This information is used for the operation of the service, to maintain quality of the service, and to
                    provide general statistics regarding use of the Damndeal website.
                </p>
                <p className="pt-3 pl-1 text-justify">
                    Please keep in mind that if you directly disclose personally identifiable information or personally sensitive
                    data through Damndeal's public message boards, this information may be collected and used by others.
                </p>
                <p className="pl-1 text-left">
                    Damndeal encourages you to review the privacy statements of websites you choose to link to from Damndeal so that
                    you can understand how those websites collect, use and share your information. Damndeal is not responsible for
                    the privacy statements or other content on websites outside of the Damndeal website.
                </p>
                <h2 className="pt-3 text-left text-xl font-medium mt-4 mb-4">Use of your Personal Information</h2>
                <p className="pl-1 text-left">
                    Damndeal collects and uses your personal information to operate its website(s) and deliver the services you have
                    requested.
                </p>
                <p className="pt-3 pl-1 text-left mt-4">
                    Damndeal may also use your personally identifiable information to inform you of other products or services
                    available from Damndeal and its affiliates. Damndeal may also contact you via surveys to conduct research about
                    your opinion of current services or of potential new services that may be offered.
                </p>
                <p className="pl-1 text-justify mt-4">
                    Damndeal does not sell, rent or lease its customer lists to third parties.
                </p>
                <p className="pl-1 text-left mt-4">
                    Damndeal may, from time to time, contact you on behalf of external business partners about a particular offering
                    that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name,
                    address, telephone number) is not transferred to the third party. Damndeal may share data with trusted partners
                    to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for
                    deliveries. All such third parties are prohibited from using your personal information except to provide these
                    services to Damndeal, and they are required to maintain the confidentiality of your information.
                </p>
                <p className="pl-1 text-left mt-4">
                    Damndeal may keep track of the websites and pages our users visit within Damndeal, in order to determine what
                    Damndeal services are the most popular. This data is used to deliver customized content and advertising within
                    Damndeal to customers whose behavior indicates that they are interested in a particular subject area.
                </p>
                <p className="pl-1 text-left mt-4">
                    Damndeal will disclose your personal information, without notice, only if required to do so by law or in the
                    good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
                    process served on Damndeal or the site; (b) protect and defend the rights or property of Damndeal; and, (c) act
                    under exigent circumstances to protect the personal safety of users of Damndeal, or the public.
                </p>
                <h2 className="pt-3 text-left text-xl font-medium">Use of Cookies</h2>
                <p className="pl-1 text-left mt-4">
                    The Damndeal website may use &quot;cookies&quot; to help you personalize your online experience. A cookie is a
                    text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or
                    deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in
                    the domain that issued the cookie to you.
                </p>
                <p className="pt-3 pl-1 text-left mt-4">
                    One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
                    cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize
                    Damndeal pages, or register with Damndeal site or services, a cookie helps Damndeal to recall your specific
                    information on subsequent visits. This simplifies the process of recording your personal information, such as
                    billing addresses, shipping addresses, and so on. When you return to the same Damndeal website, the information
                    you previously provided can be retrieved, so you can easily use the Damndeal features that you customized.
                </p>
                <p className="pl-1 text-left mt-4">
                    You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
                    usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may
                    not be able to fully experience the interactive features of the Damndeal services or websites you visit.
                </p>
                <h2 className="pt-3 text-left text-xl font-medium">Security of your Personal Information</h2>
                <p className="pl-1 text-left mt-4">
                    Damndeal secures your personal information from unauthorized access, use or disclosure. When personal
                    information (such as a credit card number) is transmitted to other websites, it is protected through the use of
                    encryption, such as the Secure Sockets Layer (SSL) protocol.
                </p>
                <h2 className="pt-3 text-left text-xl font-medium mt-4">Children Under Thirteen</h2>
                <p className="pl-1 text-left mt-4">
                    Damndeal does not knowingly collect personally identifiable information from children under the age of thirteen.
                    If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
                </p>
                <h2 className="pt-3 pl-1 text-left text-xl font-medium">Opt-Out & Unsubscribe</h2>
                <p className="pl-1 text-left">
                    We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain
                    information. Users may opt-out of receiving any or all communications from Damndeal by contacting us here:
                </p>
                <ul className="list-disc pl-5">
                    <li className="pl-1">
                        <Link to="http://www.damndeeal.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                            Web page: www.damndeal.com
                        </Link>
                    </li>
                    <li className="pl-1">
                        <Link to="mailto:info@damndeal.com" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                            Email: info@damndeal.com
                        </Link>
                    </li>
                </ul>
                <p className="pl-1 text-left">
                    - Phone: <span className="underline">209-227-9427</span>
                </p>
                <h2 className="pt-3 text-left text-xl font-medium my-4">Changes to this Statement</h2>
                <p className="pl-1 text-left">
                    Damndeal will occasionally update this Statement of Privacy to reflect company and customer feedback. Damndeal
                    encourages you to periodically review this Statement to be informed of how Damndeal is protecting your
                    information.
                </p>
                <h2 className="pt-3 text-left text-xl font-medium my-4">Contact Information</h2>
                <p className="pl-1 text-left">
                    Damndeal welcomes your questions or comments regarding this Statement of Privacy. If you believe that Damndeal
                    has not adhered to this Statement, please contact Damndeal at:
                </p>
                <address className="pl-1 text-left py-4">
                    <strong>Damndeal</strong>
                    <br />
                    <span className="underline">209-227-9427</span>
                    <br />
                    <Link to="mailto:info@damndeal.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        Email: info@damndeal.com
                    </Link>
                </address>
                <p className="pt-3 pl-1 text-left">Effective as of March 09, 2024</p>
            </div>

            <Footer />
        </>
    );
};

export default PrivacyPolicy;
