import { useParams } from "react-router-dom"
import { Navbar } from "../../components/shared/navbar"
import Footer from "../../components/shared/footer"
import { blogs } from "../../utils/data"


const BlogDeatilsPage = () => {
    // get [id] from the URL
    const { id } = useParams()
    let blog = blogs?.find((blog: any) => blog?.id === Number(id))
    return (
        <>
            <Navbar />
            <div className="w-full p-5 flex justify-center items-center">
                <div className="pt-20 md:pt-0 my-10 mt-2 flex flex-col gap-8 page-container">
                    <div className="flex flex-col gap-5 my-10">
                        <div className="w-full flex justify-center items-center gap-2">
                            <h1 className="text-center text-lg lg:text-3xl font-bold">Blog</h1>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex flex-col gap-5">
                                <div className='w-full bg-[#f1f1f1] rounded-lg'>
                                    <img src={`/blogs/` + blog?.image} alt={blog?.title} className="w-full h-full rounded" />
                                </div>
                                {/* <div className="w-full h-[300px] md:h-[400px] lg:h-[500px] bg-[#f1f1f1] rounded-lg"></div> */}
                                <div className="w-full flex flex-col gap-2">
                                    <h1 className="text-2xl font-semibold">{blog?.title}</h1>
                                    <div className='text-5xl'>
                                        <div className='text-5xl tracking-wide leading-9'>
                                            {blog?.body}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default BlogDeatilsPage