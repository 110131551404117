import React from 'react'
import { useForm } from 'react-hook-form';
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function ShopCategoryForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);
  return (
    <>
         <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>Shop & Categories</h2>

                <form onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.shop ? "text-red-500" : "text-zinc-800"}`}>Shop *</label>
                        <div  {...register("shop", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.shop ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Facebook</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.shop?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Shop is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.productcate ? "text-red-500" : "text-zinc-800"}`}>Product Category *</label>
                        <div  {...register("productcate", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.productcate ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose your category here</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.productcate?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Product Category is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.tags ? "text-red-500" : "text-zinc-800"}`}>Tags *</label>
                        <div  {...register("tags", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.tags ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Select..........</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.tags?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Tags is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.manufac ? "text-red-500" : "text-zinc-800"}`}>Manufactures *</label>
                        <div  {...register("manufac", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.manufac ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Select..........</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.manufac?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Manufactures is required</p>}
                    </div>
                    <div className="flex justify-end gap-8 col-span-2">
                        <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            Save
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
    </>
  )
}
