import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FilterState {
    category: string[];
    priceRange: string[];
    brands: string[];
    materialType: string[];
    rating: string[];
}

interface ShopContextProps {
    filters: FilterState;
    applyFilter: (filterType: keyof FilterState, filterValue: string) => void;
}

const ShopContext = createContext<ShopContextProps | null>(null);

export const ShopProvider = ({ children }: { children: ReactNode }) => {
    const [filters, setFilters] = useState<FilterState>({
        category: [],
        priceRange: [],
        brands: [],
        materialType: [],
        rating: [],
    });

    console.log(filters);
    
    const applyFilter = (filterType: keyof FilterState, filterValue: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: prevFilters[filterType].includes(filterValue)
                ? prevFilters[filterType].filter(value => value !== filterValue)
                : [...prevFilters[filterType], filterValue],
        }));
    };

    return (
        <ShopContext.Provider value={{ filters, applyFilter }}>
            {children}
        </ShopContext.Provider>
    );
};

export const useShopContext = () => {
    const context = useContext(ShopContext);
    if (!context) {
        throw new Error("useShopContext must be used within a ShopProvider");
    }
    return context;
};
