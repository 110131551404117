import { Controller, useFormContext } from 'react-hook-form';
import Select from '../select/select';
import { countryDropdownOptions } from '../../../../utils/constant';



export default function VendorShopAddressForm() {
    const { register, formState: { errors }, control } = useFormContext();
    // const onSubmit = (data: any) => console.log(data);
    return (
        <>

            <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>Business Address</h2>

                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    options={countryDropdownOptions}
                                    placeholder='Select your country'
                                    label='Country *'
                                    selectedOption={field.value}
                                    setSelectedOption={(value) => field.onChange(value)} />
                            )}
                        />
                        {errors.country?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Country is required</p>}
                    </div>
                    <div className="w-full relative 2xl:col-span-2 xl:col-span-2 cols-span-1">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.address1 ? "text-red-500" : "text-zinc-800"}`}>Address 1 *</label>
                        <input type="text" placeholder='655 Shaniya Springs, Schowalterfort 46669'  {...register("address1", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.address1 ? "border-red-500" : "border-gray-200"}`} />
                        {errors.address1?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Address 1 is required</p>}
                    </div>
                    <div className="w-full relative 2xl:col-span-2 xl:col-span-2 cols-span-1">
                        <label htmlFor="Address2" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal text-zinc-800`}>Address 2 (Optional)</label>
                        <input type="text" placeholder='96003 Florine Point, North Baronchester 05091-6744' className={`w-full border border-solid rounded-md outline-none h-14 px-4 border-gray-200`} />
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.city ? "text-red-500" : "text-zinc-800"}`}>City/Town *</label>
                        <input type="text" placeholder='Hanford'  {...register("city", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.city ? "border-red-500" : "border-gray-200"}`} />
                        {errors.city?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">City/Town is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.state ? "text-red-500" : "text-zinc-800"}`}>State *</label>
                        <input type="text" placeholder='Port Estefania'  {...register("state", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.state ? "border-red-500" : "border-gray-200"}`} />
                        {errors.state?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">State is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.zipcode ? "text-red-500" : "text-zinc-800"}`}>ZIP Code *</label>
                        <input type="text" placeholder='2322'  {...register("zipcode", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.zipcode ? "border-red-500" : "border-gray-200"}`} />
                        {errors.zipcode?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Zip Code is required</p>}
                    </div>

                </div>
            </div>
        </>
    )
}
