import React from 'react';

const FileInput = ({
    multiple = false,
    accept = 'image/*',
    onChange,
    id,
    showPreview = true,
    initialValue,
    ...props
}: {
    multiple?: boolean;
    accept?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    showPreview?: boolean;
    initialValue?: string;
    props?: any;
}) => {
    const [filePreviews, setFilePreviews] = React.useState<string[]>([]);

    const previewFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            setFilePreviews(prevPreviews => [...prevPreviews, reader.result as string]);
        };
        reader.readAsDataURL(file);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        let files = e.target.files;
        setFilePreviews([]); // Clear existing previews
        for (let i = 0; i < (files?.length || 0); i++) {
            previewFile(files![i]);
        }
    };

    return (
        <>
            <div className="w-full mb-5">
                <label htmlFor={id} className="flex flex-col items-center justify-center w-full border border-gray-300 border-dashed rounded-2xl cursor-pointer bg-gray-50 ">
                    {(filePreviews.length === 0 && initialValue) ?
                        <img loading="lazy" src={initialValue} width={200} height={100} className='w-[200px] h-auto' alt='img' />
                        :
                        filePreviews.length === 0 ?
                            (
                                <div className='p-9'>
                                    <div className="mb-3 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <g id="Upload 02">
                                                <path id="icon" d="M16.296 25.3935L19.9997 21.6667L23.7034 25.3935M19.9997 35V21.759M10.7404 27.3611H9.855C6.253 27.3611 3.33301 24.4411 3.33301 20.8391C3.33301 17.2371 6.253 14.3171 9.855 14.3171V14.3171C10.344 14.3171 10.736 13.9195 10.7816 13.4326C11.2243 8.70174 15.1824 5 19.9997 5C25.1134 5 29.2589 9.1714 29.2589 14.3171H30.1444C33.7463 14.3171 36.6663 17.2371 36.6663 20.8391C36.6663 24.4411 33.7463 27.3611 30.1444 27.3611H29.2589" stroke="#4F46E5" strokeWidth="1.6" strokeLinecap="round" />
                                            </g>
                                        </svg>
                                    </div>
                                    <h4 className="text-center text-gray-900 text-sm font-medium leading-snug">Drag Your file</h4>
                                </div>
                            ) : (
                                showPreview ? multiple ? <div className="w-full mb-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                    {filePreviews.map((preview, index) => (
                                        <img loading="lazy" key={index} src={preview} alt={`File Preview ${index}`} className="w-full h-[200px] object-cover rounded-2xl" />
                                    ))}
                                </div> : <div className='w-full'>
                                    <img loading="lazy" src={filePreviews[0]} alt="File Preview" className="w-full h-[200px] object-cover rounded-2xl" />
                                </div> : <p className="text-gray-900 text-sm font-medium leading-snug">File selected</p>
                            )}
                    <input
                        id={id}
                        type="file"
                        accept={accept}
                        multiple={multiple}
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </label>
            </div>
        </>
    );
};

export default FileInput;
