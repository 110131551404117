// useLocalStorage.js
"use client";
import React from "react";
import { CartItem } from "../types/type";
import { useToast } from "../components/shared/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { setCartLength } from "../features/slices/products-slice";
import { AppState } from "../redux/store";

const useUtils = () => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const cartLength = useSelector((state: AppState) => state.product.cartLength);


    const handleCart = (item: CartItem, set: (val: boolean) => any) => {
        let cartString = localStorage.getItem("my_cart");
        const cart = cartString ? JSON.parse(cartString) : [];

        if (cart.find((cartItem: CartItem) => cartItem.id === item.id)) {
            // remove item from cart
            let newCart = cart.filter((cartItem: CartItem) => cartItem.id !== item.id);
            localStorage.setItem("my_cart", JSON.stringify(newCart));
            dispatch(setCartLength(cartLength - 1));
            showToast("Product removed from cart", "success", "Product Removed");
            set(false);
            return;
        }

        // add item to cart
        let newCart = [...cart, item];
        localStorage.setItem("my_cart", JSON.stringify(newCart));
        dispatch(setCartLength(cartLength + 1));
        showToast("Product added to cart", "success", "Product Added");

        set(true);
    }

    const handleWishlist = (item: CartItem, set: (val: boolean) => any) => {
        let wishlistString = localStorage.getItem("my_wishlist");
        const wishlist = wishlistString ? JSON.parse(wishlistString) : [];

        if (wishlist.find((wishlistItem: CartItem) => wishlistItem.id === item.id)) {
            // remove item from wishlist
            let newWishlist = wishlist.filter((wishlistItem: CartItem) => wishlistItem.id !== item.id);
            localStorage.setItem("my_wishlist", JSON.stringify(newWishlist));
            showToast("Product removed from wishlist", "success", "Product Removed");
            //dispatch(setWishlistLength(wishlistLength - 1));
            set(false);
            return;
        }

        // add item to wishlist
        let newWishlist = [...wishlist, item];
        localStorage.setItem("my_wishlist", JSON.stringify(newWishlist));
        showToast("Product added to wishlist", "success", "Product Added");

        set(true);
    }

    const checkExistInCart = (id: string) => {
        let cartString = localStorage.getItem("my_cart");
        const cart = cartString ? JSON.parse(cartString) : [];
        return cart.find((item: CartItem) => item.id === id);
    }

    const checkExistInWishlist = (id: string) => {
        let wishlistString = localStorage.getItem("my_wishlist");
        const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
        return wishlist.find((item: CartItem) => item.id === id);
    }

    return { handleCart, handleWishlist, checkExistInCart, checkExistInWishlist };
};



/**
 *   const handleAddToCart = (data: any) => {
        let variant = myVariant || product?.variants?.[0];
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item?.id === data?.id)) {
            setAddedToCart(false);
            dispatch(setCartLength(cartLength - 1));
            setCart((prev: any[]) => prev.filter((item) => item?.id !== data?.id));
            return showToast("Your product was removed successfully", "success", "Product Removed");
        }

        console.log(data, product, variant, price);


        dispatch(setCartLength(cartLength + 1));
        setAddedToCart(true);
        let itemToAdd = {
            title: data?.title,
            id: data?.id,
            quantity: quantity,
            src: product?.images?.find((item: any) => item?.id === variant?.image_id)?.src || product?.images?.[0]?.src || product?.image?.src || product?.image,
            price: variant?.price || price,
            variant_id: variant?.id,
        }

        setCart([...cart, itemToAdd]);
        showToast("Your cart has been added successfully!", "success", "Product Added")
    }
 */
export default useUtils;