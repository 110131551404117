import React from 'react'
import MainHeader from '../../components/common/header/main-header'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../../components/common/footer/footer';
import { useStorefrontProductsQuery } from '../../features/api/products-api';
import ProductSkeleton from '../../components/shared/skeleton/ProductSkeleton';
import { CategoryFilters } from '../../components/common/category-filter-sidebar/category-filter-sidebar';
import { ShopProvider, useShopContext } from '../../components/ShopContext';
import { ProductCard } from '../../components/common/products/shop/shop-product';

const CategoryDetailsPage: React.FC = () => {
    const [router] = useSearchParams();
    return (
        <ShopProvider>
            <MainHeader />
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-10 px-5 mb-20'>
                <div className='flex items-center gap-x-3 pt-8'>
                    <Link to={`/`} className='text-base font-medium underline'>Home</Link>
                    <p>/</p>
                    <Link to={`/shop`} className='text-base font-medium underline'>Shop</Link>
                    <p>/</p>
                    <Link to={`#`} className='text-base font-medium underline text-gray-400'>{router.get("search")}</Link>
                </div>

                {/* ======== Category options filters ======== */}
                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col items-start gap-8'>
                    <div className='2xl:w-1/4 xl:w-1/4 md:w-1/4 lg:w-1/4 w-full'>
                        {/* ========== category filters ======= */}
                        <CategoryFilters />
                    </div>
                    {/* ========= products here ========= */}
                    <ShopProducts />
                </div>
            </div>

            <Footer />
        </ShopProvider>
    )
}

const ShopProducts = () => {
    const [router] = useSearchParams();
    const { filters } = useShopContext();
    const { isLoading, data } = useStorefrontProductsQuery({
        limit: 30,
        sortKey: "BEST_SELLING",
        search: router.get('search')?.replace(/%20/g, ' ') || '',
        productType: filters.category.join(','),
        priceRange: filters.priceRange.join('-'),
        brands: filters.brands.join(','),
        materialType: filters.materialType.join(','),
        rating: filters.rating.join(',')
    });
    const products = data?.products;
    return (
        <div className='2xl:w-4/5 xl:w-4/5'>
            <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 gap-x-6 gap-y-8'>
                
                   {data?.products && data?.products.length > 0 ? (
                     <>
                     {isLoading ?
                         Array(6).fill(0).map((_, i) => (
                             <ProductSkeleton key={i} />
                         ))
                         : data?.products?.map((item: any, index: number) => (
                             <ProductCard
                                 item={item}
                                 id={item.id}
                             />
                         ))}
                 </>
                   ) : (
                     <p className='text-center text-gray-600 mt-6'>No products found.</p> 

                   )}
            </div>
        </div>
    )
}


export default CategoryDetailsPage


