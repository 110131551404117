// ToastContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';

type ToastType = 'success' | 'error' | 'info';

interface ToastContextType {
    showToast: (message: string, type?: ToastType, title?: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [toast, setToast] = useState<{ message: string; type: ToastType; title?: string } | null>(null);
    const [progress, setProgress] = useState<number>(100);
    const timeoutRef = useRef<number | undefined>(undefined);

    const showToast = (message: string, type: ToastType = 'info', title?: string) => {
        setToast({ message, type, title });
        setProgress(100);

        // Clear existing timeout
        if (timeoutRef.current !== undefined) {
            clearTimeout(timeoutRef.current);
        }
        // Set a new timeout
        timeoutRef.current = window.setTimeout(() => setToast(null), 10000); // Hide after 3 seconds
        // Update progress
        const start = Date.now();
        const duration = 10000;
        // const interval = 10;
        const updateProgress = () => {
            const elapsed = Date.now() - start;
            const percent = Math.max(0, 100 - (elapsed / duration) * 100);
            setProgress(percent);
            if (percent > 0) {
                requestAnimationFrame(updateProgress);
            }
        };

        updateProgress();
    };
    useEffect(() => {
        return () => {
            if (timeoutRef.current !== undefined) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [])

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toast && (
                <>
                    <div
                        className="fixed inset-0 bg-black opacity-50 z-40"
                        style={{ pointerEvents: 'auto' }}
                    />
                    <div
                        className="fixed inset-0 flex items-center justify-center z-50"
                    >
                        <div className={`relative w-full max-w-xl pt-8 mx-4 shadow-lg rounded-lg ${toast.type === 'success' ? 'bg-white text-black' : toast.type === 'error' ? 'bg-white text-black' : 'bg-blue-500 text-white'}`}>
                            {toast.type === "error" && (
                                <>
                                    <div className='flex justify-center'>
                                        <img src="/assets/images/error.svg" loading='lazy' alt="Error" className='size-12' />
                                    </div>
                                    <h1 className='text-lg text-center text-red-500 uppercase font-bold mt-4'>Error!</h1>
                                    <div className='p-4'>
                                        {toast.message && (
                                            <h1 className="text-base italic text-center font-medium">{toast.message}</h1>
                                        )}
                                    </div>
                                    <div className='flex justify-end p-3'>
                                        <button
                                            className="mt-4 px-8 py-2 bg-[#FF5F00] text-white rounded"
                                            onClick={() => setToast(null)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div
                                        className="absolute bottom-0 left-0 right-0 h-1 bg-red-500 rounded-full"
                                        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
                                    />
                                </>
                            )}
                            {toast.type === "success" && (
                                <>
                                    <div className='flex justify-center'>
                                        <img src="/assets/images/success.svg" loading='lazy' alt="Error" className='size-12' />
                                    </div>
                                    <h1 className='text-lg text-center text-green-500 uppercase font-bold mt-4'>Success!</h1>
                                    <div className='p-4'>
                                        {toast.message && (
                                            <h1 className="text-base italic text-center font-medium">{toast.message}</h1>
                                        )}
                                    </div>
                                    <div className='flex justify-end p-3'>
                                        <button
                                            className="mt-4 px-8 py-2 bg-[#FF5F00] text-white rounded"
                                            onClick={() => setToast(null)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div
                                        className="absolute bottom-0 left-0 right-0 h-1 bg-green-500 rounded-full"
                                        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

export default ToastProvider