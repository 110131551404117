import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryApi } from '@reduxjs/toolkit/query';
import { API_URL } from '../utils/utils';

export const requestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true,
  credentials: 'include' as RequestCredentials, // Make sure the type is correctly inferred
};

export const commonApi = createApi({
  reducerPath: 'common-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/`,
    prepareHeaders: (headers: Headers, api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `${token}`); // Ensure the correct format if necessary
      }
      return headers;
    },
    ...requestConfig,
  }),
  tagTypes: [
    'Auth', 'Product', 'Order', 'shopify', 'product', 'magic-club', 
    'ebook', 'wallet', 'e-club', 'coupon', 'ad', 'vendor-business', 
    'admincustomers',
  ],
  endpoints: (builder) => ({}),
});

export default commonApi;
