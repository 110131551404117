import React from 'react'
import MainHeader from '../../components/common/header/main-header'
import { Link } from 'react-router-dom'
import Footer from '../../components/common/footer/footer'

export default function MyWalletPage() {
    return (
        <>
            <MainHeader />

            <div className="w-full h-96 bg-gradient-to-r from-[#7c74de] to-[#382fa2]">
                <div className='max-w-screen-xl mx-auto pt-20 2xl:px-0 xl:px-0 px-8'>
                    <div className='flex 2xl:flex-row xl:flex-row justify-between w-full 2xl:items-center xl:items-center items-start md:flex-row lg:flex-row flex-col'>
                        <div className='flex flex-col'>
                            <h1 className='2xl:text-5xl xl:text-5xl text-xl font-bold text-white'>
                                My Wallet
                            </h1>
                            <p className='text-lg text-white mt-4 font-medium 2xl:text-left xl:text-left text-center'>
                                Track your earnings, spending, and manage your funds.
                            </p>
                        </div>
                        <div className='2xl:w-1/2 xl:w-1/2 w-full flex justify-end'>
                            <img src="/assets/images/wallet.png" className='w-1/2' alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <h1 className='2xl:text-3xl xl:text-3xl text-xl font-bold text-center mt-14'>
                Withdrawal Summary
            </h1>
            <div className='max-w-screen-2xl mx-auto pt-20'>
                <div className='border border-solid border-gray-300 rounded-2xl p-14'>
                    <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-10'>
                        <div className='w-full bg-gray-100 p-8 rounded-lg'>
                            <h6 className="text-gray-500 text-xl font-medium">Total Balance</h6>
                            <h2 className="text-black text-2xl font-medium mt-5">$45000</h2>
                        </div>
                        <div className='w-full bg-gray-100 p-8 rounded-lg'>
                            <h6 className="text-gray-500 text-xl font-medium">Total Spending</h6>
                            <h2 className="text-black text-2xl font-medium mt-5">$5000</h2>
                        </div>
                        <div className='w-full bg-gray-100 p-8 rounded-lg'>
                            <h6 className="text-gray-500 text-xl font-medium">Withdrawl Amount</h6>
                            <h2 className="text-black text-2xl font-medium mt-5">$3000</h2>
                        </div>
                    </div>
                    <div className='flex 2xl:flex-row xl:flex-row flex-col items-center w-full gap-5 pt-8'>
                        <button type="button" className='w-full py-3 bg-primary uppercase text-lg rounded-md'>
                            Withdraw Funds
                        </button>
                        <button type="button" className='w-full py-3 border border-solid border-black uppercase text-lg rounded-md'>
                            Add Funds
                        </button>
                    </div>
                </div>
            </div>
            <h1 className='2xl:text-3xl xl:text-3xl text-xl font-medium text-center mt-14'>
                Recent Transaction
            </h1>
            <div className='max-w-screen-2xl mx-auto pt-14 pb-32'>
                <div className="w-full bg-[#ffefd2] rounded-2xl 2xl:p-14 xl:p-14 p-10">
                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right">
                            <thead className="text-base text-gray-700 capitalize">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Date
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Amount
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        Aug 15, 2024
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        Purchased: Bluetooth Headphones
                                    </td>
                                    <td className="px-6 py-4">
                                        $120.00
                                    </td>
                                    <td className="px-6 py-4 text-green-600">
                                        Completed
                                    </td>
                                </tr>
                                <tr className="">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        Aug 16, 2024
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        Earned: Referral Bonus
                                    </td>
                                    <td className="px-6 py-4">
                                        +$50.00
                                    </td>
                                    <td className="px-6 py-4 text-green-600">
                                        Completed
                                    </td>
                                </tr>
                                <tr className="gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        Aug 17, 2024
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        Withdrawal to Bank Account
                                    </td>
                                    <td className="px-6 py-4">
                                        -$600.00
                                    </td>
                                    <td className="px-6 py-4 text-red-600">
                                        Pending
                                    </td>
                                </tr>
                                <tr className="gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        Aug 18, 2024
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        Purchased: Fashion Sneakers
                                    </td>
                                    <td className="px-6 py-4">
                                        -$400.00
                                    </td>
                                    <td className="px-6 py-4 text-green-600">
                                        Completed
                                    </td>
                                </tr>
                                <tr className="gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        Aug 19, 2024
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        Earned: Cashback from Purchase
                                    </td>
                                    <td className="px-6 py-4">
                                        -$800.00
                                    </td>
                                    <td className="px-6 py-4 text-red-600">
                                        Pending
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-center pt-14'>
                        <Link to={``} className='underline font-medium'>View more</Link>
                    </div>
                </div>

                <h1 className='2xl:text-3xl xl:text-3xl text-xl font-medium text-center mt-14'>
                    Earnings Overview
                </h1>
                <div className='w-full p-4 py-12 border border-solid border-gray-300 mt-12 rounded-2xl'>
                    <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 grid-cols-1 gap-10'>
                        <div className='w-full flex flex-col items-center'>
                            <h6 className="text-gray-500 text-xl font-medium">Referral Bonuses</h6>
                            <h2 className="text-black text-2xl font-medium">30%</h2>
                        </div>
                        <div className='w-full flex flex-col items-center'>
                            <h6 className="text-gray-500 text-xl font-medium">Cashback Rewards</h6>
                            <h2 className="text-black text-2xl font-medium">40%</h2>
                        </div>
                        <div className='w-full flex flex-col items-center'>
                            <h6 className="text-gray-500 text-xl font-medium">Promotional Bonuses</h6>
                            <h2 className="text-black text-2xl font-medium">15%</h2>
                        </div>
                        <div className='w-full flex flex-col items-center'>
                            <h6 className="text-gray-500 text-xl font-medium">Product Reviews</h6>
                            <h2 className="text-black text-2xl font-medium">15%</h2>
                        </div>
                    </div>

                </div>

                <h1 className='2xl:text-3xl xl:text-3xl text-xl font-medium text-center mt-14'>
                    Exclusive Deals for Wallet Users
                </h1>
            </div>

            <Footer />
        </>
    )
}
