import { useNavigate } from "react-router-dom"
import { useDeleteBusinessMutation, useGetBusinessesByIdQuery } from "../../features/slices/vendor-api";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteDialog from "../../components/common/ui/forms/dialog/delete-dialog";
import { useToast } from "../../components/shared/ToastContext";

export default function VendorShopManagmentPage() {
    const navigate = useNavigate();
    const user = useSelector((state: AppState) => state.auth.user)
    const { isLoading, data } = useGetBusinessesByIdQuery(user?._id, {
        skip: !user?._id,
        refetchOnFocus: true,
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [deleteShop, { error }] = useDeleteBusinessMutation();
    const { showToast } = useToast();
    const handleDelete = async (id: any) => {
        setIsDialogOpen(true);
        setSelectedItemId(id);
    }

    const handleConfirm = async () => {
        if (selectedItemId) {
            try {
                await deleteShop(selectedItemId);
                showToast("Shop deleted successfully", "success", "Shop deleted successfully")
            } catch (error) {
                showToast("Failed to delete shop", "error", "Failed to delete shop");
            } finally {
                setIsDialogOpen(false);
                setSelectedItemId(null);
            }
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setSelectedItemId(null);
    }


    useEffect(() => {
        if (error) {
            showToast("Failed to delete shop!", "error", "Failed to delete shop");
        }
    }, [error]);

    console.log(data);


    return (
        <>
            <div className='flex flex-wrap gap-6 justify-between items-center pt-4'>
                <h2 className='text-black text-[22px] font-medium mt-6 leading-snug'>Business Management</h2>
                <button type='button' className="w-fit h-[46px] px-10 py-3 bg-yellow-300 rounded-[100px] shadow justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-zinc-800 text-xl font-medium leading-snug" onClick={() => navigate("/vendor-panel/add-new-business")}>Create New Business</div>
                </button>
            </div>
            {isLoading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <img loading="lazy" src="/assets/images/gif/spinner.svg" alt="" />
                </div>
            ) : (
                <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 pt-8 lg:grid-cols-3 grid-cols-1 gap-x-6 gap-6'>
                    {data?.map((item: any) => (
                        <div key={item._id} className='w-full bg-white p-5 rounded-3xl'>
                            <div className="w-full justify-start items-center gap-3 inline-flex">
                                <div className="p-[3px] rounded-full border-2 border-dashed border-neutral-500 justify-center bg-white size-14 items-center gap-2.5 flex">
                                    <img className="size-full rounded-full" src={item?.profile} alt="Profile" />
                                </div>
                                <div className="flex-col justify-start items-start gap-2 inline-flex">
                                    <div className="text-black text-sm font-medium">{item.businessName}</div>
                                    <div className="justify-start items-start gap-[5px] inline-flex">
                                        <div className="size-4 flex justify-center items-center relative">
                                            <img loading="lazy" src="/assets/images/svg/location.svg" alt="Location" />
                                        </div>
                                        <div className="w-[207px] text-neutral-500 text-[11px] font-normal">{item.address1} {item.address2} </div>
                                    </div>
                                    <div className="justify-center items-center gap-[5px] inline-flex">
                                        <div className="w-3.5 h-3.5 relative">
                                            <img loading="lazy" src="/assets/images/svg/call.svg" alt="Location" />
                                        </div>
                                        <div className="text-neutral-500 text-[11px] font-normal">{item.phone}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full justify-between pt-5 items-start gap-1.5 inline-flex">
                                <div className="pr-[7px] border-r border-black/opacity-10 flex-col justify-center items-center gap-[5px] inline-flex">
                                    <div className="text-black text-base font-medium">10%</div>
                                    <div className="text-neutral-500 text-sm font-normal">Commission</div>
                                </div>
                                <div className="pr-[7px] border-r border-black/opacity-10 flex-col justify-center items-center gap-[5px] inline-flex">
                                    <div className="text-black text-base font-medium">0</div>
                                    <div className="w-[68px] text-center text-neutral-500 text-sm font-normal">Sale</div>
                                </div>
                                <div className="pr-[7px] border-r border-black/opacity-10 flex-col justify-center items-center gap-[5px] inline-flex">
                                    <div className="text-black text-base font-medium">$0</div>
                                    <div className="w-[68px] text-center text-neutral-500 text-sm font-normal">Balance</div>
                                </div>
                                <div className="flex-col justify-center items-center gap-[5px] inline-flex">
                                    <div className="text-black text-base font-medium">0</div>
                                    <div className="w-[60px] text-center text-neutral-500 text-sm font-normal">Products</div>
                                </div>
                            </div>

                            <div className="w-full justify-between pt-8 items-center gap-6 inline-flex flex-wrap">
                                <div className="justify-center items-center gap-[5px] flex cursor-pointer">
                                    <Link to={`/vendor-panel/view-shop/${item._id}`} className="pb-[5px] border-b border-neutral-500 justify-center items-center gap-[5px] flex">
                                        <div className="text-neutral-500 text-sm font-medium">View Business</div>
                                    </Link>
                                </div>
                                <div className="justify-start items-start gap-[15px] flex cursor-pointer">
                                    <Link to={`/vendor-panel/edit-business-management/${item._id}`} className="px-6 py-2 bg-yellow-300 rounded-[100px] justify-center items-center flex">
                                        <div className="text-black text-sm font-medium">Edit Business</div>
                                    </Link>
                                    <button type="button" onClick={() => handleDelete(item._id)} className="px-6 py-2 bg-white hover:bg-black hover:text-white rounded-full border border-black justify-center items-center flex">
                                        <div className=" text-sm font-medium hover:text-white">Remove</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <DeleteDialog
                open={isDialogOpen}
                title="Confirm Deletion"
                onClose={handleClose}
                onConfirm={handleConfirm}
            >
                <p>Are you sure you want to delete this item?</p>
            </DeleteDialog>
        </>
    )
}
