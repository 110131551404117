import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetBusinessByIdQuery } from '../../features/slices/vendor-api';
import { Link } from 'react-router-dom';
import { useToast } from '../../components/shared/ToastContext';



export default function ViewShopsPage() {
  const { id } = useParams();
  const { isLoading, data, isError } = useGetBusinessByIdQuery(id!, {
    skip: !id,
  });
  const { showToast } = useToast();
  
  useEffect(() => {
    if (isError) {
      showToast("Error getting business data from business ", "error", "Error getting business");
    }
  }, [isError])


  return (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center min-h-screen'>
          <img loading="lazy" src="/assets/images/gif/spinner.svg" className='size-24 rounded-full' alt="" />
        </div>
      ) : (
        <>
          <div className='w-full p-4 bg-white rounded-2xl'>
            <div className='w-full relative'>
              <img loading="lazy" src={`${data?.backgroundImage}`} className='w-full 2xl:h-64 xl:h-64 md:h-64 lg:h-64 h-44 object-cover rounded-2xl' alt="" />

              <div className='2xl:size-28 xl:size-28 md:size-28 lg:size-28 size-16 absolute 2xl:bottom-5 xl:bottom-5 md:bottom-5 lg:bottom-5 top-36 left-3 p-1 rounded-full bg-white flex justify-center items-center border border-dashed border-black'>
                <img loading="lazy" src={`${data?.profile}`} className='rounded-full' alt="" />
              </div>

              <div className='flex flex-wrap justify-end gap-8 pt-10 w-full'>
                <div className='flex items-center gap-1'>
                  <img loading="lazy" src="/assets/images/svg/ion_location.svg" alt="" />
                  <p className='text-sm'>
                    {data?.address1}{","} {data?.address2}
                  </p>
                </div>
                <div className='flex items-center gap-1'>
                  <img loading="lazy" src="/assets/images/svg/fluent_call-16-regular.svg" alt="" />
                  <p className='text-sm'>
                    {data?.phone}
                  </p>
                </div>
                <Link to={`/vendor-panel/edit-business-management/${data?._id}`} className="w-fit h-[47px] text-black text-base font-medium px-10 py-3 bg-yellow-300 rounded-full justify-center items-center inline-flex">
                  Edit Business
                </Link>
              </div>
            </div>
            <div className="2xl:w-[153px] xl:w-[153px] md:w-[153px] lg:w-[153px] w-full 2xl:text-center xl:text-center md:text-center lg:text-center text-left text-black text-xl font-medium leading-snug">{data?.businessName}</div>

            {/* Add shop list */}
          </div>

          <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 mt-10 md:grid-cols-3 lg:grid-cols-3 gap-8'>
            <div className='w-full bg-white rounded-xl p-4'>
              <div className='flex justify-between'>
                <div className='flex flex-col gap-3'>
                  <h3 className="text-black text-lg font-medium">Total Products</h3>
                  <h1 className="text-black text-2xl font-bold">0</h1>
                </div>
                <div className="size-14 flex justify-center items-center bg-yellow-300 rounded-xl">
                  <img loading="lazy" src="/assets/images/svg/check-circle.svg" alt="" />
                </div>
              </div>
            </div>
            <div className='w-full bg-white rounded-xl p-4'>
              <div className='flex justify-between'>
                <div className='flex flex-col gap-3'>
                  <h3 className="text-black text-lg font-medium">Total Orders</h3>
                  <h1 className="text-black text-2xl font-bold">$0</h1>
                </div>
                <div className="size-14 flex justify-center items-center bg-yellow-300 rounded-xl">
                  <img loading="lazy" src="/assets/images/svg/cube-check.svg" alt="" />
                </div>
              </div>
            </div>
            <div className='w-full bg-white rounded-xl p-4'>
              <div className='flex justify-between'>
                <div className='flex flex-col gap-3'>
                  <h3 className="text-black text-lg font-medium">Admin Commission</h3>
                  <h1 className="text-black text-2xl font-bold">10%</h1>
                </div>
                <div className="size-14 flex justify-center items-center bg-yellow-300 rounded-xl">
                  <img loading="lazy" src="/assets/images/svg/user-group.svg" alt="" />
                </div>
              </div>
            </div>
            <div className='w-full bg-white rounded-xl p-4'>
              <div className='flex justify-between'>
                <div className='flex flex-col gap-3'>
                  <h3 className="text-black text-lg font-medium">Current Balance</h3>
                  <h1 className="text-black text-2xl font-bold">$0</h1>
                  <span className="text-neutral-500 text-xs font-normal">20% this week</span>
                </div>
                <div className="size-14 flex justify-center items-center bg-yellow-300 rounded-xl">
                  <img loading="lazy" src="/assets/images/svg/cart-check.svg" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className='w-full p-6 bg-white rounded-2xl mt-8'>
            <h1 className="text-neutral-500 text-lg font-medium leading-snug">Registered Since</h1>
            <h1 className="text-black text-[22px] font-medium mt-2 leading-snug">{data?.createdAt.slice(0, 10)}</h1>

            <h4 className="text-black text-[22px] font-medium mt-6 leading-snug">Bio</h4>

            <p className="text-neutral-500 text-base font-normal mt-3 leading-snug">{data?.state}</p>
          </div>

          <div className='w-full p-6 bg-white rounded-2xl mt-8'>
            <h1 className="text-black text-[22px] font-medium mt-2 leading-snug">Payment Information</h1>
            <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 mt-10 md:grid-cols-3 lg:grid-cols-3 gap-8'>
              <div className='flex flex-col gap-2 w-full'>
                <h6 className="text-neutral-500 text-lg font-normal leading-snug">Name</h6>
                <h5 className="text-black text-lg font-normal leading-snug">{data?.businessName}</h5>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <h6 className="text-neutral-500 text-lg font-normal leading-snug">Email</h6>
                <h5 className="text-black text-lg font-normal leading-snug">{data?.email}</h5>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <h6 className="text-neutral-500 text-lg font-normal leading-snug">Bank</h6>
                <h5 className="text-black text-lg font-normal leading-snug">Bank of {data?.country}</h5>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <h6 className="text-neutral-500 text-lg font-normal leading-snug">Account No</h6>
                <h5 className="text-black text-lg font-normal leading-snug">{data?.phone}</h5>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  )
}