import MyDraftTable from '../../components/common/ui/my-draft-table'
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function MyDraftPage() {
    return (
        <>
            <div className='w-full bg-white rounded-xl p-8'>
                <div className='flex 2xl:flex-row md:flex-row xl:flex-row lg:flex-row flex-col gap-10 justify-between items-center'>
                    <h2 className="text-black text-[22px] font-medium leading-snug">My Draft</h2>
                    <div className="2xl:w-[526.31px] xlw-[526.31px] w-full h-[46px] px-[25px] py-[15px] bg-neutral-100 rounded-[5px] border border-black/10 justify-start items-center gap-[418px] inline-flex">
                        <input placeholder='Search' className="w-full border-none outline-none bg-transparent h-4 text-neutral-500 text-sm font-normal" />
                    </div>
                    <div className='flex items-center gap-2'>
                        <h2 className="text-black text-base font-medium leading-snug">Filter</h2>
                        <MdKeyboardArrowDown className="text-xl" />
                    </div>
                </div>

                <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Shop</label>
                        <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Category</label>
                        <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Product Type</label>
                        <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full bg-white rounded-xl p-5 mt-8'>
                <MyDraftTable />
            </div>
        </>
    )
}
