import { MdKeyboardArrowDown } from "react-icons/md";
import { Input } from "../../types/type";
import { SubmitHandler, useForm } from "react-hook-form";

export default function AdminProfilePage() {
    const { register, handleSubmit, formState: { errors } } = useForm<Input>();
    const onSubmit: SubmitHandler<Input> = (data: any) => console.log(data);


    return (
        <>
            <div className="w-full bg-white p-8 rounded-3xl">
                <div className="w-full h-fit py-4 rounded-lg relative flex justify-center items-center bg-zinc-100 border border-dashed border-zinc-300">
                    <div className="flex flex-col gap-1.5 items-center">
                        <img loading="lazy" src="/assets/images/svg/upload.svg" alt="" />
                        <p className="text-neutral-500">
                            <span className="text-black">Upload your image</span> or drag and drop.
                        </p>
                        <span className="text-sm font-normal text-neutral-500">(PNG, JPEG are supported) 400px max size.</span>
                    </div>

                    <img loading="lazy" src="/assets/images/admin-profile.png" className="absolute left-8 size-36" alt="" />
                </div>
                <div className="px-4">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Hector Micheal </h2>
                    <p className="text-left text-zinc-800 text-base font-normal leading-snug">@Username</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                        <div className="w-full relative">
                            <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.fname ? "text-red-500" : "text-zinc-800"}`}>First Name *</label>
                            <input type="text" {...register("fname", { required: true, pattern: /^[A-Za-z]+$/i })}  aria-invalid={errors.fname ? "true" : "false"}  placeholder="Super" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.fname ? "border-red-500" : "border-gray-200"}`} />
                            {errors.fname?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">First name is required</p>}
                            {errors.fname?.type === 'pattern' && <p role="alert" className="text-sm text-red-500 mt-2">Please enter valid First Name</p>}
                        </div>
                        <div className="w-full relative">
                            <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.lname ? "text-red-500" : "text-zinc-800"}`}>Last Name *</label>
                            <input type="text" {...register("lname", { required: true, pattern: /^[A-Za-z]+$/i })} placeholder="Admin" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.lname ? "border-red-500" : "border-gray-200"}`} />
                            {errors.lname?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Last name is required</p>}
                            {errors.lname?.type === 'pattern' && <p role="alert" className="text-sm text-red-500 mt-2">Please enter valid Last Name</p>}
                        </div>
                        <div className="w-full relative">
                            <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.emailAddress ? "text-red-500" : "text-zinc-800"}`}>Email Address *</label>
                            <input type="text" {...register("emailAddress", { required: true })} placeholder="admin@admin.com" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.emailAddress ? "border-red-500" : "border-gray-200"}`} />
                            {errors.emailAddress?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Email address is required</p>}
                            {errors.emailAddress?.type === 'pattern' && <p role="alert" className="text-sm text-red-500 mt-2">Please enter valid Last Name</p>}
                        </div>
                        <div className="w-full relative">
                            <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.contactNumber ? "text-red-500" : "text-zinc-800"}`}>Contact Number *</label>
                            <input type="number" {...register("contactNumber", { required: true })} placeholder="+91" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.contactNumber ? "border-red-500" : "border-gray-200"}`} />
                            {errors.contactNumber?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Contact Number is required</p>}
                            {errors.contactNumber?.type === 'pattern' && <p role="alert" className="text-sm text-red-500 mt-2">Please enter valid Last Name</p>}
                        </div>
                        <div className="w-full relative col-span-2">
                            <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.aboutDescription ? "text-red-500" : "text-zinc-800"}`}> About Description *</label>
                            <textarea rows={5} cols={20} {...register("aboutDescription", { required: true, pattern: /^[A-Za-z]+$/i })} placeholder="About Description Write Here" className={`w-full resize-none border border-solid p-4 rounded-md outline-none px-4 ${errors.aboutDescription ? "border-red-500" : "border-gray-200"}`} />
                            {errors.aboutDescription?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">About Description is required</p>}
                            {errors.aboutDescription?.type === 'pattern' && <p role="alert" className="text-sm text-red-500 mt-2">Please enter valid Description</p>}
                        </div>
                        <div className="flex justify-end gap-8 col-span-2">
                            <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                Save
                            </button>
                            <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='w-full h-fit p-8 pt-5 bg-white rounded-3xl mt-8'>
                <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Change Password</h2>

                <div>
                    <div className='w-full pt-12'>
                        <div className="w-full relative pb-8">
                            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Old Password *</label>
                            <input type="password" placeholder='•••••••••••••' className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                            <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                        </div>
                        <div className="w-full relative pb-8">
                            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">New Password *</label>
                            <input type="password" placeholder='•••••••••••••' className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                            <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                        </div>
                        <div className="w-full relative pb-8">
                            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Re-enter Password *</label>
                            <input type="password" placeholder='•••••••••••••' className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                            <img loading="lazy" src="/assets/images/svg/eye.svg" className='absolute top-4 right-4 cursor-pointer' alt="" />
                        </div>
                        <div className="flex justify-end gap-8 pt-8">
                            <button type="button" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                Change Password
                            </button>
                            <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Social Profile Links</h2>
                    <h2 className="text-black text-[15px] font-medium mt-6 leading-snug">+ Add Profile</h2>
                </div>
                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <img loading="lazy" src="/assets/images/facebook.png" alt="" />
                                    <label htmlFor="" className="text-sm bg-white font-normal">Facebook</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative">
                        <input type="text" placeholder="Add Link Here......" className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                    </div>
                    <div className="flex justify-end gap-8 col-span-3">
                        <button type="button" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            Save
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>E-mail Notifications</h2>

                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative col-span-2">
                        <div className="mb-4 text-neutral-500 text-sm font-normal leading-snug">Set your email notification for messaging feature.</div>
                        <textarea rows={4} placeholder='Write message here..........' className="w-full resize-none p-3 border border-solid border-gray-200 rounded-md outline-none px-4" />
                    </div>
                </div>

                <div className="w-full pt-12 h-[39px] justify-end items-center gap-[15px] inline-flex">
                    <div className="p-[7px] bg-gray-200 rounded-[100px] justify-start items-start gap-[15px] flex">
                        <div className="w-[25px] h-[25px] bg-neutral-500 rounded-full"></div>
                        <div className="w-[25px] h-[25px] bg-gray-200 rounded-full"></div>
                    </div>
                    <div className="w-[174px] text-right text-black text-xl font-medium leading-snug">Enable Notification</div>
                </div>
            </div>
        </>
    )
}
