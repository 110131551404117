import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductState {
    cartLength: number;
    discount: number;
    subtotal: number;
    shippingCharges: number;
    tax: number;
    search: string,
    category: string
    // Add other product-related state as needed
}

const initialState: ProductState = {
    cartLength: 0,
    discount: 0,
    subtotal: 0,
    shippingCharges: 0,
    tax: 0,
    search: "",
    category: ""
    // Initialize other state properties here
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setCartLength(state, action: PayloadAction<number>) {
            state.cartLength = action.payload;
        },
        setDiscount(state, action: PayloadAction<number>) {
            state.discount = action.payload;
        },
        setSubtotal(state, action: PayloadAction<number>) {
            state.subtotal = action.payload;
        },
        setSearch(state, action: PayloadAction<string>) {
            state.search = action.payload;
        },
        setCategory(state, action: PayloadAction<string>) {
            state.category = action.payload;
        },
    },
});

export const { setCartLength
    , setDiscount
    , setSubtotal
    , setCategory
    , setSearch
} = productSlice.actions;
export default productSlice.reducer;
