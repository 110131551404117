import { Link } from 'react-router-dom'
import useLocalStorage from '../../hooks/useLocalStorage';
import { useGetProfileQuery, useVerifyEmailMutation } from '../../features/slices/api-slices';
import { useEffect, useState } from 'react';
import { useToast } from '../../components/shared/ToastContext';
import { GoUnverified, GoVerified } from 'react-icons/go';

export default function UserPannelProfilePage() {

    const { data, isError, isLoading } = useGetProfileQuery();
    const [verify, { isLoading: isVerifying, isSuccess }] = useVerifyEmailMutation();

    const { showToast } = useToast();

    const handleActivateEmail = () => {
        verify({
            email: data?.email
        });
    }

    useEffect(() => {
        if (isError) {
            showToast("Error loading profile!", "error", "Failed to load profile");
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            showToast("Email verification link sent successfully", "success", "Email Sent");
        }
    }, [isSuccess]);

    return (
        <>
            <div className='w-full p-8 bg-white rounded-2xl'>
                {isLoading ? (
                    <div className='flex justify-center items-center min-h-screen'>
                        <img loading="lazy" src="/assets/images/gif/spinner.svg" className='size-24' alt="Spiner" />
                    </div>
                ) : (
                    <>
                        <div className='w-full relative h-auto'>
                            <img
                                src='/auth.png'
                                alt='BannerImage'
                                className='w-full 2xl:h-56 xl:h-56 md:h-56 lg:h-56 h-24 object-cover rounded-2xl' />
                            <div className='absolute 2xl:top-32 xl:top-32 top-0 left-8 2xl:size-36 xl:size-36 size-24 rounded-full flex items-center justify-center'>
                                <img loading="lazy" src={`${data?.avatar}`} className="rounded-full w-full" alt="Avatar" />
                            </div>
                        </div>

                        <div className='flex 2xl:flex-row xl:flex-row flex-col gap-4 justify-between pt-16 2xl:items-center xl:items-center items-start'>
                            <div className='flex flex-col items-center'>
                                <h4 className='capitalize text-xl font-medium cursor-pointer'>
                                    {data?.name}
                                </h4>
                            </div>
                            <Link to={`/vendor-panel/edit-vendor-profile`} className='px-10 py-2.5 text-sm bg-yellow-300 text-black rounded'>
                                Edit your Profile
                            </Link>
                        </div>

                        <h1 className='text-lg font-medium mt-8'>
                            Account Information
                        </h1>
                        {data ? (
                            <div className='flex 2xl:flex-row xl:flex-row flex-col justify-between pt-5 items-center'>
                                <div className='2xl:w-1/2 xl:w-1/2 w-full space-y-5'>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/email-sm.svg" className='size-5' alt="Email" />
                                        <h4 className='font-medium flex items-center'>
                                            Email
                                            {
                                                isVerifying ? <div className='size-4 ml-2 spinner-border text-blue-500' /> :
                                                    data?.is_email_verified === 0 ?
                                                        <GoUnverified className='text-red-500 size-4 ml-2 cursor-pointer' onClick={handleActivateEmail} /> :
                                                        <GoVerified className='text-green-500 size-4 ml-2' />
                                            }
                                        </h4>
                                    </div>
                                    <span className='text-sm text-neutral-400 pl-7'>{data.email}</span>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/call-2.svg" className='size-5' alt="call" />
                                        <h4 className='font-medium'>
                                            Phone Number
                                        </h4>
                                    </div>
                                    <span className='text-sm text-neutral-400 pl-8'>{data.phone}</span>
                                </div>
                                <div className='2xl:w-1/2 xl:w-1/2 w-full space-y-2'>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/location-sm.svg" className='size-5' alt="location" />
                                        <h4 className='font-medium'>
                                            Address
                                        </h4>
                                    </div>
                                    <span className='text-sm pl-7'>
                                        {data.address_1}
                                    </span>
                                    {data?.address_2 && <p className='text-sm pl-7 mt-0'>
                                        {data.address_2}
                                    </p>}
                                    <p className='text-sm pl-7 mt-0'>
                                        {data.city + ', ' + data.state + ', ' + data.zip}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className='flex justify-between pt-5 items-center'>
                                <div className='2xl:w-1/2 xl:w-1/2 w-full space-y-5'>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/email-sm.svg" className='size-5' alt="email" />
                                        <h4 className='font-medium'>
                                            Email
                                        </h4>
                                    </div>
                                    <span className='text-sm text-neutral-400 pl-7'>gertrude@gmail.com</span>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/call-2.svg" className='size-5' alt="call" />
                                        <h4 className='font-medium'>
                                            Phone Number
                                        </h4>
                                    </div>
                                    <span className='text-sm text-neutral-400 pl-8'>613.531.8040</span>
                                </div>
                                <div className='2xl:w-1/2 xl:w-1/2 w-full space-y-2'>
                                    <div className='flex items-center gap-2'>
                                        <img loading="lazy" src="/assets/images/svg/location-sm.svg" className='size-5' alt="location" />
                                        <h4 className='font-medium'>
                                            Address
                                        </h4>
                                    </div>
                                    <span className='text-sm pl-7'>
                                        <b className='font-medium'>
                                            Line 1 : &nbsp;
                                        </b>
                                        655 Shaniya Springs, Schowalterfort 46669
                                    </span>
                                    <p className='text-sm pl-7 mt-0'>
                                        655 Shaniya Springs, Schowalterfort 46669
                                    </p>
                                    <p className='text-sm pl-7 mt-0'>
                                        <b className='font-medium'>
                                            State :  &nbsp;
                                        </b>
                                        655 Shaniya Springs, Schowalterfort 46669
                                    </p>
                                    <p className='text-sm pl-7 mt-0'>
                                        <b className='font-medium'>
                                            City : &nbsp;
                                        </b>
                                        655 Shaniya Springs, Schowalterfort 46669
                                    </p>
                                    <p className='text-sm pl-7 mt-0'>
                                        <b className='font-medium'>
                                            Zip Code : &nbsp;
                                        </b>
                                        3456765
                                    </p>
                                </div>
                            </div>
                        )}
                        <h1 className='text-lg font-medium mt-10'>
                            About me
                        </h1>
                        <p className='text-base mt-1'>
                            {data?.bio}
                        </p>

                        {/* <h1 className='text-lg font-medium mt-10'>Social Profile Links</h1>
                <div className='flex items-center gap-4 pt-4 w-full'>
                    <div className='flex items-center gap-2'>
                        <img loading="lazy" src="/assets/images/svg/facebook.svg" className='size-4' alt="" />
                        <span>facebook.com/shopname</span>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img loading="lazy" src="/assets/images/svg/twitter.svg" className='size-4' alt="" />
                        <span>instagram.com/shopname</span>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img loading="lazy" src="/assets/images/svg/instagram.svg" className='size-4' alt="" />
                        <span>instagram.com/shopname</span>
                    </div>
                </div> */}
                    </>
                )}
            </div>
        </>
    )
}
