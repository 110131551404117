import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForgotPasswordMutation } from "../../features/slices/api-slices";
import { useToast } from "../../components/shared/ToastContext";

const schema = z.object({
    email: z.string().email("Invalid email address").nonempty("Email is required"),
});

type FormData = z.infer<typeof schema>;

const ForgotPassword: React.FC = () => {

    const { register, handleSubmit, formState } = useForm<FormData>({
        resolver: zodResolver(schema),
        mode: "onBlur", // Validation mode onBlur
        defaultValues: {
            email: "",
        },
    });

    const { errors } = formState;
    const { showToast } = useToast();
    const [forgotPassword, { isLoading, isSuccess, isError, error }] = useForgotPasswordMutation();


    useEffect(() => {
        if (!isLoading && isSuccess) {
            showToast("Email sent successfully", "success", "Forgot Password");
        }
        if (!isLoading && isError) {
            showToast(`Error while sending email ${(error as any)?.data?.msg}`, "error", "Forgot Password");
        }
    }, [isLoading, isSuccess, isError, error]);


    const onSubmit = async (data: FormData) => {
        await forgotPassword(data);
    };


    return (
        <div className="flex justify-between items-center h-screen w-full">
            <div className="w-full flex justify-center items-center">
                <div className="flex flex-col mx-auto gap-4 md:w-[500px] w-full p-5 lg:p-10 md:shadow-lg rounded-[10px]">
                    <Link to="/" className="w-full flex justify-center items-center">
                        <img
                            src={'/logo.gif'}
                            alt="logo"
                            className="w-[300px] h-[100px] object-cover"
                            width={150}
                            height={150}
                        />
                    </Link>
                    <h1 className="text-xl md:text-2xl font-bold tracking-wide capitalize my-2">Forgot Password</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-5">
                        <label htmlFor="email">
                            Email
                            <input
                                {...register("email")}
                                id="email"
                                className="rounded p-2 mt-2 border border-gray-300 w-full"
                                type="email"
                            />
                        </label>
                        {errors.email && (
                            <span className="text-red-500 text-sm">{errors.email.message}</span>
                        )}

                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-[#FF5F00] text-white self-center rounded-full px-10 mt-5 py-3"
                        >
                            {
                                isLoading ? "Loading..." : "Forgot Password"
                            }
                        </button>
                        <div className="flex justify-center items-center gap-1">
                            <span className="text-gray-400">New User ?</span>
                            <Link to="/auth/signup" className="text-[#FF5F00]">
                                Sign up
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:inline-flex">
                <img
                    src="/auth.png"
                    alt="auth"
                    className="h-[100vh] w-[800px] object-cover"
                    width={400}
                    height={400}
                />
            </div>
        </div>
    );
};

export default ForgotPassword;
