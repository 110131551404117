export default function CouponRow() {
    return (
        <div className="flex gap-5 mb-4">
            <img
                src="https://placehold.co/500x200?text=Coupon"
                alt=""
                className="p-2 border shadow rounded-2xl bg-white"
            />
            <img
                src="https://placehold.co/500x200?text=Coupon"
                alt=""
                className="p-2 border shadow rounded-2xl bg-white"
            />
            <img
                src="https://placehold.co/500x200?text=Coupon"
                alt=""
                className="p-2 border shadow rounded-2xl bg-white"
            />
            <img
                src="https://placehold.co/500x200?text=Coupon"
                alt=""
                className="p-2 border shadow rounded-2xl bg-white"
            />
        </div>
    );
}
