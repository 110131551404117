
import ProductCard from '../../components/common/product-card'
import AnalyticsCard from '../../components/common/ui/Cards/analytics-card'

export default function VendorDashboard() {
  return (
    <>
        <div className='w-full grid 2xl:grid-cols-4 pt-8 xl:grid-cols-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-8'>
          <AnalyticsCard smallTitle="Total Revenue" title="$23,456" week="50% this week" icon="/rectangle-10-7@2x.png" />
          <AnalyticsCard smallTitle="Total Shops" title="45" week="16% this week" icon="/rectangle-10-4@2x.png" />
          <AnalyticsCard smallTitle="Vendor" title="34" week="30% this week" icon="/assets/images/svg/box3.svg" />
          <AnalyticsCard smallTitle="Total Orders" title="43" week="20% this week" icon="/assets/images/svg/box4.svg" />
        </div>

        <div className='flex 2xl:flex-row xl:flex-row pt-8 flex-col gap-8'>
          <div className='2xl:w-full xl:w-full w-full bg-white relative rounded-2xl p-8'>
            <div className='flex justify-between items-center'>
              <h2 className='text-2xl font-medium'>
                Sales Reports
              </h2>
              <div className='flex flex-row gap-x-3'>
                <button className='px-1 py-2 text-sm'>
                  Week
                </button>
                <button className='px-1 py-2 text-sm text-gray-600'>
                  Month
                </button>
                <button className='px-1 py-2 text-sm text-gray-600'>
                  Year
                </button>
              </div>
            </div>
            <div className='flex items-center gap-2 pt-3'>
              <div className='size-9 bg-yellow-300 rounded-full flex justify-center items-center'>
                <img loading="lazy" src="/assets/images/svg/arrow-up.svg" alt="" />
              </div>
              <span className='text-gray-500 font-medium'>45.6%</span>
            </div>
            <img loading="lazy" src="/assets/images/trading.png" alt="" className='mt-6' />
            <div className='size-10 bg-yellow-300 absolute right-10 cursor-pointer rounded-lg flex justify-center items-center bottom-8'>
              <img loading="lazy" src="/assets/images/svg/arrow-right.svg" alt="" />
            </div>
          </div>
          <div className='2xl:w-1/3 xl:w-1/3 w-full bg-white rounded-2xl p-4'>
            <h1 className='text-2xl font-bold mt-4 text-center'>
              Customer
            </h1>
            <div className='flex flex-col items-center pt-8 gap-2'>
              <img loading="lazy" src="/assets/images/circle-trading.png" alt="" className='w-44 rounded-full' />
            </div>
            <ul className='space-y-2 mt-5 px-5'>
              <li className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <div className='w-8 h-2 bg-yellow-300 rounded-full'></div>
                  <span className='text-sm text-gray-500'>Current Customer</span>
                </div>
                <span className='text-base text-gray-900'>66%</span>
              </li>
              <li className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <div className='w-8 h-2 bg-green-500 rounded-full'></div>
                  <span className='text-sm text-gray-500'>New Customer</span>
                </div>
                <span className='text-base text-gray-900'>25%</span>
              </li>
              <li className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <div className='w-8 h-2 bg-red-500 rounded-full'></div>
                  <span className='text-sm text-gray-500'>Retargeted Customer</span>
                </div>
                <span className='text-base text-gray-900'>48%</span>
              </li>
            </ul>
          </div>
        </div>

        <div className='w-full bg-white rounded-2xl p-10 mt-8'>
          <div className='flex justify-between items-center'>
            <h2 className='text-2xl font-medium'>
              Order Status
            </h2>
            <div className='flex flex-row gap-x-3'>
              <button className='px-1 py-2 text-sm'>
                Today
              </button>
              <button className='px-1 py-2 text-sm text-gray-600'>
                Weekly
              </button>
              <button className='px-1 py-2 text-sm text-gray-600'>
                Monthly
              </button>
              <button className='px-1 py-2 text-sm text-gray-600'>
                Yearly
              </button>
            </div>
          </div>
          <div className='w-full grid 2xl:grid-cols-4 pt-8 xl:grid-cols-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-8'>
            <div className='w-full h-fit bg-white rounded-xl border border-solid border-gray-300 p-5'>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-1.5'>
                  <h6 className='text-sm text-gray-500'>
                    Pending Order
                  </h6>
                  <h3 className='text-2xl font-bold'>
                    4
                  </h3>
                </div>
                <div className='size-12 bg-yellow-300 flex justify-center items-center rounded-lg'>
                  <img loading="lazy" src="/assets/images/svg/box5.svg" className='size-7' alt={``} />
                </div>
              </div>
            </div>
            <div className='w-full h-fit bg-white rounded-xl border border-solid border-gray-300 p-5'>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-1.5'>
                  <h6 className='text-sm text-gray-500'>
                    Processing Order
                  </h6>
                  <h3 className='text-2xl font-bold'>
                    3
                  </h3>
                </div>
                <div className='size-12 bg-yellow-300 flex justify-center items-center rounded-lg'>
                  <img loading="lazy" src="/assets/images/svg/box7.svg" className='size-7' alt={``} />
                </div>
              </div>
            </div>
            <div className='w-full h-fit bg-white rounded-xl border border-solid border-gray-300 p-5'>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-1.5'>
                  <h6 className='text-sm text-gray-500'>
                    Completed Order
                  </h6>
                  <h3 className='text-2xl font-bold'>
                    8
                  </h3>
                </div>
                <div className='size-12 bg-yellow-300 flex justify-center items-center rounded-lg'>
                  <img loading="lazy" src="/assets/images/svg/box6.svg" className='size-7' alt={``} />
                </div>
              </div>
            </div>
            <div className='w-full h-fit bg-white rounded-xl border border-solid border-gray-300 p-5'>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-1.5'>
                  <h6 className='text-sm text-gray-500'>
                    Cancelled Order
                  </h6>
                  <h3 className='text-2xl font-bold'>
                    2
                  </h3>
                </div>
                <div className='size-12 bg-yellow-300 flex justify-center items-center rounded-lg'>
                  <img loading="lazy" src="/assets/images/svg/box8.svg" className='size-7' alt={``} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full h-fit bg-white rounded-2xl p-8 mt-8'>
          <div className="flex justify-between items-center">
            <h2 className="text-black text-[22px] font-medium leading-snug">Recent Orders</h2>
            <span className='border-b border-dashed border-gray-400 text-gray-400 cursor-pointer'>View All orders</span>
          </div>
          <div className="relative overflow-x-auto mt-8 sm:rounded-lg border border-solid border-gray-100">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 captalize bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Issued By
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr><tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    #uy37E
                  </td>
                  <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                    John Doe
                  </td>
                  <td className="px-6 py-4">
                    Ash Double Bed
                  </td>
                  <td className="px-6 py-4">
                    Shop
                  </td>
                  <td className="px-6 py-4">
                    Furniture Shop
                  </td>
                  <td className="px-6 text-red-500 py-4">
                    <div className="flex items-center gap-3">
                      <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <h3 className='mt-8 text-3xl font-medium'>
          Most Rated Products
        </h3>
        <div className="w-full overflow-x-auto mt-8">
          <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-5 mb-4">
            <ProductCard
              productName={"CASHMERE-01 HEEL"}
              amount={200}
              imageUrl={
                "https://placehold.co/200x150?text=Placeholder"
              }
              ratings={4.3}
            />
            <ProductCard
              productName={"6 Round Leather Coasters Set"}
              amount={120}
              imageUrl={
                "https://placehold.co/200x150?text=Placeholder"
              }
              ratings={4.8}
            />
            <ProductCard
              productName={"Embellished Raffia Loafers"}
              amount={300}
              imageUrl={
                "https://placehold.co/200x150?text=Placeholder"
              }
              ratings={4}
            />
            <ProductCard
              productName={"German Chocolate Cake"}
              amount={210}
              imageUrl={
                "https://placehold.co/200x150?text=Placeholder"
              }
              ratings={5}
            />
          </div>
        </div>
    </>
  )
}
