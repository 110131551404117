
export default function Inventory() {
    return (
        <>
            <div className="w-full bg-white p-8 pt-7 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium leading-snug">Inventory</h2>

                </div>
                <div className='flex justify-center items-center min-h-screen'>
                    <p>No Product Added!</p>
                </div>
                {/* <InventoryTable /> */}
            </div>
        </>
    )
}
