import React from 'react'

export default function EDiscountDetailsModal({ isOpen, onClose, children }: any) {
    if (!isOpen) return null;
    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                    <div className="p-4 flex justify-end">
                        <button
                            className="text-gray-500 outline-none border-none bg-transparent text-xl hover:text-gray-700 focus:outline-none"
                            onClick={onClose}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="p-4">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}
