import CartTable from '../../components/common/ui/cart-table'

export default function MyCartPage() {
  return (
        <div className='w-full bg-white p-8 lg:rounded-3xl h-fit'>
            <h3 className="text-black text-[22px] font-medium leading-snug">My Cart</h3>
            <CartTable />
        </div>
  )
}
