import React from 'react'
import MainHeader from '../../../components/common/header/main-header'
import Footer from '../../../components/common/footer/footer'

export default function UnlockExclusivePage() {
    return (
        <>
            <MainHeader />

            <main className='w-full relative'>
                <img src="/assets/images/unlock-ex.png" alt="" />
                <div className='absolute top-0 left-0 right-0 2xl:pt-32 xl:pt-28 pt-3'>
                    <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-10 px-5'>
                        <h1 className='2xl:text-4xl text-black xl:text-4xl text-sm font-bold 2xl:w-[582px] xl:w-[582px] 2xl:leading-[55px] xl:leading-[55px] w-full'>
                            Unlock exclusive rewards and earn big with our unique system.
                        </h1>
                        <button type='button' className='w-fit 2xl:text-sm xl:text-sm md:text-sm lg:text-sm text-xs px-6 2xl:mt-6 xl:mt-6 md:mt-6 lg:mt-6 mt-2 py-2 bg-black text-white rounded-full'>
                            Join the Magic Club Today
                        </button>
                    </div>
                </div>
            </main>

            {/* ========= section one code start here ======== */}
            <section className='max-w-screen-2xl mx-auto pt-10'>
                <h3 className='text-2xl text-center font-medium'>
                    What is the Magic Club?
                </h3>
                <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-2 px-3'>
                    <p className='text-center mt-2'>
                        The Magic Club is an exclusive membership program designed to reward our most loyal customers. As a member of the Magic Club, you gain access to a world of special perks, unique benefits, and the opportunity to earn significant rewards just by shopping with us.
                    </p>
                </div>
                {/* ========= section one code end here ======== */}
            </section>
            {/* ========= section two code start here ======== */}
            <section className='max-w-screen-xl mx-auto pt-10 2xl:px0 xl:px-0 px-8'>
                <h3 className='text-2xl text-center font-medium'>
                    How It Works?
                </h3>
                <div className='grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 mt-8 gap-4 gap-y-8'>
                    <div className='w-full relative'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step1
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/club-icon.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Join the Club
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            Simply sign up on our website or during checkout to start enjoying the benefits right away. Membership is free, and there’s no minimum purchase required to join.
                        </p>
                    </div>
                    <div className='w-full relative'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step2
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/earn.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Earn Points
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            The more you shop, the more points you accumulate. You can also earn extra points by participating in special promotions, completing surveys, or referring friends to join the Magic Club.
                        </p>
                    </div>
                    <div className='w-full relative'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step3
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/reddem.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Redeem Your Rewards
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            Once you've earned enough points, you can redeem them for amazing rewards like discounts on future purchases, free products, or even limited-edition items.
                        </p>
                    </div>
                    <div className='w-full relative'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step4
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/enjoy.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Enjoy VIP Privileges
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            As a Magic Club member, you’ll receive exclusive perks like early access to sales, special members-only discounts, and priority customer service.
                        </p>
                    </div>
                </div>
                <h3 className='2xl:text-2xl xl:text-2xl md:text-2xl lg:text-2xl text-xl mt-16 text-center font-medium'>
                    How Do I Get Magic Club Points?
                </h3>
                <div className="w-full p-8 bg-[#ffefd2] rounded-xl mt-8 2xl:mb-80 xl:mb-80 mb:mb-44 lg:mb-44 mb-32">
                    <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-6 justify-between w-full'>
                        <div className='w-full flex flex-col'>
                            <h2 className='text-xl'>
                                Points Earned
                            </h2>
                            <ul className='flex flex-col 2x:gap-y-7 xl:gap-y-7 md:gap-y-7 lg:gap-y-7 gap-y-4 mt-5'>
                                <li>100 Points</li>
                                <li>250 Points</li>
                                <li>500 Points</li>
                                <li>750 Points</li>
                                <li>1000 Points</li>
                                <li>1500 Points</li>
                                <li>2000 Points</li>
                            </ul>
                        </div>
                        <div className='w-full flex flex-col'>
                            <h2 className='text-xl'>
                                Rewards
                            </h2>
                            <ul className='flex flex-col 2x:gap-y-7 xl:gap-y-7 md:gap-y-7 lg:gap-y-7 gap-y-4 mt-5'>
                                <li>$10 Off Coupon</li>
                                <li>Free Shipping on Any Order</li>
                                <li>Exclusive Member-Only Product</li>
                                <li>$50 Off on Orders Over $200</li>
                                <li>VIP Event Access</li>
                                <li>Free Product of Your Choice</li>
                                <li>$100 Gift Card</li>
                            </ul>
                        </div>
                        <div className='w-full flex flex-col'>
                            <h2 className='text-xl'>
                                Description
                            </h2>
                            <ul className='flex flex-col gap-y-4 text-[13px] mt-5'>
                                <li>
                                    Redeem 100 points for an instant $10 discount on your next purchase. Perfect for small essentials or gifts!
                                </li>
                                <li>
                                    Enjoy free shipping on any order, no matter the size or destination. Save on delivery costs with this reward.
                                </li>
                                <li>
                                Unlock access to a special product only available to Magic Club members. Choose from a selection of exclusive items.
                                </li>
                                <li>
                                Planning a big purchase? Use 750 points to get $50 off when you spend $200 or more. Perfect for those big-ticket items!
                                </li>
                                <li>
                                Receive an invitation to an exclusive VIP event, where you can shop new collections before anyone else and enjoy additional perks.
                                </li>
                                <li>
                                Redeem your points for a free product from our curated selection, including top brands and new arrivals.
                                </li>
                                <li>
                                Treat yourself or someone special with a $100 gift card to use on anything in our store. The ultimate reward for loyal customers!
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}
