import LowOutOfStockTable from '../../components/common/ui/low-out-of-stock-table'

export default function LowOutOfSock() {
  return (
    <>
        <div className='w-full bg-white p-5 rounded-xl'>
            <LowOutOfStockTable />
        </div>
    </>
  )
}
