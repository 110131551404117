import { useSelector } from "react-redux";
import { formatCurrency, getProductTax, getSubtotal } from "../../../utils/utils";
import { AppState } from "../../../redux/store";
import { ProductToStore } from "../../../interfaces/interface";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useCallback } from "react";

const Order = () => {
    const { currency } = useSelector((state: AppState) => state.auth);
    const { shippingCharges, discount } = useSelector((state: AppState) => state.product);
    const [cart] = useLocalStorage("my_cart", []);
    const subtotal = useCallback(() => getSubtotal(cart), [cart])();
    const taxPrice = getProductTax(Number(subtotal)).toFixed(2);
    const total = Number(subtotal) + Number(taxPrice) - Number(discount);

    return (
        <div className="flex flex-col gap-6 w-full p-6 rounded-[10px] border-gray-100 border-[1px] shadow-sm font-normal">
            <h1 className="text-xl font-bold">Your Order</h1>
            {cart && cart.length > 0 && cart.map((item: ProductToStore) => (
                <div key={item.id} className="flex flex-col gap-3 text-base">
                    <div className="flex justify-between items-center">
                        <p className="w-[150px]">
                            {item.title} x {item.quantity}
                        </p>
                        <span>{formatCurrency(item.quantity * item.price, currency)}</span>
                    </div>
                </div>
            ))}
            <div className="flex flex-col gap-3 text-base mt-7">
                <hr />
                <div className="flex justify-between items-center font-semibold opacity-90">
                    <p>Sub Total</p>
                    <span>{formatCurrency(Number(subtotal), currency)}</span>
                </div>
                <hr />
                <div className="flex justify-between items-center font-semibold opacity-90">
                    <p>Tax</p>
                    <span>{formatCurrency(Number(taxPrice), currency)}</span>
                </div>
                <hr />
                <div className="flex justify-between items-center font-semibold opacity-90">
                    <p>Shipping</p>
                    {shippingCharges === 0 ? (
                        <span className="text-red-500">Free</span>
                    ) : (
                        <span>{formatCurrency(shippingCharges, currency)}</span>
                    )}
                </div>
                <hr />
                <div className="flex justify-between items-center font-semibold opacity-90">
                    <p>Total</p>
                    <span>{formatCurrency(total, currency)}</span>
                </div>
            </div>
        </div>
    );
};

export default Order;
