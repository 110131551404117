import {
    faArrowRight,
    faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LineChartComponent from "./LineChartComponent";

export default function Graph() {
    return (
        <div className="bg-white rounded-3xl col-span-1 row-span-1 md:col-span-3 md:row-span-2 p-5 min-h-[40vh]">
            <div className="grid grid-cols-1 md:grid-cols-3 h-full">
                <div className="col-span-1 md:col-span-2">
                    <div className="flex flex-col gap-2 h-full">
                        <div className="flex md:flex-col md:gap-2">
                            <h2 className="text-2xl md:text-3xl font-bold">
                                $56,540
                            </h2>
                            <Link to="" className="flex gap-2 my-auto ml-auto md:ml-0">
                                <div className="bg-primary rounded-full w-7 h-7 flex hover:scale-110 duration-300">
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        className="m-auto"
                                    />
                                </div>

                                <p className="my-auto font-medium text-neutral-500">
                                    344
                                </p>
                            </Link>
                        </div>
                        <div className="rounded-3xl h-full mt-4">
                            <LineChartComponent />
                        </div>
                    </div>
                </div>
                <div className="col-span-1 p-5 justify-between flex md:flex-col">
                    <h2 className="text-3xl md:text-4xl font-bold">
                        Total
                        <br />
                        Wallet
                    </h2>
                    <Link to="" className="flex gap-2 my-auto md:my-0">
                        <div className="bg-primary rounded w-8 h-8 flex hover:scale-110 duration-300">
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="m-auto"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
