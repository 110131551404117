import { Link } from "react-router-dom";

export default function MyOrdersTable({ data, handleDeleteOrder, handleEditOrder, isLoading }: any) {
    return (
        <>
            {isLoading ? (
               <div className="flex justify-center items-center min-h-screen">
                    <img loading="lazy" src="/assets/images/gif/spinner.svg" className="size-28" alt="" />
               </div>
            ) : (
                <div className="relative overflow-x-auto mt-8 sm:rounded-lg border border-solid border-gray-100">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 captalize bg-gray-50">
                            <tr>
                                <th className="px-6 py-3">
                                    ID
                                </th>
                                <th className="px-6 py-3">
                                    Customer
                                </th>
                                <th className="px-6 py-3">
                                    Product
                                </th>
                                <th className="px-6 py-3">
                                    Order Date
                                </th>
                                <th className="px-6 py-3">
                                    Quantity
                                </th>
                                <th className="px-6 py-3">
                                    Total Amount
                                </th>
                                <th className="px-6 py-3">
                                    Total Earning
                                </th>
                                <th className="px-6 py-3">
                                    Status
                                </th>
                                <th className="px-6 py-3">
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {data?.orders?.map((items: any) => (
                                <tr className="bg-white border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                        {items._id}
                                    </td>
                                    <td className="px-6 py-4 text-gray-900 whitespace-nowrap">
                                        {items.name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {items.customer.first_name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {items.created_at}
                                    </td>
                                    <td className="px-6 py-4">
                                        ${items.total_discounts}
                                    </td>
                                    <td className="px-6 py-4">
                                        ${items.current_total_price}
                                    </td>
                                    <td className="px-6 py-4">
                                        {items.line_items.length}
                                    </td>
                                    <td className="px-6 text-amber-300 py-4">
                                        {items.fulfillment_status}
                                    </td>

                                    <td className="px-6 text-xinc-800 py-4">
                                        <div className="flex items-center gap-3">
                                            <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Eye" />
                                            <Link to={`/user-panel/orders-details`} className="text-sm font-medium hover:underline">View Details</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

        </>
    )
}
