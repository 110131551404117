import { BiSolidCopy } from 'react-icons/bi'
// import { FaArrowRight } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { useRedeemCouponMutation } from '../../features/slices/api-slices';
import { useEffect } from 'react';
import { useToast } from '../../components/shared/ToastContext';
export default function EDiscountDetailsPage() {
    const location = useLocation();

    const state = location.state as any;
    const { showToast } = useToast();
    const [redeem, { isLoading, data, error }] = useRedeemCouponMutation();

    useEffect(() => {
        if (!isLoading && data) {
            showToast("Your coupon has been redeem successfully", "success", "Coupon Redeem successfully")
        } else if (!isLoading && error) {
           showToast("Your coupon has not been redeem", "error", "Coupon Redeem Failed. Please try again")
        }
    }, [isLoading, data, error]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(state?.coupon?.code)
        showToast("Your coupon has been copied successfully", "success", "Coupon Copied")
    }
    return (
        <>
            <div className='w-full lg:rounded-3xl p-7'>
                <h2 className='text-xl font-medium'>
                    E-Discount Coupon Details
                </h2>
                <div className='flex items-center gap-2 pt-5'>
                    <p className='text-base font-medium'>
                        Coupon Code:
                        <span className={`${state?.coupon?.redeemed ? 'line-through' : ''}`}>{state?.coupon?.code}</span>
                    </p>
                    <span className='text-gray-600'></span>
                    <BiSolidCopy className="text-black cursor-pointer" onClick={handleCopyToClipboard} />
                </div>
                <div className='flex items-center pt-1'>
                    <span className='text-base font-medium'>Discount: &nbsp;</span>
                    <span className='text-gray-600'>

                        {state?.coupon?.discountType !== "free" && state?.coupon?.discount}
                        {state?.coupon?.discountType === 'percentage' ? '%' : state?.coupon?.discountType === "free" ? "FREE" : '$'}
                        {state?.coupon?.discountType !== "free" && <span className="ml-1">Off</span>}
                    </span>
                </div>
                <div className='flex items-center pt-1'>
                    <span className='text-base font-medium'>Status: &nbsp;</span>
                    <span className='text-gray-600'>
                        {state?.coupon?.redeemed ? 'Redeemed' : 'Active'}
                    </span>
                </div>

                <div className='flex items-center pt-1'>
                    <span className='text-base font-medium'>Validity: &nbsp;</span>
                    <span className='text-gray-600'>
                        {moment(state?.coupon?.expiryDate).format('DD-MM-YYYY HH:mm')}
                    </span>
                </div>
                <div className='flex items-center my-5'>
                    <button className='bg-yellow-300 text-zinc-800 px-4 py-2 rounded-2xl' onClick={() => redeem({ code: state?.coupon?.code })}>
                        {isLoading ? 'Redeeming...' : 'Redeem'}
                    </button>
                </div>
            </div>
            <div className='grid 2xl:grid-cols-2 mt-8 xl:grid-cols-2 grid-cols-1 gap-10'>
                <div className='w-full bg-white rounded-2xl p-8'>
                    <h2 className='text-lg font-medium'>
                        Terms & Condition
                    </h2>
                    <p className='mt-3'>
                        {state?.ad?.terms}
                    </p>
                </div>
                <div className='w-full bg-white rounded-2xl p-8'>
                    <h2 className='text-lg font-medium'>
                        Usage Instructions
                    </h2>
                    <p className='mt-3'>
                        Applicable on all products
                    </p>

                    <p className='mt-3'>
                        Cannot be combined with other offers
                    </p>
                </div>
                <div className='w-full bg-white rounded-2xl p-8'>
                    <h2 className='text-lg font-medium'>
                        Store Detail
                    </h2>
                    <p className='mt-3'>
                        <b>Store Name:</b> {state?.ad?.businessId?.businessName}
                    </p>

                    <p className='mt-3'>
                        <b> Address:</b>
                        <br />
                        {state?.ad?.businessId?.address1}
                        <br />
                        {state?.ad?.businessId?.address2 && state?.ad?.businessId?.address2}
                        <br />
                        {state?.ad?.businessId?.city + ', ' + state?.ad?.businessId?.state + ', ' + state?.ad?.businessId?.zip}
                    </p>
                </div>
                <div className='w-full bg-white rounded-2xl p-8'>
                    <h2 className='text-lg font-medium'>
                        Contact Information
                    </h2>
                    <p className='mt-3'>
                        Email: {state?.ad?.businessId?.email}
                    </p>

                    <p className='mt-3'>
                        Phone: {state?.ad?.businessId?.phone}
                    </p>
                </div>
            </div>

            <div className='w-full p-8 mt-10 lg:rounded-3xl'>
                <h2 className='text-lg font-medium'>
                    Share this coupon
                </h2>
                <div className='flex items-center gap-6 mt-8'>
                    <img loading="lazy" src="/assets/images/svg/instagram.svg" alt="" className='size-8' />
                    <img loading="lazy" src="/assets/images/svg/twitter.svg" alt="" className='size-8' />
                    <img loading="lazy" src="/assets/images/svg/facebook.svg" alt="" className='size-8' />
                    <div className='flex items-center gap-2 cursor-pointer select-none'>
                        <img loading="lazy" src="/assets/images/svg/copy-link.svg" alt="" />
                        <span>Copy Link</span>
                    </div>
                </div>
            </div>

            {/* <div className='w-full bg-white p-8 mt-10 rounded-3xl'>
                <h2 className='text-lg font-medium'>
                    Related Coupons
                </h2>

                <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-8 mt-4'>
                    <Link to={`/e-discount-details`}>
                        <div className="w-full h-fit bg-red-400 rounded-2xl p-5">
                            <div className='flex justify-between items-center'>
                                <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                                <span className="text-white text-base font-normal">Status: Active</span>
                            </div>
                            <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                            <div className='flex justify-between items-center pt-2'>
                                <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                                <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                    <FaArrowRight />
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="w-full h-fit bg-[#62B3C6] rounded-2xl p-5">
                        <div className='flex justify-between items-center'>
                            <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                            <span className="text-white text-base font-normal">Status: Active</span>
                        </div>
                        <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                        <div className='flex justify-between items-center pt-2'>
                            <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                            <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                <FaArrowRight />
                            </span>
                        </div>
                    </div>
                    <div className="w-full h-fit bg-amber-500 rounded-xl p-5">
                        <div className='flex justify-between items-center'>
                            <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                            <span className="text-white text-base font-normal">Status: Active</span>
                        </div>
                        <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                        <div className='flex justify-between items-center pt-2'>
                            <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                            <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                <FaArrowRight />
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
