import QuestionBox from '../../components/shared/Box/question-box'
import Divider from '../../components/common/ui/divider'


export default function MyQuestionPage() {
    return (
        <>
            <div className='w-full bg-white min-h-screen p-8 lg:rounded-3xl'>
                <h3 className="text-black text-[22px] font-medium leading-snug">My Questions</h3>
                <div className='flex flex-wrap gap-3 justify-between pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt='Default' />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Razero Wolverine</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
                <Divider className="mt-6" />
                 <div className='flex flex-wrap gap-3 justify-between pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt="DefaultPicture" />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Chocolate Chip Cookie</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
                <Divider className="mt-6" />
                 <div className='flex flex-wrap gap-3 justify-between pt-6 items-center w-full'>
                    <div className='flex items-center gap-6'>
                        <img className="w-[60px] h-[60px] rounded-[7px] border" src="https://via.placeholder.com/60x60" alt="DefaultPicture" />
                        <div className='flex flex-col'>
                            <span className='text-zinc-800 text-lg font-normal'>Chocolate Chip Cookie</span>
                            <small className=' text-neutral-500 text-lg font-normal '>$120</small>
                        </div>
                    </div>
                    <span className='text-right text-neutral-500 text-sm font-normal leading-snug'>Date: 5 Jun, 2024</span>
                </div>
                <QuestionBox />
            </div>
        </>
    )
}
