import React, { useEffect, useState } from "react";
import moment from "moment";
import OnlineCouponsSection from "../../components/common/dashboard/OnlineCouponsSection";
import { BiSolidCopy } from "react-icons/bi";
import EDiscountDetailsModal from "../../components/common/ui/modal/e-discount-modal";
import { useRedeemCouponMutation } from "../../features/slices/api-slices";
import { useToast } from "../../components/shared/ToastContext";
import { useLocation } from "react-router-dom";
import useDeviceDetect from "../../hooks/device-detect";
import DashboardBanner from "../../components/common/ui/banners/dashboard-banner";
import { getCouponAddress } from "../../utils/utils";

export default function AllCouponsPage() {
  const ebookData = useLocation().state?.data;

  // do not fetch data if user is not logged in
  console.log(ebookData);

  return (
    <div className='w-full bg-yellow-400 lg:rounded-3xl '>
      <div className='w-full bg-white p-8 min-h-screen'>
        <DashboardBanner title="Shop Smart with Our Best Coupon Deals!" image="/assets/images/shop-smart.png" color="white" />
        <EbookCoupons ebook={ebookData?.ebook} ads={ebookData?.ads} />
      </div>
    </div>
  )
}

export const EbookCoupons = ({
  ebook,
  ads,
}: {
  ebook: any;
  ads: any;
}) => {
  const [selectedType, setSelectedType] = useState('') // In-Store, Online
  const device = useDeviceDetect();
  const adSteps = device === "mobile" ? 3 : 6;
  // select random color for each coupon 

  const bannersPaths = ["/assets/images/banner1.png", "/assets/images/banner2.png"]

  return (
    <div className='w-full my-10'>
      <div className='flex 2xl:gap-10 xl:gap-10 md:gap-10 lg:gap-10 gap-3 my-5 border-b border-solid border-yellow-500 2xl:pl-6 xl:pl-6 md:pl-6 lg:pl-6 pl-0'>
        <button onClick={() => setSelectedType('')} className={`${selectedType === '' ? 'bg-custom-yellow' : 'bg-[#f4f4f4] border border-solid border-gray-300 border-b-0'} text-black 2xl:px-7 xl:px-7 md:px-7 lg:px-7 px-3 py-3 2xl:text-base xl:text-base md:text-base lg:text-base text-xs rounded-t-xl`}>All</button>
        <button onClick={() => setSelectedType('In-Store')} className={`${selectedType === 'In-Store' ? 'bg-custom-yellow' : 'bg-[#f4f4f4] border border-solid border-gray-300 border-b-0'} text-black 2xl:px-7 xl:px-7 md:px-7 lg:px-7 px-3 2xl:text-base xl:text-base md:text-base lg:text-base text-xs py-2 rounded-t-xl`}>In-Store</button>
        <button onClick={() => setSelectedType('Online')} className={`${selectedType === 'Online' ? 'bg-custom-yellow' : 'bg-[#f4f4f4] border border-solid border-gray-300 border-b-0'} text-black 2xl:px-7 xl:px-7 md:px-7 lg:px-7 px-3 2xl:text-base xl:text-base md:text-base lg:text-base text-xs py-2 rounded-t-xl`}>Online</button>
      </div>
      <div className=''>
        {
          selectedType === 'In-Store' ?
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-4"> {
              ads?.map((ad: any, i: number) => (
                <React.Fragment key={i}>
                  <CouponCard key={ad.ad._id} coupon={ad.coupons[0]} ad={ad.ad} ebook={ebook} />
                  {(i + 1) % adSteps === 0 && i !== ads?.length - 1 && (
                    <div className="w-full col-span-1 lg:col-span-3 py-4">
                      <img src={
                        // show 1st banner then after next 2 rows show 2nd banner
                        bannersPaths[(Math.floor(i / 6) % bannersPaths.length)]
                      } alt="Ad" className="w-full" />
                    </div>
                  )}

                </React.Fragment>
              ))
            }
            </div>
            :
            selectedType === 'Online' ?
              <OnlineCouponsSection />
              :
              <>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-3 mt-4"> {
                  ads?.map((ad: any, i: number) => (
                    <React.Fragment key={i}>
                      <CouponCard key={ad.ad._id} coupon={ad.coupons[0]} ad={ad.ad} ebook={ebook} />
                      {(i + 1) % adSteps === 0 && i !== ads?.length - 1 && (
                        <div className="w-full col-span-1 lg:col-span-3 py-4">
                          <img src={
                            bannersPaths[(Math.floor(i / 6) % bannersPaths.length)]
                          } alt="Ad" className="w-full" />
                        </div>
                      )}

                    </React.Fragment>
                  ))
                }
                </div>
                <div className="w-full col-span-3 py-4">
                  <img src={
                    bannersPaths[1]
                  } alt="Ad" className="w-full" />
                </div>
                <OnlineCouponsSection />
              </>
        }
      </div>
    </div>
  )
}

export const CouponCard = ({
  coupon,
  ad,
  ebook,
  bg
}: {
  coupon: any;
  ad: any;
  ebook: any;
  bg?: string;
}) => {
  const [redeem, { isLoading, data, error }] = useRedeemCouponMutation();
  const { showToast } = useToast();

  console.log(data);

  // const handleNavigate = () => {
  //     navigate(`/user-panel/e-discount-&-directory-details`, { state: { coupon, ad, ebook } })
  // }
  const handleCopyToClipboard = () => {
    if (!coupon?.code) {
      return;
    }
    if (coupon?.redeemed) {
      return;
    }

    navigator.clipboard.writeText(coupon?.code)
    showToast('Coupon code copied to clipboard', "success", "Coupon Copied")
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    if (coupon?.redeemed) {
      return;
    }
    setIsModalOpen(true)
  };
  const closeModal = () => setIsModalOpen(false);


  useEffect(() => {
    if (!isLoading && data) {
      showToast("Coupon redeemed is loading successfully", "success", "Coupon redeemed");
    } else if (!isLoading && error) {
      showToast("Failed to load! redeem coupn", "error", "Failed to load")
    }
  }, [isLoading, data, error]);

  return (
    <>
      <div>
        <div className="w-full h-fit rounded-lg p-5 mb-4 overflow-hidden" style={{
          background: '#4ec987'
        }}>
          <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-3 justify-between pb-3 items-start w-full relative'>
            <span className={`text-white text-sm font-normal flex items-center gap-2 ${coupon?.redeemed ? 'line-through' : ""}`}>Code:  {coupon?.code} <BiSolidCopy className="text-white cursor-pointer" onClick={handleCopyToClipboard} /></span>
            <span className="text-white 2xl:text-xs xl:text-xs md:text-xs lg:text-xs text-xs font-normal">Expire on: {moment(ebook?.expiryDate).format('DD MMM YYYY')}</span>
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="w-[211px] text-zinc-800 2xl:text-2xl xl:text-2xl md:text-2xl lg:text-2xl text-lg font-medium relative">
              {ad?.productTitle}
            </div>
            {coupon?.redeemed &&
              <div className=" text-zinc-800 text-2xl font-medium relative">
                <img src="/assets/images/reedem-image.png" alt="redeemed" className="w-24 h-auto" />
              </div>
            }
          </div>
          {<div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between w-full 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-3 pt-4'>
            <div className=" text-sm font-normal">
              <span className="font-bold">{ad?.businessId?.businessName}</span>
              <span className="block text-white">{getCouponAddress(ad?.businessId)}</span>
            </div>
            {!coupon?.redeemed && <div onClick={openModal} className="text-white px-4 py-2 bg-black rounded-full text-sm font-normal flex items-center gap-2 cursor-pointer min-w-max">
              <span>Redeem Here</span>
            </div>}
          </div>}
        </div>
      </div>


      <EDiscountDetailsModal isOpen={isModalOpen} onClose={closeModal} ad={ad}>
        <h2 className="text-xl font-medium">E-Discount Coupon Details </h2>
        <div className='flex items-center gap-2 pt-5'>
          <p className='text-base font-medium'>
            Coupon Code:
            <span className={`${coupon?.redeemed ? 'line-through' : ''}`}>{coupon?.code}</span>
          </p>
          <span className='text-gray-600'></span>
          <BiSolidCopy className="text-black cursor-pointer" onClick={handleCopyToClipboard} />
        </div>

        <div className='flex items-center pt-1'>
          <span className='text-base font-medium'>Discount: &nbsp;</span>
          <span className='text-gray-600'>

            {coupon?.discountType !== "free" && coupon?.discount}
            {coupon?.discountType === 'percentage' ? '%' : coupon?.discountType === "free" ? "FREE" : '$'}
            {coupon?.discountType !== "free" && <span className="ml-1">Off</span>}
          </span>
        </div>

        <div className='flex items-center pt-1'>
          <span className='text-base font-medium'>Status: &nbsp;</span>
          <span className='text-gray-600'>
            {coupon?.redeemed ? 'Redeemed' : 'Active'}
          </span>
        </div>

        <div className='flex items-center pt-1'>
          <span className='text-base font-medium'>Validity: &nbsp;</span>
          <span className='text-gray-600'>
            {moment(coupon?.expiryDate).format('DD-MM-YYYY HH:mm')}
          </span>
        </div>




        <div className='w-full bg-white rounded-2xl pt-4'>
          <h2 className='text-lg font-medium'>
            Store Detail
          </h2>
          <p className='mt-3'>
            <b>Store Name:</b> {ad?.businessId?.businessName}
          </p>

          <p className='mt-3'>
            <b> Address:</b>
            <br />
            {ad?.businessId?.address1}
            <br />
            {ad?.businessId?.address2 && ad?.businessId?.address2}
            <br />
            {ad?.businessId?.city + ', ' + ad?.businessId?.state + ', ' + ad?.businessId?.zipcode}
          </p>
        </div>
        <div className='flex justify-center items-center my-5 mt-8'>
          <button className='bg-yellow-300 text-zinc-800 px-4 py-2 rounded-2xl' onClick={() => redeem({ code: coupon?.code })}>
            {isLoading ? 'Redeeming...' : 'Redeem'}
          </button>
        </div>
      </EDiscountDetailsModal>
      {/* <div className="w-full h-fit  bg-amber-400 rounded-xl p-5">

                <div className="flex flex-col justify-between">
                    <div>
                        <div className="flex items-center">
                            <span className="discount-percentage text-2xl font-bold ">
                                {coupon?.discountType !== "free" && coupon?.discount}
                                {coupon?.discountType === 'percentage' ? '%' : coupon?.discountType === "free" ? "FREE" : '$'}
                            </span>
                            {coupon?.discountType !== "free" && <span className="block text-xl  ml-3">Off</span>}
                        </div>
                        <img loading="lazy" src={ad?.image} alt="logo" className="w-24 h-auto rounded-md" width={350} height={100} />
                        <div className="review flex flex-col my-2">
                            <span></span>
                            <span className="review-score font-bold text-red-600">{ad?.businessId?.businessName}</span>
                            <span className="review-score ">{getCouponAddress(ad?.businessId)}</span>
                            <div className="social-icons flex space-x-2 mt-1 text-gray-600">
                                <i className="fab fa-facebook"></i>
                                <i className="fab fa-instagram"></i>
                                <i className="fab fa-twitter"></i>
                                <i className="fab fa-linkedin"></i>
                                <i className="fas fa-share"></i>
                            </div>
                        </div>
                        <div className=" text-zinc-800 font-medium mt-3">
                            <div className="coupon-body">
                                <span className="block text-gray-600 my-2">Terms: {ad?.terms}</span>
                                <div className="coupon-code-section flex flex-col md:flex-row items-center justify-between mt-4">
                                    <span className={`coupon-code flex-1 bg-gray-300 p-2 text-2xl text-center rounded-lg tracking-widest ${coupon?.redeemed && ' line-through'}`}>

                                    </span>
                                    <button
                                        onClick={() => {
                                            // redeem({ code: coupon?.code });
                                        }}
                                        disabled={coupon?.redeemed}
                                        className="get-coupon-btn my-3 text-sm md:ml-4 md:my-0 px-2 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 disabled:opacity-50"
                                    >
                                        {!coupon?.redeemed ? "GET COUPON" : "REDEEMED"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <h4 className="block text-white my-2">{ad?.description}</h4>
                        <p className="block text-white my-2">Terms: {ad?.terms}</p>
                    </div>
                </div>
                <div className='flex justify-between items-center pt-2'>
                    <span className="text-white text-base font-normal">{!ad?.isUnlimited ? <span className="exp-date font-bold">
                        EXP {moment(ad?.couponExpiry).format('DD MMM YYYY')}
                    </span> :
                        <span className="exp-date font-bold">
                            Unlimited
                        </span>
                    }</span>
                </div>
            </div> */}

    </>
  )
}

