import React from 'react'

interface OrderDetailsProps {
  total: number;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ total }) => {
  const currency = "USD"
  return (
    <div className="flex flex-col gap-6 w-full p-6 rounded-[10px] border-gray-100 border-[1px] shadow-sm font-normal">
      <h1 className="text-xl font-bold">Your Order</h1>
      <div className="flex flex-col gap-3 text-base mt-7">
        <div className='flex justify-center items-center mt-5 w-full'>
          <img loading="lazy" src='/e-coupon-book.jpg' alt='ebook' className='w-full' />
        </div>
        <hr />
        <div className="flex justify-between items-center font-semibold opacity-90">
          <p>Total</p>
          <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(total)}</span>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails
