import { useState, useEffect } from 'react';

function useDeviceDetect() {
    const [device, setDevice] = useState('desktop');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            if (width <= 768) {
                setDevice('mobile');
            } else if (width > 768 && width <= 1024) {
                setDevice('tablet');
            } else {
                setDevice('desktop');
            }
        };

        handleResize(); // Call on mount to set initial device type
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return device;
}

export default useDeviceDetect;
