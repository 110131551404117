export default function ProductCard({
    productName,
    amount,
    imageUrl,
    ratings,
}: {
    productName: string;
    amount: number;
    imageUrl: string;
    ratings: number;
}) {
    return (
        <div className="flex flex-col rounded-2xl shadow bg-white p-2">
            <img
                src={imageUrl}
                alt="ProductImage"
                className="w-full object-cover rounded-lg aspect-[4/3]"
            />
            <div className="p-5 w-full">
                <h3 className="text-lg mb-2">{productName}</h3>
                <div className="flex w-full 2xl:flex-row xl:flex-row md:flex-row lg:flex-row gap-3 justify-between">
                    <h3 className="text-xl font-semibold">${amount}</h3>
                    <div className="flex items-center">
                        <p className="bg-yellow-300 rounded-full px-3 py-0.5 w-fit text-sm">
                            {ratings}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
