import { Link } from "react-router-dom";
import MyOrdersTable from "../../components/common/ui/orders-table";
import { useShopifyCustomerOrdersListQuery } from "../../features/api/products-api";
import { FaArrowRight } from "react-icons/fa6";
import { useEffect } from "react";
import { useToast } from "../../components/shared/ToastContext";

export default function MyOrdersPage() {
    const { data, isLoading, isError } = useShopifyCustomerOrdersListQuery();
    const { showToast } = useToast();
    useEffect(() => {
        if (isError) {
            showToast("Something went wrong loading your order list", "error", "Error loading order list")
        }

    }, [isError])


    return (
        <>
            <div className="w-full bg-white lg:rounded-3xl p-6">
                <h2 className="text-black text-2xl font-medium leading-snug">My Orders</h2>
                {data?.orders?.length === 0 ? (
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="flex flex-col items-center gap-3">
                            <h6 className="text-black text-lg font-normal">No Orders Found!</h6>
                            <Link to={`/`} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm flex items-center gap-2'>Back to Home <FaArrowRight /></Link>
                        </div>
                    </div>
                ) : (
                    <MyOrdersTable data={data} handleDeleteOrder={() => { }} handleEditOrder={() => { }} isLoading={isLoading} />
                )}

            </div>
        </>
    )
}
