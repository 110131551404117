import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useActivateEmailMutation } from "../../../../features/slices/api-slices";

export default function ActivationPage() {
    // id from the url /:id
    const { token } = useParams<{ token: string }>();
    const [activate, { isLoading, isSuccess, isError }] = useActivateEmailMutation();

    useEffect(() => {
        activate({
            activation_token: token
        });
    }, [token, activate]);


    return (
        <>
            {
                isLoading ? <div className="text-center h-[70vh] flex flex-col justify-center items-center">
                    <p className="text-base font-semibold text-teal-600">Hi Dear!</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">Please Wait...</h1>
                    <p className="mt-6 text-base leading-7 text-neutral-600">
                        We are verifying your email.
                    </p>
                </div> :
                    isSuccess ?
                        <div>
                            <div className="flex items-center justify-center h-screen">
                                <div className="p-1 rounded shadow-lg bg-gradient-to-r from-purple-500 via-green-500 to-blue-500">
                                    <div className="flex flex-col items-center p-4 space-y-2 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Thank You !</h1>
                                        <p>
                                            Your email has been successfully verified. {" " + token}
                                        </p>
                                        <p>Thank you for your interest! Check your email for a link to the guide.</p>
                                        <a
                                            href='/'
                                            className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounde rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                            </svg>
                                            <span className="text-sm font-medium">
                                                Home
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        !isLoading && isError && <div className="text-center h-[70vh] flex flex-col justify-center items-center">
                            <p className="text-base font-semibold text-teal-600">Hi Dear!</p>
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">Error While Verification</h1>
                            <p className="mt-6 text-base leading-7 text-red-600">
                                {isLoading ? "Loading..." : "Error while verifying your email. Please try again."}
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href="/"
                                    className="bg-[var(--theme-yellow)] text-[var(--theme-blue)] py-2 px-4 font-bold rounded-md">
                                    Go back home
                                </a>
                            </div>
                        </div>
            }
        </>
    )
}
