import moment from "moment";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
export default function Table({data}: any) {

    return (
        <>
            <div className="relative overflow-x-auto mt-8">
                <table className="w-full text-sm text-left border border-solid border-gray-100 rtl:text-right rounded-t-xl rounded-xl text-gray-500">
                    <thead className="text-sm text-gray-700 capitalize bg-gray-200">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                User ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Club ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Parent ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Completion%
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total Rewards
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((data: any) => (
                                <tr key={data._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {moment(data.createdAt).format('DD/MM/YYYY HH:mm')}
                                    </td>
                                    <td className="px-6 text-center py-4">
                                        {data.userId}
                                    </td>
                                    <td className="px-6 text-center py-4">
                                        {data._id}
                                    </td>
                                    <td className="px-6 text-center py-4">
                                        {data.parentClubId}
                                    </td>
                                    <td className="px-6 text-center py-4">
                                        {Number(data.completionPercentage).toFixed(3)}%
                                    </td>
                                    <td className="px-6 text-center py-4">
                                        {data.totalRewards}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className='flex gap-x-4 mt-8 items-center justify-end'>
                    <button type='button' className="size-10 flex justify-center items-center bg-yellow-300 rounded">
                        <FaArrowLeft />
                    </button>
                    <button type='button' className="size-10 flex justify-center items-center bg-yellow-300 rounded">
                        <FaArrowRight />
                    </button>
                </div>
            </div>
        </>
    )
}
