import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    Product,
    AllProductsArgs,
    SearchProductsArgs,
    NewProductArgs,
    UpdateProductArgs,
    DeleteProductArgs,
    ShopifyProductsArgs,
    StorefrontProductsArgs,
    CreateShopifyCartArgs,
} from "../../interfaces/interface";
import { API_URL } from "../../utils/utils";
// Define a token
const token = localStorage.getItem("token"); // Replace with your actual token

export const productAPI = createApi({
    reducerPath: "productApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + "/product/",
        headers: {
            Authorization: `${token}`,
        },
    }),
    tagTypes: [
        "product",
        "shopify",
        "magic-club",
        "wallet",
        "ebook",
        "customer-orders",
    ],
    endpoints: (builder) => ({
        latestProducts: builder.query<any, any>({
            query: ({ currency }) => {
                let base = `latest`;
                if (currency) base += `?currencyTo=${currency}`;
                return base;
            },
            providesTags: ["product"],
        }),
        allProducts: builder.query<Product[], AllProductsArgs>({
            query: (data) => {
                let base = `admin-products`;
                if (data?.currency) base += `?currencyTo=${data.currency}`;
                if (data?.search) base += `&search=${data.search}`;
                return base;
            },
            providesTags: ["product"],
        }),
        searchProducts: builder.query<Product[], SearchProductsArgs>({
            query: ({ price, search, sort, category, page, tags, currency }) => {
                let base = `all?search=${search}`;
                if (price) base += `&price=${price}`;
                if (sort) base += `&sort=${sort}`;
                if (category) base += `&category=${category}`;
                if (page) base += `&page=${page}`;
                if (tags) base += `&tags=${tags}`;
                if (currency) base += `&currencyTo=${currency}`;
                return base;
            },
            providesTags: ["product"],
        }),

        productDetails: builder.query<Product, string>({
            query: (id) => id,
            providesTags: ["product"],
        }),

        newProduct: builder.mutation<void, NewProductArgs>({
            query: ({ formData, id }) => ({
                url: `new?id=${id}`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["product"],
        }),

        updateProduct: builder.mutation<void, UpdateProductArgs>({
            query: ({ formData, userId, productId }) => ({
                url: `${productId}?id=${userId}`,
                method: "PUT",
                body: formData,
            }),
            invalidatesTags: ["product"],
        }),

        deleteProduct: builder.mutation<void, DeleteProductArgs>({
            query: ({ userId, productId }) => ({
                url: `${productId}?id=${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["product"],
        }),

        shopifyCollections: builder.query<any, void>({
            query: () => `shopify/collections`,
            providesTags: ["shopify"],
        }),
        shopifyProducts: builder.query<any, ShopifyProductsArgs>({
            query: ({ collection_id = null, limit = 30 }) => {
                let url = `shopify`;
                if (limit) url += `?limit=${limit}`;
                if (collection_id) url += `&collection_id=${collection_id}`;
                return url;
            },
            providesTags: ["shopify"],
        }),
        shopifyProduct: builder.query<any, any>({
            query: (id) => `shopify/${id}`,
            providesTags: ["shopify"],
        }),
        salesfrontCollections: builder.query<any, void>({
            query: () => `shopify/storefront/collections`,
            providesTags: ["shopify"],
        }),
        storefrontProducts: builder.query<any, any>({
            query: ({ search = "", limit, sortKey, productType, priceRange, brands, materialType, rating }) => {
                let base = `shopify/storefront/products?`;
                if (search) base += `search=${search}`;
                if (limit) base += `&limit=${limit}`;
                if (sortKey) base += `&sortKey=${sortKey}`;
                if (productType) base += `&productType=${productType}`;
                if (priceRange) base += `&priceRange=${priceRange}`;
                if (brands) base += `&brands=${brands}`;
                if (materialType) base += `&materialType=${materialType}`;
                if (rating) base += `&rating=${rating}`;
                return base;
            },
            providesTags: ["shopify"],
        }),
        getAvailableFilters: builder.query<any, void>({
            query: () => `shopify/storefront/filters`,
            providesTags: ["shopify"],
        }),
        productRecommendations: builder.query<any, any>({
            query: ({ id, limit = 10 }) => `shopify/storefront/products/recommendations/${id}?limit=${limit}`,
            providesTags: ["shopify"],
        }),
        createShopifyOrder: builder.mutation<any, any>({
            query: (order) => ({
                url: "/order/checkout",
                method: "POST",
                body: order,
            }),
            invalidatesTags: ["shopify", "magic-club", "wallet", "ebook"],
        }),

        getMagicClubs: builder.query<any, void>({
            query: () => `user/magic-club`,
            providesTags: ["magic-club"],
        }),

        getWallet: builder.query<any, void>({
            query: () => `user/wallet`,
            providesTags: ["wallet"],
        }),
        shopifyOrderDetails: builder.query<any, string>({
            query: (id) => `shopify/order/${id}`,
            providesTags: ["shopify"],
        }),

        shopifyCustomerOrdersList: builder.query<any, void>({
            query: () => `shopify/order/customer/list`,
            providesTags: ["customer-orders"],
        }),

        shopifyCreateCart: builder.mutation<void, CreateShopifyCartArgs>({
            query: (cart) => ({
                url: `shopify/cart`,
                method: "POST",
                body: cart,
            }),
            invalidatesTags: ["shopify"],
        }),
    }),
});

export const {
    useLatestProductsQuery,
    useAllProductsQuery,
    useSearchProductsQuery,
    useProductDetailsQuery,
    useNewProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useShopifyCollectionsQuery,
    useShopifyProductsQuery,
    useShopifyProductQuery,
    useStorefrontProductsQuery,
    useProductRecommendationsQuery,
    useCreateShopifyOrderMutation,
    useGetMagicClubsQuery,
    useGetWalletQuery,
    useShopifyOrderDetailsQuery,
    useShopifyCustomerOrdersListQuery,
    useShopifyCreateCartMutation,
    useSalesfrontCollectionsQuery,
    useGetAvailableFiltersQuery,
} = productAPI;
