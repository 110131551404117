
import { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Link } from 'react-router-dom';
import Divider from '../ui/divider';
import { useGetProfileQuery } from '../../../features/slices/api-slices';

export default function Header() {
    const [showHideProfileDropDown, setShowHideProfileDropDown] = useState(false);
    const { data } = useGetProfileQuery();
    return (
        <>
            <div className='hidden lg:flex w-full py-5 justify-between 2xl:mx-0 xl:mx-0 lg:mx-4 md:mx-4 mx-4 items-center'>
                <div className='2xl:w-1/2 xl:w-1/2 w-11/12'>
                    <div className='w-full h-10 relative bg-white rounded-full'>
                        <input type="text" placeholder='Search' className='border-none outline-none bg-white px-6 rounded-full h-full w-full' />
                        <img loading="lazy" src='/assets/images/svg/search.svg' alt="" className='absolute top-3 right-5 z-30' />
                    </div>
                </div>
                <div className='w-1/2 2xl:block xl:block md:block lg:block hidden'>
                    <div className='2xl:flex xl:flex hidden justify-end flex-row items-center w-full gap-10'>
                        <button type='button' className='size-10 bg-white rounded-full flex justify-center items-center'>
                            <img loading="lazy" src="/assets/images/svg/notification.svg" alt="" />
                        </button>
                        <div className='relative'>
                            <div onClick={() => setShowHideProfileDropDown(!showHideProfileDropDown)} className='w-fit select-none h-fit cursor-pointer px-3 py-1 bg-white rounded-full border-2 border-solid border-gray-200 flex gap-4 justify-between items-center'>
                                <img loading="lazy" src={`${data?.avatar}`} className='size-8 rounded-full' alt="" />
                                <div className='flex flex-col'>
                                    <h6 className='text-black mb-0 font-bold text-sm capitalize'>
                                        {data?.name}
                                    </h6>
                                    <span className='text-sm'>
                                        @Username
                                    </span>
                                </div>
                                <MdKeyboardArrowDown className='text-2xl' />
                            </div>
                            {showHideProfileDropDown && (
                                <div className='bg-white absolute top-full mt-2 w-52 h-fit z-50 rounded-md border=2 border-solid border-gray-200'>
                                    <ul className='flex flex-col gap-y-7 p-4'>
                                        <li>
                                            <Link to={`/user-panel/profile`} className='flex items-center gap-2 text-sm font-medium hover:text-yellow-500' onClick={() => setShowHideProfileDropDown(false)}>
                                                <img loading="lazy" src="/assets/icons/side-bar/p-sm.svg" alt="" />
                                                My Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/user-panel/magic-club`} className='flex items-center gap-2 text-sm font-medium hover:text-yellow-500' onClick={() => setShowHideProfileDropDown(false)}>
                                                <img loading="lazy" src="/assets/icons/side-bar/magic.svg" alt="" className='size-4' />
                                                Magic Club
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/user-panel/my-cart`} className='flex items-center gap-2 text-sm font-medium hover:text-yellow-500' onClick={() => setShowHideProfileDropDown(false)}>
                                                <img loading="lazy" src="/assets/icons/side-bar/cart-my.svg" className='size-4' alt="" />
                                                My Cart
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/user-panel/wishlist`} className='flex items-center gap-2 text-sm font-medium hover:text-yellow-500' onClick={() => setShowHideProfileDropDown(false)}>
                                                <img loading="lazy" src="/assets/icons/side-bar/whitelist.svg" alt="" className='size-4' />
                                                Wishlist
                                            </Link>
                                        </li>
                                    </ul>
                                    <Divider />
                                    <div className='p-4'>
                                        <button type='button' className='flex items-center gap-2 border-none outline-none bg-transparent text-sm font-medium hover:text-yellow-500' onClick={() => {
                                            localStorage.removeItem("token");
                                            window.location.assign("/");
                                            setShowHideProfileDropDown(false)
                                        }}>
                                            <img loading="lazy" src="/assets/icons/side-bar/logout.svg" alt="" />
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
