import React, { useCallback, useState } from 'react'
import { FileDragDropInputZoneProps } from '../../../interfaces/interface'

const DragDropFile: React.FC<FileDragDropInputZoneProps> = ({ onFileAdded, user, setAvatar }: any) => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('image/')) {
      setFile(droppedFile);
      setPreview(URL.createObjectURL(droppedFile));
    }
  }, []);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("file change");

    const selectedFile = event.target.files?.[0] || null;
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
      console.log(selectedFile, "selectedFile");

      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        let base64data = "data:image/jpeg;base64," + reader.result?.toString().split(",")[1];
        console.log(base64data.substring(0, 100));
        
        setAvatar(base64data);
      };

      // setAvatar(event.target.files?.[0]);
      setPreview(URL.createObjectURL(selectedFile));
    }
  };
  return (
    <>
      {user ? (
        <div className={`w-full h-fit py-4 rounded-lg relative flex justify-center items-center border border-dashed ${file ? "border-blue-500 bg-blue-300/25" : "border-zinc-300 bg-zinc-100"}`}
          onDrop={onDrop} onDragOver={onDragOver}>
          <input
            type="file"
            multiple
            onChange={onFileChange}
            className="hidden"
            id="fileInput"
          />
          <label htmlFor='fileInput' className="flex flex-col gap-1.5 items-center">
            <img loading="lazy" src="/assets/images/svg/upload.svg" alt="" />
            <p className="text-neutral-500">
              <span className="text-black cursor-pointer text-center hover:underline">Upload your image</span> or drag and drop.
            </p>
            <span className="text-sm font-normal text-center text-neutral-500">(PNG, JPEG are supported) 400px max size.</span>
          </label>
          {preview ? (
            <img loading="lazy" src={`${preview}`} className="2xl:absolute xl:absolute md:absolute lg:absolute  2xl:left-8 xl:left-8 md:left-8 lg:left-8 absolute bottom-0 size-36 rounded-full" alt="" />
          ) : (
            <img loading="lazy" src={user.avatar} className="2xl:absolute xl:absolute md:absolute lg:absolute  2xl:left-8 xl:left-8 md:left-8 lg:left-8 absolute bottom-0 2xl:size-36 xl:size-36 md:size-36 lg:size-36 size-24 rounded-full" alt="" />
          )}

        </div>
      ) : (
        <div className={`w-full h-fit py-4 rounded-lg relative flex justify-center items-center border border-dashed ${file ? "border-blue-500 bg-blue-300/25" : "border-zinc-300 bg-zinc-100"}`}
          onDrop={onDrop} onDragOver={onDragOver}>
          <input
            type="file"
            multiple
            onChange={onFileChange}
            className="hidden"
            id="fileInput"
          />
          <label htmlFor='fileInput' className="flex flex-col gap-1.5 items-center">
            <img loading="lazy" src="/assets/images/svg/upload.svg" alt="" />
            <p className="text-neutral-500">
              <span className="text-black cursor-pointer hover:underline">Upload your image</span> or drag and drop.
            </p>
            <span className="text-sm font-normal text-neutral-500">(PNG, JPEG are supported) 400px max size.</span>
          </label>
          {preview ? (
            <img loading="lazy" src={`${preview}`} className="absolute left-8 size-36 rounded-full" alt="" />
          ) : (
            <img loading="lazy" src={`/assets/images/profile.png`} className="2xl:absolute xl:absolute md:absolute lg:absolute  2xl:left-8 xl:left-8 md:left-8 lg:left-8 absolute bottom-0 size-36 rounded-full" alt="" />
          )}

        </div>
      )}
    </>
  )
}


export default DragDropFile;