type Category = {
    name: string;
    link: string;
    src: string;
};

const categories: Category[] = [
    {
        name: "Electronics",
        link: "/category/electronics?cat=Electronics",
        src: "/electronics.png",
    },
    { name: "Clothings", link: "/category/clothings", src: "/clothings.png" },
    {
        name: "Health & Beauty",
        link: "/category/health_beauty",
        src: "/health.png",
    },
    { name: "Home & Kitchen", link: "/category/home_kitchen", src: "/home.png" },
    {
        name: "Home & Furniture",
        link: "/category/home_furniture",
        src: "/furnitures.png",
    },
    {
        name: "Grocery & Market",
        link: "/category/grocery_market",
        src: "/grocery.png",
    },
    {
        name: "Toys & Video Games",
        link: "/category/toys_games",
        src: "/toys.png",
    },
    { name: "Babies & Moms", link: "/category/babies_moms", src: "/babies.png" },
];

const sellerCategories = [
    {
        name: "Top 20",
    },
    {
        name: "Baby",
    },
    {
        name: "Electronics",
    },
    {
        name: "See All",
    }
];

const shopCategories = [
    {
        name: "Electronics",
        link: "/category/electronics?cat=Electronics",
    },
    {
        name: "Baby",
        link: "/category/baby",
    },
    {
        name: "Clothings",
        link: "/category/clothings",
    },
    {
        name: "Furniture",
        link: "/category/furniture",
    },
    {
        name: "Grocery",
        link: "/category/grocery",
    },
    {
        name: "Toy & Video Games",
        link: "/category/toy_video_games",
    },
    {
        name: "Kitchen",
        link: "/category/kitchen",
    },
    {
        name: "Beauty",
        link: "/category/beauty",
    }
]

const businessCategories = [
    {
        value: "accountingInsuranceFinancialServices",
        label: "Accounting, Insurance, and Financial Services",
        subCategories: [
            { value: "accountant", label: "Accountant" },
            { value: "actuary", label: "Actuary" },
            { value: "attorney", label: "Attorney" },
            { value: "bankCreditUnion", label: "Bank or credit union" },
            { value: "bookkeeper", label: "Bookkeeper" },
            { value: "financialAdvisor", label: "Financial advisor" },
            { value: "financialPlanner", label: "Financial planner" },
            { value: "insuranceAgentBroker", label: "Insurance agent or broker" },
            { value: "investmentAdvisor", label: "Investment advisor" },
            { value: "mortgageBroker", label: "Mortgage broker" },
            { value: "payrollService", label: "Payroll service" },
            { value: "realEstateAgentBroker", label: "Real estate agent or broker" },
            { value: "taxAdvisor", label: "Tax advisor" },
            { value: "titleInsuranceCompany", label: "Title insurance company" }
        ],
    },
    {
        value: "artsEntertainmentRecreation",
        label: "Arul, Entertainment, and Recreation",
        subCategories: [
            { value: "amusementPark", label: "Amusement park" },
            { value: "artGallery", label: "Art gallery" },
            { value: "artist", label: "Artist" },
            { value: "band", label: "Band" },
            { value: "bowlingAlley", label: "Bowling alley" },
            { value: "casino", label: "Casino" },
            { value: "concertHall", label: "Concert hall" },
            { value: "danceSchool", label: "Dance school" },
            { value: "danceStudio", label: "Dance studio" },
            { value: "fitnessCenter", label: "Fitness center" },
            { value: "gym", label: "Gym" },
            { value: "golfCourse", label: "Golf course" },
            { value: "healthSpa", label: "Health spa" },
            { value: "karaokeBar", label: "Karaoke bar" },
            { value: "liveMusicVenue", label: "Live music venue" },
            { value: "museum", label: "Museum" },
            { value: "musicSchool", label: "Music school" },
            { value: "musicStudio", label: "Music studio" },
            { value: "nightclub", label: "Nightclub" },
            { value: "park", label: "Park" },
            { value: "performingArtsTheater", label: "Performing arts theater" },
            { value: "recreationCenter", label: "Recreation center" },
            { value: "sportingGoodsStore", label: "Sporting goods store" },
            { value: "tattooParlor", label: "Tattoo parlor" },
            { value: "theater", label: "Theater" },
            { value: "touristAttraction", label: "Tourist attraction" },
            { value: "videoGameStore", label: "Video game store" }
        ],
    },
    {
        value: "beautyHealthWellness",
        label: "Beauty, Health, and Wellness",
        subCategories: [
            { value: "audiologist", label: "Audiologist" },
            { value: "chiropractor", label: "Chiropractor" },
            { value: "dentist", label: "Dentist" },
            { value: "dermatologist", label: "Dermatologist" },
            { value: "doctor", label: "Doctor" },
            { value: "hairSalon", label: "Hair salon" },
            { value: "hearingAidStore", label: "Hearing aid store" },
            { value: "holisticAlternativeMedicine", label: "Holistic or alternative medicine practitioner" },
            { value: "hospital", label: "Hospital" },
            { value: "massageTherapist", label: "Massage therapist" },
            { value: "medicalLaboratory", label: "Medical laboratory" },
            { value: "mentalHealthProfessional", label: "Mental health professional" },
            { value: "optometrist", label: "Optometrist" },
            { value: "pharmacy", label: "Pharmacy" },
            { value: "physicalTherapist", label: "Physical therapist" },
            { value: "plasticSurgeon", label: "Plastic surgeon" },
            { value: "spa", label: "Spa" },
            { value: "veterinarian", label: "Veterinarian" },
            { value: "wellnessCenter", label: "Wellness center" },
            { value: "yogaStudio", label: "Yoga studio" }
        ],
    },
    {
        value: "constructionHomeRepairServices",
        label: "Construction, Home Repair, and Services",
        subCategories: [
            { value: "applianceRepairService", label: "Appliance repair service" },
            { value: "architect", label: "Architect" },
            { value: "carpenter", label: "Carpenter" },
            { value: "carpetInstaller", label: "Carpet installer" },
            { value: "contractor", label: "Contractor" },
            { value: "electrician", label: "Electrician" },
            { value: "fenceContractor", label: "Fence contractor" },
            { value: "flooringContractor", label: "Flooring contractor" },
            { value: "generalContractor", label: "General contractor" },
            { value: "handyman", label: "Handyman" },
            { value: "hvacContractor", label: "HVAC contractor" },
            { value: "interiorDesigner", label: "Interior designer" },
            { value: "landscapeArchitect", label: "Landscape architect" },
            { value: "lawnCareService", label: "Lawn care service" },
            { value: "painter", label: "Painter" },
            { value: "plumber", label: "Plumber" },
            { value: "poolCleanerService", label: "Pool cleaner or service" },
            { value: "remodeler", label: "Remodeler" },
            { value: "roofingContractor", label: "Roofing contractor" },
            { value: "septicTankService", label: "Septic tank service" },
            { value: "treeService", label: "Tree service" },
            { value: "windowContractor", label: "Window contractor" }
        ],
    },
    {
        value: "educationTraining",
        label: "Education and Training",
        subCategories: [
            { value: "adultEducationCenter", label: "Adult education center" },
            { value: "artSchool", label: "Art school" },
            { value: "collegeUniversity", label: "College or university" },
            { value: "communityCollege", label: "Community college" },
            { value: "danceSchool", label: "Dance school" },
            { value: "drivingSchool", label: "Driving school" },
            { value: "elementarySchool", label: "Elementary school" },
            { value: "highSchool", label: "High school" },
            { value: "languageSchool", label: "Language school" },
            { value: "musicSchool", label: "Music school" },
            { value: "preschool", label: "Preschool" },
            { value: "privateSchool", label: "Private school" },
            { value: "religiousSchool", label: "Religious school" },
            { value: "technicalVocationalSchool", label: "Technical or vocational school" },
            { value: "testPreparationService", label: "Test preparation service" },
            { value: "tutor", label: "Tutor" }
        ],
    },
    {
        value: "foodDining",
        label: "Food and Dining",
        subCategories: [
            { value: "bagelShop", label: "Bagel shop" },
            { value: "bakery", label: "Bakery" },
            { value: "barPub", label: "Bar or pub" },
            { value: "barbecueRestaurant", label: "Barbecue restaurant" },
            { value: "breakfastRestaurant", label: "Breakfast restaurant" },
            { value: "bubbleTeaShop", label: "Bubble tea shop" },
            { value: "cafe", label: "Cafe" },
            { value: "coffeeShop", label: "Coffee shop" },
            { value: "convenienceStore", label: "Convenience store" },
            { value: "dessertShop", label: "Dessert shop" },
            { value: "donutShop", label: "Donut shop" },
            { value: "foodCourt", label: "Food court" },
            { value: "foodTruck", label: "Food truck" },
            { value: "groceryStore", label: "Grocery store" },
            { value: "iceCreamShop", label: "Ice cream shop" },
            { value: "juiceBar", label: "Juice bar" },
            { value: "liquorStore", label: "Liquor store" },
            { value: "market", label: "Market" },
            { value: "mediterraneanRestaurant", label: "Mediterranean restaurant" },
            { value: "mexicanRestaurant", label: "Mexican restaurant" },
            { value: "pizzaRestaurant", label: "Pizza restaurant" },
            { value: "sandwichShop", label: "Sandwich shop" },
            { value: "seafoodRestaurant", label: "Seafood restaurant" },
            { value: "steakhouse", label: "Steakhouse" },
            { value: "sushiRestaurant", label: "Sushi restaurant" },
            { value: "tacoStand", label: "Taco stand" },
            { value: "teaShop", label: "Tea shop" },
            { value: "vegetarianVeganRestaurant", label: "Vegetarian or vegan restaurant" }
        ],
    },
    {
        value: "hotelsLodging",
        label: "Hotels and Lodging",
        subCategories: [
            { value: "bedAndBreakfast", label: "Bed and breakfast" },
            { value: "campground", label: "Campground" },
            { value: "extendedStayHotel", label: "Extended stay hotel" },
            { value: "hostel", label: "Hostel" },
            { value: "hotel", label: "Hotel" },
            { value: "motel", label: "Motel" },
            { value: "rvPark", label: "RV park" },
            { value: "vacationRental", label: "Vacation rental" }
        ],
    },
    {
        value: "localServices",
        label: "Local Services",
        subCategories: [
            { value: "atm", label: "ATM" },
            { value: "carWash", label: "Car wash" },
            { value: "childCareService", label: "Child care service" },
            { value: "churchReligiousOrganization", label: "Church or religious organization" },
            { value: "communityCenter", label: "Community center" },
            { value: "copyShop", label: "Copy shop" },
            { value: "courthouse", label: "Courthouse" },
            { value: "dryCleaner", label: "Dry cleaner" },
            { value: "fireStation", label: "Fire station" },
            { value: "governmentOffice", label: "Government office" },
            { value: "library", label: "Library" },
            { value: "lostStolenItemReportingService", label: "Lost or stolen item reporting service" },
            { value: "movieTheater", label: "Movie theater" },
            { value: "parkingLot", label: "Parking lot" },
            { value: "park", label: "Park" },
            { value: "postOffice", label: "Post office" },
            { value: "publicTransportationStation", label: "Public transportation station" },
            { value: "taxiService", label: "Taxi service" },
            { value: "towingService", label: "Towing service" },
            { value: "utilityCompany", label: "Utility company" },
            { value: "warehouse", label: "Warehouse" }
        ],
    },
    {
        value: "professionalServices",
        label: "Professional Services",
        subCategories: [
            { value: "advertisingAgency", label: "Advertising agency" },
            { value: "computerRepairService", label: "Computer repair service" },
            { value: "consultingFirm", label: "Consulting firm" },
            { value: "designAgency", label: "Design agency" },
            { value: "eventPlanner", label: "Event planner" },
            { value: "marketingAgency", label: "Marketing agency" },
            { value: "mover", label: "Mover" },
            { value: "printingService", label: "Printing service" },
            { value: "publicRelationsAgency", label: "Public relations agency" },
            { value: "staffingAgency", label: "Staffing agency" },
            { value: "webDesigner", label: "Web designer" },
            { value: "writingService", label: "Writing service" }
        ],
    },
    {
        value: "retail",
        label: "Retail",
        subCategories: [
            { value: "antiqueShop", label: "Antique shop" },
            { value: "applianceStore", label: "Appliance store" },
            { value: "artSupplyStore", label: "Art supply store" },
            { value: "autoPartsStore", label: "Auto parts store" },
            { value: "babyStore", label: "Baby store" },
            { value: "bookstore", label: "Bookstore" },
            { value: "boutique", label: "Boutique" },
            { value: "clothingStore", label: "Clothing store" },
            { value: "computerStore", label: "Computer store" },
            { value: "electronicsStore", label: "Electronics store" },
            { value: "furnitureStore", label: "Furniture store" },
            { value: "giftShop", label: "Gift shop" },
            { value: "groceryStore", label: "Grocery store" },
            { value: "hardwareStore", label: "Hardware store" },
            { value: "jewelryStore", label: "Jewelry store" },
            { value: "liquorStore", label: "Liquor store" },
            { value: "motorcycleShop", label: "Motorcycle shop" },
            { value: "musicStore", label: "Music store" },
            { value: "newsstand", label: "Newsstand" },
            { value: "outdoorStore", label: "Outdoor store" },
            { value: "pawnShop", label: "Pawn shop" },
            { value: "petStore", label: "Pet store" },
            { value: "pharmacy", label: "Pharmacy" },
            { value: "shoeStore", label: "Shoe store" },
            { value: "sportingGoodsStore", label: "Sporting goods store" },
            { value: "toyStore", label: "Toy store" },
            { value: "usedCarDealer", label: "Used car dealer" },
            { value: "vapeShop", label: "Vape shop" },
            { value: "videoGameStore", label: "Video game store" }
        ],
    },
    {
        value: "travelTransportation",
        label: "Travel and Transportation",
        subCategories: [
            { value: "airport", label: "Airport" },
            { value: "airline", label: "Airline" },
            { value: "carRentalAgency", label: "Car rental agency" },
            { value: "cruiseLine", label: "Cruise line" },
            { value: "taxiService", label: "Taxi service" },
            { value: "tourOperator", label: "Tour operator" },
            { value: "travelAgency", label: "Travel agency" }
        ],
    },
    {
        value: "other",
        label: "Other",
        subCategories: [
            { value: "agriculturalService", label: "Agricultural service" },
            { value: "animalShelter", label: "Animal shelter" },
            { value: "automotiveRepairShop", label: "Automotive repair shop" },
            { value: "carWash", label: "Car wash" },
            { value: "charityOrganization", label: "Charity organization" },
            { value: "childCareService", label: "Child care service" },
            { value: "dryCleaner", label: "Dry cleaner" },
            { value: "funeralHome", label: "Fun the specified fields" },
            { value: "gasStation", label: "Gas station" },
            { value: "greenhouse", label: "Greenhouse" },
            { value: "landscapeArchitect", label: "Landscape architect" },
            { value: "laundryService", label: "Laundry service" },
            { value: "lawnCareService", label: "Lawn care service" },
            { value: "nonProfitOrganization", label: "Non-profit organization" },
            { value: "parkingLot", label: "Parking lot" },
            { value: "recyclingCenter", label: "Recycling center" },
            { value: "religiousOrganization", label: "Religious organization" },
            { value: "storageFacility", label: "Storage facility" },
            { value: "utilityCompany", label: "Utility company" },
            { value: "warehouse", label: "Warehouse" }
        ],
    }
];

export {
    categories,
    sellerCategories,
    shopCategories,
    businessCategories
}