import React from 'react'

const DashboardBanner = ({
    title,
    image,
    color
}: {
    title: string
    image: string,
    color?: string
}) => {
    return (
        <main className='w-full relative'>
            <div className='inset-0 bg-black opacity-50 absolute z-0 rounded-lg'></div>
            <img src={image} className='w-full 2xl:h-auto xl:h-auto h-36 rounded-lg' alt="" />
            <div className='absolute top-0 left-0 right-0 2xl:pt-32 xl:pt-28 pt-10'>
                <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-10 px-5'>
                    <h1 className={`2xl:text-4xl text-white lg:text-${color} xl:text-4xl text-2xl font-bold 2xl:w-[582px] xl:w-[582px] 2xl:leading-[55px] xl:leading-[55px] w-full px-5`}>
                        {title}
                    </h1>
                </div>
            </div>
        </main>
    )
}

export default DashboardBanner