"use client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { useForm, FormProvider } from "react-hook-form";
import { orderFormInitialValues, orderFormSchema } from "../../features/schemas/order-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import CheckoutForm from "../../components/common/checkout/checkout-form";
import { AppState } from "../../redux/store";
import { Navbar } from "../../components/shared/navbar";
import Order from "../../components/common/checkout/OrderDetails";
import PaymentInformation from "../../components/common/checkout/payment-info";
import CheckoutConfirmButton from "../../components/common/checkout/checkout-confirmation-button";

const Checkout = () => {
  const [country, setCountry] = useState("United States");
  const [region, setRegion] = useState("");
  const { user } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    setCountry(user?.country || "United States");
    setRegion(user?.state);
  }, [user, country, region]);

  const methods = useForm({
    resolver: zodResolver(orderFormSchema),
    defaultValues: orderFormInitialValues({}, 100), //(user, total)
  });

  // const onSubmit = (values:
  //   any
  // ) => {
  //   console.log("order is being placed", values);
  // };

  return (
    <div className="w-full">
      <Navbar />

      <div className="w-full flex justify-center items-center p-5">
        <div className="pb-10 mt-0 flex flex-col gap-8 w-[95%] md:w-[90%] lg:w-[80%]">
          <h1 className="text-center text-3xl font-bold my-10">Checkout</h1>
          <div className="flex items-center gap-1">
            <Link to="/user-panel/my-cart">Back to cart</Link>
          </div>
          <FormProvider {...methods}>
            <div className="grid grid-cols-12 gap-5 md:gap-20">
              <div className="col-span-12 md:col-span-7">
                <CheckoutForm />
              </div>
              <div className="col-span-12 md:col-span-5 flex flex-col gap-5">
                <Order />
                <PaymentInformation />
                <CheckoutConfirmButton />
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
