import { useDeleteCouponMutation } from "../../../features/slices/api-slices"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteDialog from "./forms/dialog/delete-dialog";
import moment from "moment";
import { useToast } from "../../shared/ToastContext";

export default function VendorTable({ isLoading, data }: any) {
    const navigate = useNavigate();
    const [deleteCoupon, { error }] = useDeleteCouponMutation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const { showToast } = useToast();
    const handleDeleteClick = (id: any) => {
        setIsDialogOpen(true);
        setSelectedItemId(id);

    };

    const handleConfirm = async () => {
        if (selectedItemId) {
            try {
                await deleteCoupon(selectedItemId);
                showToast("Your coupons have been deleted successfully", "success", "");

            } catch (error) {
                showToast("Failed to delete your coupons because of error", "error", "");
            } finally {
                setIsDialogOpen(false);
                setSelectedItemId(null);
            }
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setSelectedItemId(null);
    };

    useEffect(() => {
        if (error) {
            showToast("Failed to load coupons because of error", "error", "");
        }
    }, [error])


    return (
        <>
            {isLoading ? (
                <div className="flex justify-center items-center min-h-screen w-full">
                    <img loading="lazy" src="/assets/images/gif/spinner.svg" className="size-36" alt="" />
                </div>
            ) : (
                <div className="relative overflow-x-auto mt-8 rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                            <tr>

                                <th scope="col" className="px-6 py-3">
                                    Shop
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Discount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Orders
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Limit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Valid From
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Valid Until
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: any) => (
                                <tr key={item._id} className="bg-white border-b hover:bg-gray-50">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 capitalize whitespace-nowrap">
                                        {item?.businessId?.businessName}
                                    </th>
                                    <td className="px-6 py-4">
                                        {item.discountType !== "free" ? <span className="">{item.discount}</span> : 'FREE'}
                                        {item.discountType === "percentage" ? "%" : item.discountType === "free" ? "" : "$"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.couponsGenerated}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.isUnlimitedLimit ? "Unlimited" : item.couponLimit}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.createdAt.slice(0, 10)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {item?.isUnlimited ? "Unlimited" : moment(item?.validUntil).format("DD-MM-YYYY")}
                                    </td>
                                    <td className={`px-6 py-4 ${item.isAvailable === true ? "text-green-400" : "text-red-500"}`}>
                                        {item.isAvailable === true ? "Active" : "Inactive"}
                                    </td>
                                    <td className="px-6 text-red-500 py-4">
                                        <div className="flex items-center gap-3">
                                            <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" onClick={() => navigate(`/vendor-panel/edit-coupons/${item._id}`)} alt="Edit" />
                                            <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" onClick={() => navigate(`/vendor-panel/view-coupons/${item._id}`)} alt="View" />
                                            <img loading="lazy" src="/assets/icons/side-bar/delete.svg" onClick={() => handleDeleteClick(item?._id)} className="cursor-pointer" alt="Delete" />
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            )}

            <DeleteDialog
                open={isDialogOpen}
                title="Confirm Deletion"
                onClose={handleClose}
                onConfirm={handleConfirm}
            >
                <p>Are you sure you want to delete this item?</p>
            </DeleteDialog>
        </>
    )
}
