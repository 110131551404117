import React from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

export default function MyRefundsPage() {
    return (
        <div className=' w-full min-h-screen bg-white p-4 rounded-2xl'>
            <div className='flex justify-center items-center min-h-screen'>
                <div className='flex flex-col gap-y-8 items-center w-full'>
                    <h1 className='2xl:text-5xl xl:text-5xl font-medium text-lg'>
                        Comming Soon!
                    </h1>
                    <div className='flex justify-center items-center'>
                        <Link to={`/`} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm flex items-center gap-2'>Back to Home <FaArrowRight /></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
