import React from 'react'
import { useForm } from 'react-hook-form';

export default function AddProductDescriptionForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);
    return (
        <>
            <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Description</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">

                    <div className="w-full relative col-span-2">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.name ? "text-red-500" : "text-zinc-800"}`}>Name *</label>
                        <input type="text" placeholder='Cedar Double Bed' {...register("name", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.name ? "border-red-500" : "border-gray-200"}`} />
                        {errors.name?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Name is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.slug ? "text-red-500" : "text-zinc-800"}`}>Slug *</label>
                        <input type="text" placeholder='Cedar Double Bed' {...register("slug", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.slug ? "border-red-500" : "border-gray-200"}`} />
                        {errors.slug?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Slug is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.unit ? "text-red-500" : "text-zinc-800"}`}>Unit *</label>
                        <input type="text" placeholder='Kg' {...register("unit", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.unit ? "border-red-500" : "border-gray-200"}`} />
                        {errors.unit?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Unit is required</p>}
                    </div>
                    <div className="w-full relative col-span-2">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.productdesc ? "text-red-500" : "text-zinc-800"}`}>Product Description *</label>
                        <textarea rows={4} placeholder='Write message here..........'{...register("productdesc", { required: true })} className={`w-full border resize-none p-3 border-solid rounded-md outline-none px-4 ${errors.productdesc ? "border-red-500" : "border-gray-200"}`} />
                        {errors.productdesc?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Product Description is required</p>}
                    </div>
                    <div className="flex justify-end gap-8 col-span-2">
                        <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            Save
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
