import React, { useEffect, useRef, useState } from 'react'
import { SelectOptionProps, SelectOptions } from '../../../../interfaces/interface'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export default function Select({ label, selectedOption, options, placeholder, setSelectedOption }: SelectOptionProps) {
    const [isopenSelect, setIsOpenSelect] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleSelect = (option: SelectOptions) => {
        setSelectedOption(option.label);
        setIsOpenSelect(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpenSelect(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            <div ref={dropdownRef} className="relative w-full">
                <div className='w-full relative select-none' onClick={() => setIsOpenSelect(!isopenSelect)}>
                    <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal`}>{label}</label>
                    <div className={`w-full border border-solid rounded-md outline-none h-14 px-4 border-gray-200 cursor-pointer`}>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center h-14 gap-2">
                                <label htmlFor="" className={`text-sm ${selectedOption ? "text-zinc-800" : "text-neutral-400"} bg-white font-normal`}>
                                    {selectedOption ? selectedOption : placeholder}
                                </label>
                            </div>
                            {isopenSelect ? <MdKeyboardArrowUp className='text-xl' /> :
                            <MdKeyboardArrowDown className="text-xl" /> }
                        </div>
                    </div>

                </div>
                {isopenSelect && (
                    <ul className="absolute left-0 w-full mt-0.5 py-3 bg-white rounded-md drop-shadow-xl max-h-60 overflow-y-auto z-10">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                onClick={() => handleSelect(option)}
                                className="px-4 py-3 hover:bg-gray-100 text-sm cursor-pointer"
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

        </>
    )
}
