import Footer from "../../components/common/footer/footer";
import MainHeader from "../../components/common/header/main-header";
import CartTable from "../../components/common/ui/cart-table";

export default function CartsPage() {
    return (
        <>
            <MainHeader />

            <div className="max-w-screen-2xl mx-auto pt-10 min-h-screen">
                <div className='w-full bg-white p-8 rounded-3xl h-fit'>
                    <h3 className="text-black text-[22px] font-medium leading-snug">Cart</h3>

                    <CartTable />
                </div>
            </div>

            <Footer />
        </>
    )
}
