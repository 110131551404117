import React, { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

interface CustomSelectProps {
    placeholder: string;
    options: string[];
    defaultOption?: string;
    onChange: (value: string) => void;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
    placeholder,
    options,
    defaultOption,
    onChange,
}) => {
    const [selectedValue, setSelectedValue] = useState<string>(
        defaultOption || ""
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        onChange(value);
        setIsOpen(false);
    };

    return (
        <div className="relative w-40">
            <div
                className="flex justify-between items-center px-4 py-2 border border-gray-300 rounded-md cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{selectedValue || placeholder}</span>
                <div className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`}>
                    <MdOutlineKeyboardArrowDown className="text-xl" />
                </div>
            </div>
            {isOpen && (
                <div className="absolute top-full left-0 right-0 mt-1 border border-gray-300 rounded-md bg-white z-10 max-h-60 overflow-y-auto">
                    {defaultOption && (
                        <div
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelect(defaultOption)}
                        >
                            {defaultOption}
                        </div>
                    )}
                    {options.map((option) => (
                        <div
                            key={option}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
