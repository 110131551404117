import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Tooltip,
    Cell,
    Label,
} from "recharts";

const data = [
    {
        name: "Done",
        value: 700,
    },
    {
        name: "Not Done",
        value: 300,
    },
];
const colors = ["#000000", "#FFFFFF"];

export default function SmallPieChart() {
    return (
        <div className="bg-yellow-400 rounded-3xl col-span-1 h-72 md:h-auto">
            <div className="flex flex-col w-full h-full p-5">
                <div className="w-full h-full">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                                data={data}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={45}
                                outerRadius={70}
                                fill="#82ca9d"
                                stroke="none"
                                paddingAngle={5}
                                cornerRadius={8}
                                startAngle={225}
                                endAngle={-45}
                                style={{border: 'none'}}
                            >
                                {data.map((_, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={colors[index % colors.length]}
                                        style={{outline: 'none'}}

                                    />
                                ))}
                                <Label
                                    value="65%"
                                    position="center"
                                    className="text-2xl font-semibold"
                                    fill="#000000"
                                />
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <h2 className="text-2xl font-bold text-center">Object Name</h2>
            </div>
        </div>
    );
}
