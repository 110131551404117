import LoginForm from '../../components/common/auth/login-form'

const LoginPage = () => {
  return (
    <div>
      <LoginForm />
    </div>
  )
}

export default LoginPage