import React from 'react'
import { useGetAllCouponsQuery } from '../../features/slices/api-slices'
import ProductSkeleton from '../shared/skeleton/ProductSkeleton'
import { useNavigate } from 'react-router-dom'
import { Autoplay } from 'swiper/modules'
import { SwiperSlide, Swiper } from 'swiper/react'
import 'swiper/css';
import useDeviceDetect from '../../hooks/device-detect'
import { getCouponAddress, getCouponDiscount } from '../../utils/utils'

export const CouponDealsSection = () => {
    const { isLoading, data } = useGetAllCouponsQuery()
    const device = useDeviceDetect()

    const backgrounds = ['#FFBB38', '#FFEFD1', '#FAFFC1', '#FFFACE']

    return (
        <>
            <div className='max-w-screen-2xl mx-auto mt-8 2xl:px-0 xl:px-16 px-3'>
                <div className=''>
                    {isLoading ?
                        // Add skeletons here
                        <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-3'>
                            {Array(4).fill(0).map((_, i) => (
                                <ProductSkeleton key={i} />
                            ))}
                        </div>
                        :
                        <Swiper
                            spaceBetween={40}
                            slidesPerView={device === 'mobile' ? 1 : 4}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false
                            }}
                            modules={[Autoplay]}
                            speed={1000}
                            loop={true}

                        >
                            {data?.slice(0, 10)?.map((item: any, index: number) => (
                                <SwiperSlide key={item.image}>
                                    <DealCard
                                        title={item.productTitle}
                                        image={item.image}
                                        discount={{
                                            discount: item.discount,
                                            discountType: item.discountType
                                        }}
                                        background={backgrounds[index]}
                                        link={"/buy-ebooks"}
                                        address={item.businessId}
                                        key={item._id} />
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                    }
                </div>
            </div>
        </>
    )

}

// param 1: title, param 2: image, param 3: discount, param 4: category, param 5: link, param 6: button text, param 7: bg color, param 8: button color
type DealCardProps = {
    title: string;
    image: string;
    discount: any;
    background: string;
    link: string;
    address?: any;
}

export const DealCard = ({ title, image, background, link, address, discount }: DealCardProps) => {
    const navigate = useNavigate();
    const className = `w-full rounded-xl relative 2xl:min-h-80 xl:min-h-80 min-h-72`;

    return (
        <div className={className} style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            position: 'relative'
        }}>
            {/* Overlay for opacity effect */}
            <div className='absolute inset-0 bg-black opacity-55 rounded-xl'></div>

            {/* Content */}
            <div className='relative p-6'>
                <h1 className='2xl:text-2xl xl:text-2xl text-sm font-bold text-white'>{title}</h1>
                <p className='text-white text-sm my-2'>{
                    getCouponDiscount(discount?.discount, discount?.discountType)
                }</p>
                <button
                    type='button'
                    onClick={() => navigate(link)}
                    className={`px-6 mt-4 py-2 text-black text-sm tracking-wider rounded-full !bg-[${background}]`}
                    style={{
                        backgroundColor: background || '#FFBB38'
                    }}
                >
                    Shop Now
                </button>
            </div>
            {address && <div className='absolute bottom-4 left-4 text-white'>
                <p className='font-bold'>{address?.businessName}</p>
                <p>{getCouponAddress(address)}</p>
            </div>}
        </div>
    );
};