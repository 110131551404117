import { Link } from "react-router-dom";
import Footer from "../../components/shared/footer";
import { Navbar } from "../../components/shared/navbar";

export default function TermsAndConditionsPage() {
    return (
        <>
            <Navbar />

            <div className="container mx-auto">
                <h1 className="text-xl mb-4 font-semibold">
                    Terms & condition
                </h1>
                <p className="text-sm text-black/50 mt-2">
                    Agreement Between User and <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link>
                </p>

                <p className="my-4">
                    Welcome to <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link>. This website (the "Site") consists of various web pages operated by Damndeal. Your use of <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link> is conditional upon your acceptance of these terms, conditions, and notices outlined here (the "Terms"). By using <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link>, you agree to abide by all such Terms. Please read them carefully and keep a copy for your reference.
                </p>
                <p className="mb-4">
                    <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link>. is an e-commerce platform.
                </p>
                <h1 className="text-xl mb-4">
                    Privacy Policy
                </h1>
                <p className="mb-4">
                    Your interaction with <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link>. is governed by Damndeal's Privacy Policy. We encourage you to review our Privacy Policy, which also applies to the Site and informs users about our data collection practices.
                    is an e-commerce platform.
                </p>
                <h1 className="text-xl mb-4">
                    Electronic Communications
                </h1>
                <p className="mb-4">
                    By visiting <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link> or sending emails to Damndeal, you are engaging in electronic communications. You agree to receive electronic communications and understand that all agreements, notices, disclosures, and other communications we provide to you electronically will satisfy any legal requirement that such communications be in writing.
                </p>
                <h1 className="text-xl mb-4">
                    Your Account
                </h1>
                <p className="mb-4">
                    If you use this Site, you are responsible for maintaining the confidentiality of your account and password and for limiting access to your computer. You agree to take responsibility for all activities that occur under your account or password. You cannot assign or transfer your account to anyone else. You acknowledge that Damndeal is not responsible for third-party access to your account resulting from theft or misappropriation. Damndeal reserves the right to refuse or cancel services, terminate accounts, or remove or edit content at our discretion.
                </p>
                <p className="mb-4">
                    Damndeal does not knowingly collect personal information from individuals under the age of thirteen. If you are under 18, you may only use <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com</Link> with permission from a parent or guardian.
                </p>
                <h1 className="text-xl mb-4">
                    Cancellation/Refund Policy
                </h1>
                <p className="mb-4">
                    You may cancel your subscription at any time. However, cancellations made after 30 days of service will not be eligible for a refund. For any questions, please contact us at <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">refund@damndeal.com.</Link>
                </p>
                <h1 className="text-xl mb-4">
                    Links to Third-Party Sites/Services
                </h1>
                <p className="mb-4">
                    <Link to={`https://damndeal.com/`} className="text-blue-500 hover:underline">www.damndeal.com.</Link> may contain links to other websites ("Linked Sites"). These Linked Sites are not controlled by Damndeal, and we are not responsible for the content of any Linked Site, including any link contained in a Linked Site, or any changes or updates to a Linked Site. We provide these links for your convenience, and the inclusion of any link does not imply endorsement by Damndeal of the site or any association with its operators.
                </p>
                <h2 className="text-2xl font-bold mb-4">Additional Terms and Conditions</h2>

                <h3 className="text-xl font-medium mb-4 mt-6">Third-Party Services</h3>
                <p className="mb-4">
                    Certain services available through <Link to="https://www.damndeal.com" className="text-blue-500 hover:underline">www.damndeal.com</Link> are provided by third-party websites and organizations. By using any products, services, or functionalities originating from the www.damndeal.com domain, you acknowledge and consent to Damndeal sharing information and data with any third party with whom we have a contractual relationship to provide the requested products, services, or functionalities to our users and customers.
                </p>

                <h3 className="text-xl font-medium mb-4 mt-6">Prohibited Uses / Intellectual Property Rights</h3>
                <p className="mb-4">
                    You are granted a non-exclusive, non-transferable, and revocable license to access and use <Link to="https://www.damndeal.com" className="text-blue-500 hover:underline">www.damndeal.com</Link> strictly in accordance with these terms. As a condition of using the Site, you warrant to Damndeal that you will not use the Site for any unlawful or prohibited purposes as defined by these Terms. You may not use the Site in any manner that could damage, disable, overburden, or impair it, or interfere with any other party's use and enjoyment of the Site. Additionally, you may not attempt to obtain any materials or information through means not intentionally made available or provided through the Site.
                </p>
                <p className="mb-4">
                    All content included as part of the Service, including text, graphics, logos, images, and any software used on the Site, is the property of Damndeal or its suppliers and is protected by copyright and other intellectual property laws. You agree to respect all copyright and other proprietary notices, legends, or restrictions contained in such content and will not make any alterations to it.
                </p>
                <p className="mb-4">
                    You may not modify, publish, transmit, reverse engineer, sell, create derivative works from, or exploit any of the content found on the Site in any way. Damndeal content is not for resale. Your use of the Site does not grant you any rights to make unauthorized use of any protected content, nor may you delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for personal use and will not use it for any other purpose without explicit written permission from Damndeal and the copyright owner. You understand that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to Damndeal's intellectual property or that of our licensors, except as expressly authorized by these Terms.
                </p>

                <h3 className="text-xl font-medium mb-4 mt-6">Connecting Third-Party Accounts</h3>
                <p className="mb-4">
                    You may connect your Damndeal account to third-party accounts. By doing so, you consent to the continuous sharing of information about you with others in accordance with your privacy settings on those third-party sites. If you do not wish for your information to be shared in this manner, do not use this feature.
                </p>

                <h3 className="text-xl font-medium mb-4 mt-6">International Users</h3>
                <p className="mb-4">
                    The Service is controlled, operated, and administered by Damndeal from our offices in the USA. If you access the Service from outside the USA, you are responsible for complying with all local laws. You agree not to use Damndeal content accessed through the Site in a manner that violates any local laws.
                </p>
            </div>

            <Footer />
        </>
    )
}
