import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "../ui/select/select";
import { countryDropdownOptions } from "../../../utils/constant";

const CheckoutForm = ({
    shippingIncludes = true,
}) => {
    const { control, formState } = useFormContext();
    const { errors } = formState;
    const formatExpiryDate = (event:
        React.ChangeEvent<HTMLInputElement>
    ) => {
        let input = event.target.value.replace(/\D/g, '').substring(0, 4);
        if (input.length > 2) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        }
        event.target.value = input;
    };

    const [isOtherAddress, setIsOtherAddress] = useState(false);

    return (
        <form className="space-y-6">
            <div className="space-y-4">
                <h2 className="text-lg font-semibold">Billing Details</h2>
                <div className="grid grid-cols-3 gap-4">
                    <div className=" col-span-3 grid grid-cols-2 w-full gap-4">
                        <div>
                            <label className="block font-medium text-gray-700">First Name</label>
                            <Controller
                                control={control}
                                name="firstName"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.firstName ? "!border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.firstName && (
                                <p className="text-red-500 text-sm">{errors?.firstName?.message?.toString()}</p>
                            )}
                        </div>
                        <div>
                            <label className="block font-medium text-gray-700">Last Name</label>
                            <Controller
                                control={control}
                                name="lastName"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.lastName ? "!border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.lastName && (
                                <p className="text-red-500 text-sm">{errors.lastName.message?.toString()}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-span-3">
                        <label className="block font-medium text-gray-700">Company Name</label>
                        <Controller
                            control={control}
                            name="company"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.company ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.company && (
                            <p className="text-red-500 text-sm">{errors.company.message?.toString()}</p>
                        )}
                    </div>
                    <div className="col-span-3">
                        <Controller
                            control={control}
                            name="country"
                            render={({ field }) => (
                                <Select
                                    options={countryDropdownOptions}
                                    placeholder='Select your country'
                                    label='Country *'
                                    selectedOption={field.value}
                                    setSelectedOption={(value) => field.onChange(value)} />
                            )}
                        />
                        {errors.country && (
                            <p className="text-red-500 text-sm">{errors.country.message?.toString()}</p>
                        )}
                    </div>
                    <div className="col-span-3">
                        <label className="block font-medium text-gray-700">Street Address</label>
                        <Controller
                            control={control}
                            name="address1"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.address1 ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.address1 && (
                            <p className="text-red-500 text-sm">{errors.address1.message?.toString()}</p>
                        )}
                    </div>
                    <div className="col-span-3">
                        <label className="block font-medium text-gray-700">Apartment, suite, unit etc. (optional)</label>
                        <Controller
                            control={control}
                            name="address2"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.address2 ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.address2 && (
                            <p className="text-red-500 text-sm">{errors.address2.message?.toString()}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-gray-700">Town / City</label>
                        <Controller
                            control={control}
                            name="city"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.city ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.city && (
                            <p className="text-red-500 text-sm">{errors.city.message?.toString()}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-gray-700">State / County</label>
                        <Controller
                            control={control}
                            name="state"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.state ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.state && (
                            <p className="text-red-500 text-sm">{errors.state.message?.toString()}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-gray-700">Postcode / ZIP</label>
                        <Controller
                            control={control}
                            name="zip"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.zip ? "!border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.zip && (
                            <p className="text-red-500 text-sm">{errors.zip.message?.toString()}</p>
                        )}
                    </div>
                    <div className="col-span-3 grid grid-cols-2 gap-4">
                        <div>
                            <label className="block font-medium text-gray-700">Phone</label>
                            <Controller
                                control={control}
                                name="phone"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.phone ? "!border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.phone && (
                                <p className="text-red-500 text-sm">{errors.phone.message?.toString()}</p>
                            )}
                        </div>
                        <div className="col-span-">
                            <label className="block font-medium text-gray-700">Email Address</label>
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.email ? "!border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.email && (
                                <p className="text-red-500 text-sm">{errors.email.message?.toString()}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="space-y-4">
                    <h2 className="text-lg font-semibold">Payment Information</h2>
                    <label htmlFor="cardNumber" className="block font-medium text-gray-700">
                        Card Number
                        <Controller
                            control={control}
                            name="cardNumber"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="cardNumber"
                                    placeholder="XXXXXXXXXXXXXXXX"
                                    className={`checkout-input mt-2 mb-5 w-full ${errors.cardNumber ? "border-red-500" : "border-gray-300"
                                        }`}
                                />
                            )}
                        />
                        {errors.cardNumber && (
                            <div className="text-red-500 text-sm">{errors.cardNumber.message?.toString()}</div>
                        )}
                    </label>

                    <div className="grid grid-cols-2 gap-4">
                        {/* CVV */}
                        <label htmlFor="cardCode" className="block font-medium text-gray-700">
                            CVV
                            <Controller
                                control={control}
                                name="cardCode"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="cardCode"
                                        className={`checkout-input mt-2 mb-5 w-full ${errors.cardCode ? "border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.cardCode && (
                                <div className="text-red-500 text-sm">{errors.cardCode.message?.toString()}</div>
                            )}
                        </label>

                        {/* Expiry Date */}
                        <label htmlFor="expDate" className="block font-medium text-gray-700">
                            Expiry Date
                            <Controller
                                control={control}
                                name="expDate"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="expDate"
                                        onInput={formatExpiryDate}
                                        className={`checkout-input mt-2 mb-5 w-full ${errors.expDate ? "border-red-500" : "border-gray-300"
                                            }`}
                                    />
                                )}
                            />
                            {errors.expDate && (
                                <div className="text-red-500 text-sm">{errors.expDate.message?.toString()}</div>
                            )}
                        </label>
                    </div>
                </div>
            </div>
            {shippingIncludes && <div className="space-y-4">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="isOtherAddress"
                        checked={isOtherAddress}
                        onChange={(e) => setIsOtherAddress(e.target.checked)}
                        className="mr-2"
                    />
                    <label htmlFor="isOtherAddress" className="block font-medium text-gray-700">
                        Ship to a different address?
                    </label>
                </div>
                {isOtherAddress && (
                    <div className="space-y-4">
                        <h2 className="text-lg font-semibold">Shipping Details</h2>
                        <div className="grid gap-4">
                            <div className="col-span-3 grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block font-medium text-gray-700">First Name</label>
                                    <Controller
                                        control={control}
                                        name="new_shipping_firstname"
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_firstname ? "!border-red-500" : "border-gray-300"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {errors.new_shipping_firstname && (
                                        <p className="text-red-500 text-sm">{errors.new_shipping_firstname.message?.toString()}</p>
                                    )}
                                </div>
                                <div>
                                    <label className="block font-medium text-gray-700">Last Name</label>
                                    <Controller
                                        control={control}
                                        name="new_shipping_lastname"
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_lastname ? "!border-red-500" : "border-gray-300"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {errors.new_shipping_lastname && (
                                        <p className="text-red-500 text-sm">{errors.new_shipping_lastname.message?.toString()}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-span-3">
                                <label className="block font-medium text-gray-700">Country</label>
                                <Controller
                                    control={control}
                                    name="new_shipping_country"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_country ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_country && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_country.message?.toString()}</p>
                                )}
                            </div>
                            <div className="col-span-3">
                                <label className="block font-medium text-gray-700">
                                    Street Address
                                </label>
                                <Controller
                                    control={control}
                                    name="new_shipping_address1"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_address1 ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_address1 && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_address1.message?.toString()}</p>
                                )}
                            </div>
                            <div className="col-span-3">
                                <label className="block font-medium text-gray-700">
                                    Apartment, suite, unit etc. (optional)
                                </label>
                                <Controller
                                    control={control}
                                    name="new_shipping_address2"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_address2 ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_address2 && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_address2.message?.toString()}</p>
                                )}
                            </div>
                            <div>
                                <label className="block font-medium text-gray-700">Town / City</label>
                                <Controller
                                    control={control}
                                    name="new_shipping_city"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_city ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_city && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_city.message?.toString()}</p>
                                )}
                            </div>
                            <div>
                                <label className="block font-medium text-gray-700">
                                    State / County
                                </label>
                                <Controller
                                    control={control}
                                    name="new_shipping_state"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_state ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_state && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_state.message?.toString()}</p>
                                )}
                            </div>
                            <div>
                                <label className="block font-medium text-gray-700">
                                    Postcode / ZIP
                                </label>
                                <Controller
                                    control={control}
                                    name="new_shipping_zip"
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_zip ? "!border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                    )}
                                />
                                {errors.new_shipping_zip && (
                                    <p className="text-red-500 text-sm">{errors.new_shipping_zip.message?.toString()}</p>
                                )}
                            </div>
                            <div className="col-span-3 grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block font-medium text-gray-700">Phone</label>
                                    <Controller
                                        control={control}
                                        name="new_shipping_phone"
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_phone ? "!border-red-500" : "border-gray-300"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {errors.new_shipping_phone && (
                                        <p className="text-red-500 text-sm">{errors.new_shipping_phone.message?.toString()}</p>
                                    )}
                                </div>
                                <div className="">
                                    <label className="block font-medium text-gray-700">
                                        Email Address
                                    </label>
                                    <Controller
                                        control={control}
                                        name="new_shipping_email"
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`checkout-input mt-2 my-5  block w-full p-2 border ${errors.new_shipping_email ? "!border-red-500" : "border-gray-300"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {errors.new_shipping_email && (
                                        <p className="text-red-500 text-sm">{errors.new_shipping_email.message?.toString()}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>}
            <div className="space-y-4">
                <label className="block font-medium text-gray-700">
                    Order Notes (optional)
                </label>
                <Controller
                    control={control}
                    name="orderNotes"
                    render={({ field }) => (
                        <textarea
                            {...field}
                            className="mt-1 block w-full p-2 border border-gray-300"
                            rows={4}
                        />
                    )}
                />
            </div>
        </form >
    );
};

export default CheckoutForm;
