
export default function LowOutOfStockTable() {
    return (
        <>
           <div className="relative overflow-x-auto mt-8 sm:rounded-lg border border-solid border-gray-100">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 captalize bg-gray-50">
                        <tr>
                        <th className="px-6 py-3">
                                
                            </th>
                            <th className="px-6 py-3">
                                ID
                            </th>
                            <th className="px-6 py-3">
                                Image
                            </th>
                            <th className="px-6 py-3">
                                Product
                            </th>
                            <th className="px-6 py-3">
                                Product Type
                            </th>
                            <th className="px-6 py-3">
                            Price/Unit
                            </th>
                            <th className="px-6 py-3">
                                Quantity
                            </th>
                            <th className="px-6 py-3">
                                Status
                            </th>
                            <th className="px-6 py-3">
                                Action
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b hover:bg-gray-50">
                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b hover:bg-gray-50">

                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                <input type="checkbox" name="" id="" />
                            </th>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                               #456AB
                            </td>
                            <td className="px-6 py-4">
                                <img className="w-[45px] h-[45px] rounded-[5px] border border-black/opacity-10" src="https://via.placeholder.com/45x45" alt="DefaultImae" />
                            </td>
                            <td className="px-6 py-4">
                                Ash Double Bed
                            </td>
                            <td className="px-6 py-4">
                                40
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                           
                            <td className="px-6 py-4">
                                400
                            </td>
                            <td className="px-6 py-4 text-red-500">
                               Low Quantity
                            </td>
                            <td className="px-6 text-red-500 py-4">
                                <div className="flex items-center gap-3">
                                    <img loading="lazy" src="/assets/icons/side-bar/edit.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/eye.svg" className="cursor-pointer" alt="Edit" />
                                    <img loading="lazy" src="/assets/icons/side-bar/delete.svg" className="cursor-pointer" alt="Edit" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
