

const menuItems = [
    {
        id: 1,
        title: 'Home & Kitchen',
        path: '/',
    },
    {
        id: 2,
        title: 'Beauty & Health',
        path: '/',
    },
    {
        id: 3,
        title: 'Electronics',
        path: '/',
    },
    {
        id: 4,
        title: 'Jewellery & Accessories',
        path: '/',
    },
    {
        id: 5,
        title: 'Clothings',
        path: '/',
    },
    {
        id: 6,
        title: 'Bags & Footwear',
        path: '/',
    },
    {
        id: 7,
        title: 'Grocery & Market',
        path: '/',
    },
    {
        id: 8,
        title: 'Toys & Video Games',
        path: '/',
    }

];



const electronicCards = [
    {
        id: 1,
        title: '50% Off',
        desc: "On Electronic",
        image: 'https://m.media-amazon.com/images/I/817u84l0zPL._AC_UY180_.jpg',
        bg: "#FFEFD1"
    }
];


export { menuItems, electronicCards };