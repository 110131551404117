import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getPageMeta } from '../utils/data';

type Window = typeof globalThis & {
    dataLayer: any;
};

const HelmetLayout = ({ children }: {
    children: React.ReactNode;
}) => {
    const location = useLocation();

    const getMetaData = () => {
        const path = location.pathname;
        return getPageMeta(path);
    };

    const { title, description, keywords } = getMetaData();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            {children}
        </>
    );
};

export default HelmetLayout;
