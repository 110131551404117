import { MdKeyboardArrowDown } from 'react-icons/md'
import { CiMenuKebab } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import MyDraftTable from '../../components/common/ui/my-draft-table';
export default function ProductManagementPage() {
  return (
    <>
      <div className='w-full bg-white rounded-xl p-8'>
        <div className='flex justify-between items-center pb-8'>
          <h2 className="text-black text-2xl font-medium leading-snug">Product Management</h2>
          <button type='button' className="px-8 py-2 bg-yellow-300 border-none rounded-full shadow-none border justify-center items-center gap-3 flex">
            <IoMdAdd className='text-lg' />
            <Link to={`/vendor-panel/add-product`} className="text-black text-lg font-normal">Add Product</Link>
          </button>
        </div>
        <div className='flex 2xl:flex-row md:flex-row xl:flex-row lg:flex-row flex-col gap-10 justify-between items-center'>
          <div className=" w-full h-[46px] px-[25px] py-[15px] bg-neutral-100 rounded-[5px] border border-black/10 justify-start items-center gap-[418px] inline-flex">
            <input placeholder='Search' className="w-full border-none outline-none bg-transparent h-4 text-neutral-500 text-sm font-normal" />
          </div>
          <div className='flex items-center gap-2'>
            <h2 className="text-black text-base font-medium leading-snug">Filter</h2>
            <MdKeyboardArrowDown className="text-xl" />
          </div>
          <div className='size-7 bg-gray-100 flex justify-center items-center cursor-pointer rounded-full'>
            <CiMenuKebab />
          </div>
        </div>

        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-8 pt-8">
          <div className="w-full relative">
            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Shop</label>
            <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center h-14 gap-2">
                  <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                </div>
                <MdKeyboardArrowDown className="text-xl" />
              </div>
            </div>
          </div>
          <div className="w-full relative">
            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Category</label>
            <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center h-14 gap-2">
                  <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                </div>
                <MdKeyboardArrowDown className="text-xl" />
              </div>
            </div>
          </div>
          <div className="w-full relative">
            <label htmlFor="" className="text-sm absolute -top-2.5 left-5 bg-white font-normal">Filter By Product Type</label>
            <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center h-14 gap-2">
                  <label htmlFor="" className="text-sm bg-white text-neutral-500 font-normal">Choose here</label>
                </div>
                <MdKeyboardArrowDown className="text-xl" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full bg-white rounded-xl p-5 mt-8'>
        <MyDraftTable />
      </div>
    </>
  )
}
