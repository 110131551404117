import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { useGetBusinessesByIdQuery } from '../../../features/slices/vendor-api';
import { defaultTerms } from '../../../utils/constant';
import { AppState } from '../../../redux/store';
import FileInput from '../../../components/shared/files/FileInput';
import { useGetCouponQuery, useUpdateCouponMutation } from '../../../features/slices/api-slices';
import couponSchema from '../../../features/schemas/coupon-schema';
import { useParams, useSearchParams } from 'react-router-dom';
import { useToast } from '../../../components/shared/ToastContext';

export default function EditCouponsPage() {
    const [params] = useSearchParams();


    const by = params.get('by')

    const { user } = useSelector((state: AppState) => state.auth);
    const { id } = useParams();
    const { isLoading: businessloading, data: businesses } = useGetBusinessesByIdQuery(user?._id, {
        skip: !user?._id
    });

    const { isLoading, data } = useGetCouponQuery(id, {
        skip: !id
    });
    const { showToast } = useToast();
    const [update, { isLoading: loadingCoupons, data: dataCouponsLoading, error: dataErrorLoading }] = useUpdateCouponMutation();
    const [isUnlimitedTime, setIsUnlimitedTime] = useState(false);
    const [isUnlimitedLimit, setIsUnlimitedLimit] = useState(false);

    const methods = useForm({
        resolver: zodResolver(couponSchema),
        defaultValues: {
            businessId: '',
            productTitle: '',
            description: '',
            discountType: '',
            discount: '',
            couponLimit: '',
            image: '',
            couponExpiry: '',
            isUnlimitedLimit: false,
            isUnlimitedTime: false,
            terms: defaultTerms,
            isAvailable: true,
        }
    });

    const { handleSubmit, setValue, watch, formState: { errors } } = methods;
    const watchIsUnlimitedLimit = watch('isUnlimitedLimit', isUnlimitedLimit);
    const watchIsUnlimitedTime = watch('isUnlimitedTime', isUnlimitedTime);
    const watchDiscountType = watch('discountType', '');
    const watchDiscount = watch('discount', '');

    useEffect(() => {
        if (!isLoading && data) {
            setValue("businessId", data?.businessId?._id || '');
            setValue("productTitle", data?.productTitle || '');
            setValue("description", data?.description || '');
            setValue("discountType", data?.discountType || '');
            setValue("discount", data?.discount ? data.discount.toString() : '');
            setValue("couponLimit", data?.couponLimit ? data.couponLimit.toString() : '');
            setValue("image", data?.image || '');
            setValue("couponExpiry", data?.couponExpiry || '');
            setValue("terms", data?.terms || defaultTerms);
            setValue("isAvailable", data?.isAvailable || true);
            setValue("isUnlimitedLimit", data?.isUnlimitedLimit || false);
            setValue("isUnlimitedTime", data?.isUnlimited || false);
            if (data?.discountType === 'free') {
                setValue('discount', '');
            }
            if (data?.isUnlimitedLimit) {
                setValue('couponLimit', '');
            }
            if (data?.isUnlimitedTime) {
                setValue('couponExpiry', '');
            }
            setIsUnlimitedTime(data?.isUnlimited || false);
            setIsUnlimitedLimit(data?.isUnlimitedLimit || false);
        }
    }, [data, isLoading, setValue]);

    const onSubmit = async (values: any) => {
        let vendorPermission = by == '1' ? {
            status: 'approved',
            time: Date.now(),
            device: navigator.userAgent,
            ip: '100.000.00'
        } : null

        let data = {
            id,
            body: {
                ...values,
                vendorId: user?._id,
                isUnlimited: isUnlimitedTime,
                isUnlimitedLimit: isUnlimitedLimit,
                discount: values.discountType === 'free' ? '0' : values.discount,
                couponExpiry: values.isUnlimited ? '' : values.couponExpiry,
                couponLimit: values.isUnlimitedLimit ? '' : values.couponLimit,
                vendorPermission: vendorPermission
            }
        };
        update(data);
    };

    useEffect(() => {
        if (dataErrorLoading) {
            showToast("Failed to update coupon", "error", "Failed to update coupon")
        } else if (!loadingCoupons && dataCouponsLoading) {
            showToast('Coupon Updated Successfully', 'success', 'Coupon updated')
        }
    }, [loadingCoupons, dataCouponsLoading, dataErrorLoading]);

    const handleBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);

        reader.onload = () => {
            const base64File = "data:image/jpeg;base64," + (reader.result as string).split(",")[1];
            setValue('image', base64File);
        };
    };

    // const handleCheckboxChange = (field: keyof typeof couponSchema._type) => {
    //     return (e: React.ChangeEvent<HTMLInputElement>) => {
    //         setValue(field, e.target.checked);
    //         if (field === 'isUnlimitedLimit') {
    //             setIsUnlimitedLimit(e.target.checked);
    //             if (e.target.checked) {
    //                 setValue('couponLimit', '');
    //             }
    //         }
    //         if (field === 'isUnlimitedTime') {
    //             setIsUnlimitedTime(e.target.checked);
    //             if (e.target.checked) {
    //                 setValue('couponExpiry', '');
    //             }
    //         }
    //     };
    // };

    console.log(errors);
    console.log(data?.businessId);


    return (
        <FormProvider {...methods}>
            {isLoading ? (
                <div className="flex justify-center items-center h-screen">
                    <div className='w-20 h-20 border-8 border-t-[8px] border-gray-200 rounded-full animate-spin'></div>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='w-full p-8 pt-5 rounded-3xl bg-white'>
                        <h2 className="text-black text-[22px] font-medium leading-snug">Update Coupon</h2>
                        <div className='grid 2xl:grid-cols-3 pt-8 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-y-6 gap-4'>
                            <div className="w-full relative">
                                <label htmlFor="businessId" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.businessId ? "text-red-500" : "text-zinc-800"}`}>Select Business *</label>
                                <select
                                    {...methods.register("businessId")}
                                    className={`w-full rounded p-2 border border-gray-300 h-14 ${errors.businessId ? "border-red-500" : "border-gray-200"}`}
                                >
                                    <option value="" label="Select Business" />
                                    {!businessloading && businesses?.map((business: any) => (
                                        <option key={business._id} value={business._id} label={business.businessName} />
                                    ))}
                                </select>
                                {errors.businessId && <p role="alert" className="text-sm text-red-500 mt-2">{errors.businessId.message}</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="productTitle" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.productTitle ? "text-red-500" : "text-zinc-800"}`}>Product Title *</label>
                                <input
                                    {...methods.register("productTitle")}
                                    type="text"
                                    placeholder='SUMMER20'
                                    className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.productTitle ? "border-red-500" : "border-gray-200"}`}
                                />
                                {errors.productTitle && <p role="alert" className="text-sm text-red-500 mt-2">{errors.productTitle.message}</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="discountType" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.discountType ? "text-red-500" : "text-zinc-800"}`}>Discount Type *</label>
                                <select
                                    {...methods.register("discountType")}
                                    className={`w-full rounded p-2 border border-gray-300 h-14 ${errors.discountType ? "border-red-500" : "border-gray-200"}`}
                                >
                                    <option value="" label="Select discount type" />
                                    <option value="percentage" label="Percentage" />
                                    <option value="fixed" label="Flat" />
                                    <option value="free" label="Free" />
                                </select>
                                {errors.discountType && <p role="alert" className="text-sm text-red-500 mt-2">{errors.discountType.message}</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="discount" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.discount ? "text-red-500" : "text-zinc-800"}`}>Discount *</label>
                                <input
                                    {...methods.register("discount")}
                                    type="number"
                                    placeholder='20'
                                    className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.discount ? "border-red-500" : "border-gray-200"}`}
                                    disabled={watchDiscountType === 'free'}
                                />
                                {errors.discount && <p role="alert" className="text-sm text-red-500 mt-2">{errors.discount.message}</p>}
                                {watchDiscountType === 'percentage' && parseFloat(watchDiscount) > 100 && (
                                    <p role="alert" className="text-sm text-red-500 mt-2">Percentage discount cannot exceed 100%</p>
                                )}
                            </div>
                            <div className="w-full relative col-span-3">
                                <label htmlFor="description" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.description ? "text-red-500" : "text-zinc-800"}`}>Description *</label>
                                <input
                                    {...methods.register("description")}
                                    type="text"
                                    placeholder='20% off summer collection.'
                                    className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.description ? "border-red-500" : "border-gray-200"}`}
                                />
                                {errors.description && <p role="alert" className="text-sm text-red-500 mt-2">{errors.description.message}</p>}
                            </div>
                            <div className="w-full relative col-span-3">
                                <label htmlFor="image" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.image ? "text-red-500" : "text-zinc-800"}`}>Select Image *</label>
                                <FileInput
                                    id="image"
                                    accept="image/*"
                                    onChange={handleBanner}
                                    initialValue={data?.image}
                                />
                                {errors.image && <p role="alert" className="text-sm text-red-500 mt-2">{errors.image.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className='w-full p-8 pt-5 mt-8 rounded-3xl bg-white'>
                        <h2 className="text-black text-[22px] font-medium leading-snug">Usage Restrictions</h2>
                        <div className='grid 2xl:grid-cols-3 pt-8 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-y-6 gap-4'>
                            <div className="w-full relative">
                                <label htmlFor="couponLimit" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.couponLimit ? "text-red-500" : "text-zinc-800"}`}>Coupon Limit</label>
                                <input
                                    {...methods.register("couponLimit")}
                                    type="number"
                                    placeholder='Enter coupon limit'
                                    className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.couponLimit ? "border-red-500" : "border-gray-200"}`}
                                    disabled={watchIsUnlimitedLimit}
                                />
                                {errors.couponLimit && <p role="alert" className="text-sm text-red-500 mt-2">{errors.couponLimit.message}</p>}
                            </div>
                            <div className="w-full relative col-span-3 flex items-center gap-4">
                                <label htmlFor="isUnlimitedLimit" className="text-sm font-normal text-zinc-800">Unlimited Coupon Limit</label>
                                <input
                                    {...methods.register("isUnlimitedLimit")}
                                    type="checkbox"
                                    className="size-4"
                                    onChange={(e) => {
                                        setIsUnlimitedLimit(e.target.checked);
                                        setValue('couponLimit', '');
                                        setValue('isUnlimitedLimit', e.target.checked);
                                    }}
                                />
                            </div>
                            <div className="w-full relative col-span-3 flex items-center gap-4">
                                <label htmlFor="isUnlimitedTime" className="text-sm font-normal text-zinc-800">Unlimited Time</label>
                                <input
                                    {...methods.register("isUnlimitedTime")}
                                    type="checkbox"
                                    className="size-4"
                                    onChange={(e) => {
                                        setIsUnlimitedTime(e.target.checked);
                                        setValue('couponExpiry', '');
                                        setValue('isUnlimitedTime', e.target.checked);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='w-full p-8 pt-5 mt-8 rounded-3xl bg-white'>
                        <h2 className="text-black text-[22px] font-medium leading-snug">Validity Period</h2>
                        <div className='grid 2xl:grid-cols-2 pt-8 xl:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-y-6 gap-4'>
                            <div className="w-full relative">
                                <label htmlFor="couponExpiry" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.couponExpiry ? "text-red-500" : "text-zinc-800"}`}>Expires on</label>
                                <input
                                    {...methods.register("couponExpiry")}
                                    type="date"
                                    placeholder='Coupon expiry date'
                                    className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.couponExpiry ? "border-red-500" : "border-gray-200"}`}
                                    disabled={watchIsUnlimitedTime}
                                />
                                {errors.couponExpiry && <p role="alert" className="text-sm text-red-500 mt-2">{errors.couponExpiry.message}</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="terms" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.terms ? "text-red-500" : "text-zinc-800"}`}>Terms *</label>
                                <textarea
                                    {...methods.register("terms")}
                                    placeholder='Write your terms here...'
                                    className={`w-full border resize-none border-solid rounded-md pt-5 outline-none px-4 ${errors.terms ? "border-red-500" : "border-gray-200"}`}
                                />
                                {errors.terms && <p role="alert" className="text-sm text-red-500 mt-2">{errors.terms.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className='w-full p-8 pt-5 mt-8 rounded-3xl bg-white'>
                        <h2 className="text-black text-[22px] font-medium leading-snug">Activation Setting</h2>
                        <div className="w-full justify-between pt-8 items-center inline-flex">
                            <div className="flex-col justify-start items-start gap-[7px] inline-flex">
                                <div className="text-black text-lg font-normal">Activate Coupon</div>
                                <div className="text-neutral-500 text-lg font-normal">This toggle switch controls whether the coupon is active or inactive</div>
                            </div>
                            <input
                                {...methods.register("isAvailable")}
                                type="checkbox"
                                className="size-4"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end col-span-3 gap-8 pt-6">
                        <button type="submit" className="w-fit h-[46px] text-center text-white text-xl font-medium leading-snug px-10 py-3 bg-zinc-800 rounded justify-center items-center gap-2.5 inline-flex">
                            {loadingCoupons ? 'Saving...' : 'Publish'}
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-transparent text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </form>
            )}
        </FormProvider>
    );
}
