import { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Accordion = (props: any) => {
    const [expanded, setExpanded] = useState(false);

    const handleClick = () => {
        setExpanded((current) => !current);
    };
    return (
        <><div className="w-full flex my-6 justify-between select-none items-center bg-neutral-100 lg:rounded-lg p-5 cursor-pointer" onClick={handleClick}>
            <h3>{props.header}</h3>
            <button className="border-none outline-none">
                {expanded ? <MdKeyboardArrowUp className='text-2xl' /> : <MdKeyboardArrowDown className='text-2xl' />}
            </button>
        </div><div
            className={`bg-white p-5 border border-solid border-gray-200 rounded-md ${expanded === true ? 'block' : 'hidden'}`}
        >
                {props.children}
            </div>
        </>
    );
};

export default Accordion;
