import { BiSolidCopy } from 'react-icons/bi'
// import { FaArrowRight } from 'react-icons/fa6'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment';
import { useGetCouponQuery } from '../../features/slices/api-slices';
import { useEffect } from 'react';
import { useToast } from '../../components/shared/ToastContext';
export default function ViewCouponsPage() {
    const location = useLocation();
    const { id } = useParams();
    const state = location.state as any;
    const { isLoading, data, error } = useGetCouponQuery(id, {
        skip: !id
    });
    console.log(data);
    const { showToast } = useToast();

    useEffect(() => {
        if (error) {
            showToast("An error occurred while loading the coupon", "error", "An error occurred");
        }
    }, [error])



    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(state?.coupon?.code)
        showToast('Coupon code copied to clipboard', "success", "Coupon code copied");
    }
    return (
        <>
            {isLoading ? <p>Loading......</p> : (
                <>
                    <div className='w-full bg-white rounded-3xl p-7'>
                        <h2 className='text-xl font-medium'>
                            Coupon Details
                        </h2>
                        <div className='flex items-center gap-2 pt-5'>
                            <p className='text-base font-medium'>
                                Coupon Code:&nbsp;
                                <span>{data?.businessId?._id}</span>
                            </p>
                            <span className='text-gray-600'></span>
                            <BiSolidCopy className="text-black cursor-pointer" onClick={handleCopyToClipboard} />
                        </div>
                        <div className='flex items-center pt-1'>
                            <span className='text-base font-medium'>Discount: &nbsp;</span>
                            <span className='text-gray-600'>

                                {data?.discountType !== "free" && data?.discount}
                                {data?.discountType === 'percentage' ? '%' : data?.discountType === "free" ? "FREE" : '$'}
                                {data?.discountType !== "free" && <span className="ml-1">Off</span>}
                            </span>
                        </div>
                        <div className='flex items-center pt-1'>
                            <span className='text-base font-medium'>Status: &nbsp;</span>
                            <span className='text-gray-600'>
                                {data?.redeemed ? 'Redeemed' : 'Active'}
                            </span>
                        </div>

                        <div className='flex items-center pt-1'>
                            <span className='text-base font-medium'>Validity: &nbsp;</span>
                            <span className='text-gray-600'>
                                {moment(data?.couponExpiry).format('DD-MM-YYYY')}
                            </span>
                        </div>

                    </div>
                    <div className='grid 2xl:grid-cols-2 mt-8 xl:grid-cols-2 grid-cols-1 gap-10'>
                        <div className='w-full bg-white rounded-2xl p-8'>
                            <h2 className='text-lg font-medium'>
                                Terms & Condition
                            </h2>
                            <p className='mt-3'>
                               {data?.terms?.toString()}
                            </p>
                        </div>
                        <div className='w-full bg-white rounded-2xl p-8'>
                            <h2 className='text-lg font-medium'>
                                Usage Instructions
                            </h2>
                            <p className='mt-3'>
                                Applicable on all products
                            </p>

                            <p className='mt-3'>
                                Cannot be combined with other offers
                            </p>
                        </div>
                        <div className='w-full bg-white rounded-2xl p-8'>
                            <h2 className='text-lg font-medium'>
                                Store Detail
                            </h2>
                            <p className='mt-3'>
                                <b>Store Name:</b> {data?.businessId?.businessName}
                            </p>

                            <p className='mt-3'>
                                <b> Address:</b> &nbsp;
                                {data?.businessId?.address1}&nbsp; {data?.businessId?.address2}
                            </p>
                        </div>
                        <div className='w-full bg-white rounded-2xl p-8'>
                            <h2 className='text-lg font-medium'>
                                Contact Information
                            </h2>
                            <p className='mt-3'>
                                Email: {data?.businessId?.email}
                            </p>

                            <p className='mt-3'>
                                Phone: {data?.businessId?.phone}
                            </p>
                        </div>
                    </div>

                    <div className='w-full p-8 mt-10 bg-white rounded-3xl'>
                        <h2 className='text-lg font-medium'>
                            Share this coupon
                        </h2>
                        <div className='flex items-center gap-6 mt-8'>
                            <img loading="lazy" src="/assets/images/svg/instagram.svg" alt="" className='size-8' />
                            <img loading="lazy" src="/assets/images/svg/twitter.svg" alt="" className='size-8' />
                            <img loading="lazy" src="/assets/images/svg/facebook.svg" alt="" className='size-8' />
                            <div className='flex items-center gap-2 cursor-pointer select-none'>
                                <img loading="lazy" src="/assets/images/svg/copy-link.svg" alt="" />
                                <span>Copy Link</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className='w-full bg-white p-8 mt-10 rounded-3xl'>
                <h2 className='text-lg font-medium'>
                    Related Coupons
                </h2>

                <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-8 mt-4'>
                    <Link to={`/e-discount-details`}>
                        <div className="w-full h-fit bg-red-400 rounded-2xl p-5">
                            <div className='flex justify-between items-center'>
                                <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                                <span className="text-white text-base font-normal">Status: Active</span>
                            </div>
                            <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                            <div className='flex justify-between items-center pt-2'>
                                <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                                <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                    <FaArrowRight />
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="w-full h-fit bg-[#62B3C6] rounded-2xl p-5">
                        <div className='flex justify-between items-center'>
                            <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                            <span className="text-white text-base font-normal">Status: Active</span>
                        </div>
                        <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                        <div className='flex justify-between items-center pt-2'>
                            <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                            <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                <FaArrowRight />
                            </span>
                        </div>
                    </div>
                    <div className="w-full h-fit bg-amber-500 rounded-xl p-5">
                        <div className='flex justify-between items-center'>
                            <span className="text-white text-base font-normal flex items-center gap-2">Code: 4567764 <BiSolidCopy className="text-white cursor-pointer" /></span>
                            <span className="text-white text-base font-normal">Status: Active</span>
                        </div>
                        <div className="w-[211px] text-zinc-800 text-2xl font-medium mt-3">20% off on all products</div>
                        <div className='flex justify-between items-center pt-2'>
                            <span className="text-white text-base font-normal">Expire on: 24-05-2024</span>

                            <span className="text-white text-base font-normal flex items-center gap-2 cursor-pointer">View Details
                                <FaArrowRight />
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
                </>
            )}

        </>
    )
}
