
export const blogs = [
    {
        id: 1,
        title: "Conscious Consumerism: Embracing Sustainability with DamnDeal",
        body: (
            <>
                <p className="text-sm poppins-regular mb-4">
                    In recent years, the global movement towards sustainability has gained significant momentum, reshaping the way we approach shopping and consumption. As eco-conscious consumers seek to reduce their environmental footprint, they are turning to brands and products that promote sustainability. At DamnDeal, we’re committed to supporting this by offering a curated selection of sustainable products that allow you to shop with style and conscience.
                </p>
                <h4 className="text-lg poppins-regular font-medium">
                    Why Sustainable Shopping Matters
                </h4>
                <p className="text-sm poppins-regular my-4">
                    The impact of our purchasing decisions extends far beyond our individual lives. By opting for sustainably sourced and ethically produced goods, we can collectively promote conservation, social responsibility, and fair labor practices. From eco-friendly fashion to zero-waste home essentials, we have the power to drive positive change.
                </p>
                <h4 className="text-lg poppins-regular font-medium">
                    Discover Sustainable DamnDeal
                </h4>
                <p className="text-sm poppins-regular my-4">
                    At DamnDeal, we understand that making sustainable choices should be easy and convenient. Our collection showcases a range of eco-friendly products that align with your values and lifestyle. Whether you’re interested in organic cotton apparel, reusable kitchenware, or energy solutions, we’ve got you covered.
                </p>
                <h4 className="text-lg poppins-regular font-medium">
                    Tips for Eco-Friendly Shopping
                </h4>
                <p className="text-sm poppins-regular my-4">
                    Making eco-friendly choices doesn’t have to be overwhelming. Here are a few tips to help you shop more sustainably:
                </p>
                <ul className="mt-4 flex flex-col gap-4 list-decimal">
                    <li className="text-sm poppins-regular">
                        <strong> Prioritize quality over quantity:</strong>
                        Invest in products that are built to last, reducing the need for replacements.
                    </li>
                    <li className="text-sm poppins-regular">
                        <strong>Choose reusable alternatives:</strong>
                        Opt for reusable shopping bags, water bottles, and containers to minimize single-use plastic waste.
                    </li>
                    <li className="text-sm poppins-regular">
                        <strong>Support eco-conscious brands: </strong>
                        Look for certifications such as Fair Trade, Organic, and Forest Stewardship Council (FSC) for products.
                    </li>
                    <li className="text-sm poppins-regular">
                        <strong>Consider your carbon footprint:</strong>
                        Think about the impact of shipping when making purchases, and choose local options when possible.
                    </li>
                </ul>
                <p className="text-sm poppins-regular my-4">
                    By incorporating these tips into your routine, you can make a meaningful difference for the planet and future generations.
                </p>
            </>
        ),
        image: "First.png",
    },
    {
        id: 2,
        title: "A Journey into the World of Organic Food: The Damndeal Experience",
        body: (
            <>
                <div style={{ margin: '20px 0', lineHeight: '1.15', fontSize: '15px' }}>
                    <p className="text-sm poppins-regular">
                        As the seasons change, so do the trends in the world. With spring in full bloom, it's time to refresh your wardrobe and embrace the latest styles that are making waves on the runway and beyond. At DamnDeal, we’re here to stay ahead of the curve with our collection of trendy clothing that will take your spring wardrobe to new heights.
                    </p>

                    <p className="text-lg my-4 poppins-regular">
                        <strong>The Hottest Trends of the Season</strong>
                    </p>

                    <p className="text-sm poppins-regular my-4">
                        From vibrant hues to nostalgic throwbacks, this spring's trends are all about making a statement. Here are some of the top trends to keep an eye on:
                    </p>

                    <ul className="flex flex-col gap-4 list-decimal poppins-regular">
                        <li>
                            <strong>Power Pastels:</strong> Soft pastel shades like lavender, mint green, and baby blue are ruling the color palette this season, bringing a fresh and feminine vibe to your wardrobe.
                        </li>
                        <li>
                            <strong>Statement Sleeves:</strong> Puffy sleeves, cuffs, and voluminous blouses, dresses, and outerwear are must-have pieces for the fashion-forward.
                        </li>
                        <li>
                            <strong>Sustainable Style:</strong> With eco-consciousness on the rise, fashion is becoming more popular for pieces made from organic cotton and sustainably sourced fabrics to make an eco-friendly statement.
                        </li>
                        <li>
                            <strong>Modern Minimalism:</strong> Clean lines, neutral tones, and timeless silhouettes are at the center of this minimalist trend, offering effortless sophistication for any occasion.
                        </li>
                        <li>
                            <strong>Bold Prints:</strong> Whether it’s floral motifs, animal prints, or geometric patterns, bold prints are making a big splash this season, injecting personality into your outfits.
                        </li>
                    </ul>

                    <p className="text-lg my-4 poppins-regular">
                        <strong>Elevate Your Style with DamnDeal</strong>
                    </p>

                    <p className="text-sm poppins-regular mb-4">
                        At DamnDeal, we’ve curated a selection of on-trend clothing and accessories that will help you effortlessly embody the spirit of the season. From maxi dresses to statement-making accessories, our collection has everything you need to express your style with confidence.
                    </p>

                    <p className="text-sm poppins-regular my-4">
                        <strong>Styling Spring</strong>
                    </p>

                    <p className="text-sm poppins-regular mb-4">
                        Ready to revamp your wardrobe? Here are some styling tips for the trends:
                    </p>

                    <ul className="flex flex-col gap-4 list-decimal text-sm poppins-regular">
                        <li>
                            <strong>Mix and Match:</strong> Experiment with different textures, colors, and patterns to create dynamic and eye-catching outfits.
                        </li>
                        <li>
                            <strong>Play with Proportions:</strong> Pair oversized pieces with fitted styles for a flattering and fashionable look.
                        </li>
                        <li>
                            <strong>Accessorize Wisely:</strong> Elevate your outfits with statement jewelry, bold handbags, and accessories that reflect your personality.
                        </li>
                        <li>
                            <strong>Embrace Versatility:</strong> Invest in pieces that can be dressed up or down to suit any occasion, giving maximum mileage to your wardrobe staples.
                        </li>
                    </ul>

                    <p className="text-sm poppins-regular my-4 poppins-regular">
                        <strong>Shop Spring Trends with DamnDeal</strong>
                    </p>

                    <p className="text-sm poppins-regular">
                        Ready to update your wardrobe for spring? Explore our latest collection of fashion-forward pieces and discover your new obsessions. At DamnDeal, staying on-trend has never been easier or more affordable. Happy shopping!
                    </p>
                </div>
            </>
        ),
        image: "Second.png",
    },

    {
        id: 3,
        title: "Elevate Your Wellness: Discover Health Essentials with DamnDeal",
        body: (
            <>
                <div className="p-4 text-sm poppins-regular">
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        In today's fast-paced world, prioritizing our health and well-being has become more important. Whether you're looking to kickstart a new fitness routine, improve your nutrition, or simply adopt healthier habits, DamnDeal has everything you need to embark on your wellness journey with confidence. From gear and nutritional supplements to self-care essentials, we're here to support you every step of the way.
                    </p>
                    <p className="mb-4 leading-[115%] text-lg poppins-regular">
                        <strong>Invest in Your Health</strong>
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        Your health is your greatest asset, and investing in it is a decision that pays dividends for a lifetime. With DamnDeal, you can access a wide range of wellness products to help you look good, feel great, and live your best life. From high-quality gym equipment to natural personal care items, our collection has something for everyone.
                    </p>
                    <p className="mb-4 leading-[115%] text-lg poppins-regular">
                        <strong>Discover Your Wellness Essentials</strong>
                    </p>
                    <p className="mb-4 leading-[115%] poppins-regular text-[15px]">
                        Whether you're a seasoned fitness enthusiast or just starting your wellness journey, DamnDeal has you covered. Check out our selection of wellness products, including:
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Gear:</strong> From yoga mats and resistance bands to dumbbells and cardio equipment, we have everything you need to create a gym that fits your lifestyle.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Nutritional Supplements:</strong> Enhance your diet with vitamins, minerals, and nutrients that support overall health, immunity, and vitality.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Self-Care Essentials:</strong> Treat yourself to luxurious skincare products, aromatherapy diffusers, and aids that promote stress relief and well-being.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Lifestyle Accessories:</strong> Stay hydrated with reusable water bottles, track your fitness goals with activity trackers, and prioritize sleep with comfortable bedding and sleep aids.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Tips for a Balanced Lifestyle</strong>
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        Achieving a balanced lifestyle is key to long-term wellness. Here are some tips to help you get started:
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Prioritize Movement:</strong> Incorporate regular physical activity into your routine, whether it’s a brisk walk, yoga session, or strength training workout.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Nourish Your Body:</strong> Fuel your body with nutritious foods, vitamins, minerals, and antioxidants that support overall health.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Practice Mindfulness:</strong> Take time each day to quiet your mind through meditation, deep breathing exercises, and engage in activities that bring you joy and relaxation.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Stay Consistent:</strong> Consistency is key when it comes to forming healthy habits. Set realistic goals, establish routines, and stay committed to your health journey.
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        <strong>Join the DamnDeal Community</strong>
                    </p>
                    <p className="mb-4 leading-[115%] text-[15px] poppins-regular">
                        Ready to prioritize your health and well-being? Explore our collection of wellness products and take the first step towards a healthier, happier you. With us as your partner, your wellness goals will be more rewarding. Here’s to your health!
                    </p>
                </div>

            </>
        ),
        image: "Third.png",
    },

    {
        id: 4,
        title: "Discover Your Style: Embrace the Evolution of Fashion with DamnDeal",
        body: (
            <>
                <p className="text-base mb-4">
                    For food and home cooks alike, the kitchen is more than just a room—it’s a space for creativity, experimentation, and exploration. Whether you’re a seasoned chef or just starting to discover the joys of cooking, DamnDeal offers a treasure trove of kitchen and cooking essentials that will elevate your culinary experience to the next level. From time-saving gadgets to specialty utensils and ingredients, we have everything you need to unleash your inner chef and impress your taste buds.
                </p>

                <p className="text-base mb-4">
                    <strong>Upgrade Your Kitchen Arsenal</strong>
                </p>

                <p className="text-base mb-4">
                    Cooking is both an art and a science; having the right tools can make all the difference. At DamnDeal, we’ve curated a selection of kitchen gadgets and accessories that combine innovation and practicality to streamline your cooking process and elevate your dishes. From cutting-edge appliances to classic essentials, we have something for every cooking enthusiast.
                </p>

                <p className="text-base mb-4">
                    <strong>Discover Innovations</strong>
                </p>

                <p className="text-base mb-4">
                    Ready to enhance your cooking experience? Explore our collection of kitchen gadgets and discover a world of culinary possibilities, including:
                </p>

                <p className="text-base mb-4">
                    <strong>Time-Saving Appliances:</strong> Meal prep with multifunctional kitchen appliances like air fryers, pressure cookers, and food processors that make cooking faster, easier, and more convenient.
                </p>

                <p className="text-base mb-4">
                    <strong>Specialty Utensils:</strong> Elevate your cooking with specialty utensils and tools for specific tasks, such as spiralizers, mandolines, and immersion blenders.
                </p>

                <p className="text-base mb-4">
                    <strong>High-Quality Ingredients:</strong> Take your recipes to the next level with high-quality ingredients and pantry staples that add depth of flavor and authenticity to your dishes.
                </p>

                <p className="text-base mb-4">
                    <strong>Organizational Solutions:</strong> Keep your kitchen organized and clutter-free with storage containers, spice racks, and drawer organizers that promote space efficiency.
                </p>
                <p className="text-base mb-4">
                    <strong>Tips for Culinary Success</strong>
                </p>

                <p className="text-base mb-4">
                    Cooking is an adventure, and every meal is an opportunity to explore new flavors, techniques, and cuisines. Here are some tips to help you make the most of your culinary endeavors:
                </p>

                <p className="text-base mb-4">
                    Experiment Fearlessly: Don’t be afraid to get in the kitchen and try new recipes, ingredients, and methods.
                </p>

                <p className="text-base mb-4">
                    Master the Basics: Focus on mastering fundamental cooking techniques, such as chopping, sautéing, and roasting, to build a solid foundation for your culinary skills.
                </p>

                <p className="text-base mb-4">
                    Follow Your Taste Buds: Trust your instincts and taste as you go, adjusting seasonings and flavors to your preferences to create dishes that truly satisfy your palate.
                </p>

                <p className="text-base mb-4">
                    Share the Love: Food has a unique ability to bring people together. Share your culinary creations with friends and family, and enjoy the joy and connection that comes from sharing a meal.
                </p>

            </>
        ),
        image: "fourth.png",
    }
]

export const BANNERS = {
    HOME: {
        MAGIC_CLUB: {
            key: "MAGIC_CLUB",
            title: "Join the Magic Club",
            description: "Get exclusive deals, discounts, and rewards when you join the Magic Club.",
            bannerUrl: "https://res.cloudinary.com/dgnve8m5k/image/upload/v1724389400/Advertise_your_business_with_us_totally_free-02_mxc1ed.jpg",
            link: "/magic-club",
        },
        OUR_EBOOKS: {
            key: 'OUR_EBOOKS',
            title: "Get Our E discount coupon books",
            description: "Get our E discount coupon books and save more on your favorite brands.",
            bannerUrl: "https://res.cloudinary.com/dgnve8m5k/image/upload/v1724389397/Advertise_your_business_with_us_totally_free-03_dnrkir.jpg",
            link: "/buy-ebooks",
        },
    }
}

export const META_DATA = {
    HOME: {
        title: "Buy Deals on Electronics, Clothing, Health, and More - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    MAGIC_CLUB: {
        title: "Join the Magic Club - Exclusive Deals, Discounts, and Rewards",
        description: "Unlock exclusive deals, discounts, and rewards when you join the Magic Club. Save more on your favorite brands and products.",
        keywords: "Magic Club, exclusive deals, discounts, rewards, favorite brands, products",
    },
    OUR_EBOOKS: {
        title: "Get Our E Discount Coupon Books - Save More on Your Favorite Brands",
        description: "Get our E discount coupon books and save more on your favorite brands. Shop smarter and save more with DamnDeal.",
        keywords: "E discount coupon books, save more, favorite brands, shop smarter",
    },
    SHOP: {
        title: "Best Online Deals on Smartphones, Skincare, Baby Products, and More - DamnDeal",
        description: "Shop at DamnDeal for unbeatable online discounts on smartphones, laptops, women's and men's fashion, skincare, baby products, kitchen gadgets, furniture, and more.",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    PRODUCT_DETAIL: {
        title: "Product Detail - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    CATEGORY_DETAILS: {
        title: "Category Details - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    BLOG: {
        title: "Blog - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    BLOG_DETAIL: {
        title: "Blog Detail - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    AUTH: {
        title: "Auth - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    CART: {
        title: "Cart - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    CHECKOUT: {
        title: "Checkout - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    },
    WISHLIST: {
        title: "Wishlist - DamnDeal",
        description: "Discover the best deals and discounts on electronics, fashion, home essentials, and more at Damndeal. Shop now for exclusive offers and unbeatable prices!",
        keywords: "sustainable living, eco-friendly products, ethical brands, conscious living essentials, greener future",
    }
}

export const getPageMeta = (path: string) => {
    let page = path.split('/')[1];
    if (page.includes('shop')) return META_DATA.SHOP;
    if (page.includes('product')) return META_DATA.PRODUCT_DETAIL;
    if (page.includes('category')) return META_DATA.CATEGORY_DETAILS;
    if (page.includes('blog')) return META_DATA.BLOG;
    if (page.includes('blog-detail')) return META_DATA.BLOG_DETAIL;
    if (page.includes('magic')) return META_DATA.MAGIC_CLUB;
    if (page.includes('sign') || page.includes('login')) return META_DATA.AUTH;
    if (page.includes('ebooks') || page.includes('coupon')) return META_DATA.OUR_EBOOKS;
    if (page.includes('cart')) return META_DATA.CART;
    if (page.includes('checkout')) return META_DATA.CHECKOUT;
    if (page.includes('wishlist')) return META_DATA.WISHLIST;
    return META_DATA.HOME;
}