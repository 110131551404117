import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
    user: any;
    cartLength: number;
    currency: string;
}

const initialState: AuthState = {
    token: null,
    user: null,
    cartLength: 0,
    currency: 'USD',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload;
        },
        setCartLength(state, action: PayloadAction<number>) {
            state.cartLength = action.payload;
        },
        logout(state) {
            state.token = null;
            state.user = null;
            state.cartLength = 0;
        },
        changeCurrency(state, action: PayloadAction<string>) {
            state.currency = action.payload;
        }
    },
});

export const { setToken, setUser , logout, changeCurrency } = authSlice.actions;
export default authSlice.reducer;
