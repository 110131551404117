// src/CustomCheckbox.js

const Checkbox = ({ id, label, checked, onChange }: any) => {
    return (
        <label className="flex items-center space-x-2 cursor-pointer">
            <input
                id={id}
                type="checkbox"
                checked={checked}
                onChange={() => onChange(id)} // Fix: Use an arrow function to pass the correct id
                className="sr-only" // Hide the default checkbox
            />
            <span className={`size-5 flex items-center !ml-0 justify-center border-2 rounded ${checked ? 'bg-primary border-primary' : 'bg-transparent border-gray-300'} transition-colors duration-300`}>
                {checked && (
                    <svg
                        className="w-3 h-3 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        />
                    </svg>
                )}
            </span>
            <span className="text-gray-700 text-base">{label}</span>
        </label>
    );
};

export default Checkbox;
