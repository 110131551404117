const { commonApi } = require("../../redux/common-api");

export const vendorApi = commonApi.injectEndpoints({
    endpoints: (builder: any) => ({
        createBusiness: builder.mutation({
            query: (body: any) => ({
                url: `vendor-bussiness`,
                method: "POST",
                body,
            }),
            invalidatesTags: ['vendor-business']
        }),
        getBusinesses: builder.query({
            query: () => `vendor-bussiness`,
            providesTags: ['vendor-business']
        }),
        getBusinessById: builder.query({
            query: (id: any) => `vendor-bussiness/detail/${id}`,
            providesTags: ['vendor-business']
        }),
        getBusinessesById: builder.query({
            query: (userId: any) => `vendor-bussiness/${userId}`,
            providesTags: ['vendor-business']
        }),
        updateBusiness: builder.mutation({
            query: ({ id, body }: any) => ({
                url: `vendor-bussiness/${id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ['vendor-business']
        }),
        deleteBusiness: builder.mutation({
            query: (id: any) => ({
                url: `vendor-bussiness/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['vendor-business']
        }),
    }),
})

export const {
    useCreateBusinessMutation,
    useGetBusinessesQuery,
    useGetBusinessesByIdQuery,
    useUpdateBusinessMutation,
    useDeleteBusinessMutation,
    useGetBusinessByIdQuery
} = vendorApi;
