import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Coupon({
    couponName,
    amount,
    subHeading,
    callToAction,
    heading,
}: {
    couponName: string;
    amount: number;
    subHeading: string;
    callToAction: string;
    heading: string;
}) {
    return (
        <div className="bg-white rounded-3xl col-span-1 md:col-span-2 grid md:grid-cols-2 p-8 items-center justify-center">
            <div className="">
                <h2 className="text-lg font-medium mb-2">{couponName}</h2>
                <h3 className="mb-1 text-sm font-medium text-neutral-500">
                    {subHeading}
                </h3>
                <h3 className="text-3xl font-bold mb-6">
                    ${amount?.toString()}
                </h3>
                <div className="flex gap-2">
                    <Link to="" className="flex gap-2">
                        <div className="bg-primary rounded-full w-7 h-7 flex hover:scale-110 duration-300">
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="m-auto"
                            />
                        </div>

                        <p className="my-auto font-medium text-neutral-500">
                            {callToAction}
                        </p>
                    </Link>
                </div>
            </div>
            <div className="mt-5 md:mt-0">
                <h3 className="text-xl md:text-3xl font-bold">{heading}</h3>
            </div>
        </div>
    );
}
