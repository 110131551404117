import Dashboad from './pages/user-pannel/dashboard';
import { Route, Routes } from 'react-router-dom';
import UserPannelProfilePage from './pages/user-pannel/profile';
import { AdminDashboardLayout, DashboardLayout, InfluencerDashboardLayout, VendorDashboardLayout } from './layouts/layout';
import MyCartPage from './pages/user-pannel/my-cart';
import ProfileSettingsPage from './pages/user-pannel/profile-settings';
import ChangePasswordPage from './pages/user-pannel/change-password';
import MyOrderPage from './pages/user-pannel/my-orders';
import MusicClubPage from './pages/user-pannel/music-club';
import EDiscountCoupon from './pages/user-pannel/e-discount-coupon';
import MyWhitelistPage from './pages/user-pannel/my-white-list';
import MyQuestionPage from './pages/user-pannel/my-question';
import NeedHelpPage from './pages/user-pannel/need-help';
import NotFound from './pages/not-found';
import LoginPage from './pages/auth/login-page';
import SignupPage from './pages/auth/signup-page';
import VendorDashboard from './pages/vendor-panel/dashboard';
import EDiscountDetailsPage from './pages/user-pannel/e-discount-details';
import VendorProfilePage from './pages/vendor-panel/profile';
import VendorCouponsPage from './pages/vendor-panel/coupons';
import CreateNewCouponsPage from './pages/vendor-panel/create/create-new-coupons';
import Inventory from './pages/vendor-panel/inventory';
import OrderPage from './pages/vendor-panel/order';
import RefundPage from './pages/vendor-panel/refund';
import FaqsPage from './pages/vendor-panel/faqs';
import AdminDashboardPage from './pages/admin-pannel/dashboard';
import AdminProfilePage from './pages/admin-pannel/admin-profile';
import VendorShopManagmentPage from './pages/vendor-panel/shop-management';
import CreateNewShopPage from './pages/vendor-panel/create-new-shop';
import ViewShopsPage from './pages/vendor-panel/view-shops';
import ProductManagementPage from './pages/vendor-panel/product-management';
import AddProductPage from './pages/vendor-panel/add-product';
import MyDraftPage from './pages/vendor-panel/my-draft';
import LowOutOfSock from './pages/vendor-panel/low-out-of-stock';
import Checkout from './pages/normal-user/checkout-page';
import EbookCheckout from './pages/normal-user/ebook/checkout-page';
import MyOrdersPage from './pages/user-pannel/my-orders-page';
import VendorProfileSettingsPage from './pages/vendor-panel/profile-settings';
import VendorRegisterationPage from './pages/auth/vendor-register-page';
import EditShopManagementForm from './pages/vendor-panel/edit/edit-shop-management';
import EditCouponsPage from './pages/vendor-panel/create/edit-coupons';
import ViewCouponsPage from './pages/vendor-panel/view-coupons';
import CommingSoonPage from './pages/comming-soon';
import ForgotPassword from './pages/auth/forget-password';
import ResetPasswordPage from './pages/auth/reset/[token]/reset-password';
import ActivationPage from './pages/auth/activate/[token]/activate';
import InfluencerProfilePage from './pages/influencer-pannel/profile';
import PrivacyPolicyPage from './pages/privacy-policy/privacy-policy';
import TermsAndConditionsPage from './pages/terms-and-conditions/terms-and-conditions';
import SiteMapPage from './pages/site-map/site-map';
import BlogDetailsPage from './pages/blog-details/blog-details';
import HomePageMain from './pages/home-page/home-page';
import BuyCoponsPage from './pages/normal-user/buy-coupons-page/buy-coupons-page';
import ShopSmartPage from './pages/normal-user/shop-smart-page/shop-smart-page';
import UnlockExclusivePage from './pages/normal-user/unlock-exclusive/unclock-exclusive';
import CategoryDetailsPage from './pages/category-details/category-details';
import CartsPage from './pages/cart/cart-page';
import WishlistPage from './pages/wishlist/wishlist-page';
import MyWalletPage from './pages/my-wallet/my-wallet-page';
import AllCouponsPage from './pages/all-coupons/all-coupons-page';
import BuyEbboksPage from './pages/normal-user/buy-coupons/buy-coupons';
import BusinessProfileDetailsPage from './pages/normal-user/profile-details/profile-details';
import NGOProfilePage from './pages/normal-user/ngo-profile/ngo-profile';
import ProductDetailPage from './pages/product-details/product-details';
import MyRefundsPage from './pages/user-pannel/my-refunds';
import HelmetLayout from './layouts/HelmetLayout';

import './App.css';

export default function Layout() {

  return (
    <HelmetLayout>
      <Routes>
        <Route path='/' element={<HomePageMain />} />
        <Route path='/auth/signin' element={<LoginPage />} />
        <Route path='/auth/signup' element={<SignupPage />} />
        <Route path='/auth/forgot-password' element={<ForgotPassword />} />
        <Route path='/vendor/register' element={<VendorRegisterationPage />} />
        <Route path='/home-page' element={<HomePageMain />} />
        {/* <Route path='/product-detail/:id' element={<ProductDetailsPage />} /> */}
        <Route path='/user/verify/:token' element={<ActivationPage />} />
        <Route path='/user/reset/:token' element={<ResetPasswordPage />} />
        <Route path='/user/activate/:token' element={<ActivationPage />} />
        <Route path='/my-wallet' element={<MyWalletPage />} />
        {/* ======= unAuthenticated login user redirect these 2 pages ======= */}
        <Route path='/cart' element={<CartsPage />} />
        <Route path='/wishlist' element={<WishlistPage />} />
        {/* <Route path='' element={<AuthLayout />}> */} {/** TODO */}
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/ebook/checkout' element={<EbookCheckout />} />
        {/* </Route> */}
        <Route path='/shop' element={<CategoryDetailsPage />} />
        <Route path='/product-details/:id' element={<ProductDetailPage />} />
        {/* <Route path='/shop' element={<ShopPage />} /> */}
        <Route path='/blogs/:id' element={<BlogDetailsPage />} />
        <Route path='/buy-coupons' element={<BuyCoponsPage />} />
        <Route path='/business-profile/:id' element={<BusinessProfileDetailsPage />} />
        <Route path='/shop-smart' element={<ShopSmartPage />} />
        <Route path='/magic-club' element={<UnlockExclusivePage />} />
        <Route path='/all-coupons' element={<AllCouponsPage />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/buy-ebooks' element={<BuyEbboksPage />} />
        <Route path="/ngo-profile" element={<NGOProfilePage />} />
        {/* ========== user panel routes define here ======== */}
        <Route path="/user-panel" element={<DashboardLayout />}>
          <Route path='dashboard' element={<Dashboad />} />
          <Route path='profile' element={<UserPannelProfilePage />} />
          <Route path='edit-profile' element={<ProfileSettingsPage />} />
          <Route path='change-password' element={<ChangePasswordPage />} />
          <Route path='my-orders' element={<MyOrdersPage />} />
          <Route path='orders-details' element={<MyOrderPage />} />
          <Route path='magic-club' element={<MusicClubPage />} />
          <Route path='ebook/all-coupons' element={<AllCouponsPage />} />
          <Route path='discount-and-directory' element={<EDiscountCoupon />} />
          <Route path='e-discount-&-directory-details' element={<EDiscountDetailsPage />} />
          <Route path='wishlist' element={<MyWhitelistPage />} />
          <Route path='my-cart' element={<MyCartPage />} />
          <Route path='my-refunds' element={<MyRefundsPage />} />
          <Route path='my-question' element={<MyQuestionPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path='need-help' element={<NeedHelpPage />} />
        </Route>
        {/* ========== vendor panel routes define here ======== */}
        <Route path="/vendor-panel" element={<VendorDashboardLayout />}>
          <Route path='dashboard' element={<VendorDashboard />} />
          <Route path='e-discount-details' element={<EDiscountDetailsPage />} />
          <Route path='profile' element={<VendorProfilePage />} />
          <Route path='edit-vendor-profile' element={<VendorProfileSettingsPage />} />
          <Route path='business-management' element={<VendorShopManagmentPage />} />
          <Route path='edit-business-management/:id' element={<EditShopManagementForm />} />
          <Route path='add-new-business' element={<CreateNewShopPage />} />
          <Route path='view-shop/:id' element={<ViewShopsPage />} />
          <Route path='all-product' element={<ProductManagementPage />} />
          <Route path='add-product' element={<AddProductPage />} />
          <Route path='my-draft' element={<MyDraftPage />} />
          <Route path='all-low-and-out-of-stock-product' element={<LowOutOfSock />} />
          <Route path='coupons' element={<VendorCouponsPage />} />
          <Route path='create-coupons' element={<CreateNewCouponsPage />} />
          <Route path='edit-coupons/:id' element={<EditCouponsPage />} />
          <Route path='view-coupons/:id' element={<ViewCouponsPage />} />
          <Route path='inventory' element={<Inventory />} />
          <Route path='orders' element={<OrderPage />} />
          <Route path='refund' element={<RefundPage />} />
          <Route path='faqs' element={<FaqsPage />} />
          <Route path='coming-soon' element={<CommingSoonPage />} />
          <Route path='*' element={<NotFound />} />

        </Route>
        <Route path="/influencer-panel" element={<InfluencerDashboardLayout />}>
          <Route path='' element={<InfluencerProfilePage />} />
          <Route path='edit-profile' element={<ProfileSettingsPage />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        {/* ========== Admin Panel dashboard Routes Define Here...! ============ */}
        <Route path="/admin-panel" element={<AdminDashboardLayout />}>
          <Route path='dashboard' element={<AdminDashboardPage />} />
          <Route path='profile' element={<AdminProfilePage />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='/coming-soon' element={<CommingSoonPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
        <Route path='/site-map' element={<SiteMapPage />} />
      </Routes>

    </HelmetLayout>
  )
}