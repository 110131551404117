import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { orderFormInitialValues, orderFormSchema } from '../../../features/schemas/order-schema';
import CheckoutForm from '../../../components/common/checkout/checkout-form';
import OrderDetails from '../../../components/common/checkout/ebook-order-details';
import CheckoutConfirmButton from '../../../components/common/checkout/ebook-checkout-button';
import { Navbar } from '../../../components/shared/navbar';


const EbookCheckout: React.FC = () => {
  const methods = useForm({
    defaultValues: orderFormInitialValues({}, 0),
    resolver: zodResolver(orderFormSchema),
  });


  return (
    <div className="w-full">
      <Navbar />
      <div className="w-full flex justify-center items-center p-5">
        <div className="page-container pb-10 mt-2 flex flex-col gap-8">
          <h1 className="text-center text-3xl font-bold my-2 md:my-10">Checkout</h1>
          <FormProvider {...methods}>
            <div className="grid grid-cols-12 gap-5 md:gap-20">
              <div className="col-span-12 md:col-span-8">
                <CheckoutForm
                  shippingIncludes={false}
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-5">
                <OrderDetails total={10} />
                <CheckoutConfirmButton subtotal={10} />
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default EbookCheckout;
