import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <>
        <main className='w-full min-h-screen flex justify-center items-center'>
            <div className='flex flex-col items-center gap-4 w-full'>
                <img loading="lazy" src="/assets/images/404.png" alt="404 Page" className='w-64' />
                <h1 className='text-5xl font-bold text-center'>This page could not be  found!</h1>
                <p className='text-center text-gray-500'>The page you're looking for may have been removed, had its name changed, or is temporarily unavailable.</p>
                <Link to={`/`} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm'>Back to Home</Link>
            </div>
        </main>
    </>
  )
}
