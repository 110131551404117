
export default function CommingSoonPage() {
    return (
        <>
            <main className='flex justify-center items-center min-h-screen'>
                <div className="flex flex-col items-center gap-6">
                    <h1 className='text-6xl font-bold text-center'>Coming Soon!</h1>
                    <p className='text-center text-zinc-800 capitalize font-medium text-2xl'>Our new website is on its way.                    </p>
                </div>
            </main>
        </>
    )  
}
