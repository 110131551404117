import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlices, eEclubs } from "../features/slices/api-slices";
import { setupListeners } from "@reduxjs/toolkit/query";
import { productAPI } from "../features/api/products-api";
import productsSlice from "../features/slices/products-slice";
import authSlice from "../features/slices/auth-slice";
import commonApi from "./common-api";

const rootReducer = combineReducers({
    // Define your slices here
    product: productsSlice,
    auth: authSlice,
    [productAPI.reducerPath]: productAPI.reducer,
    [apiSlices.reducerPath]: apiSlices.reducer,
    [eEclubs.reducerPath]: eEclubs.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    // Add other slices here
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlices.middleware).concat(productAPI.middleware).concat(eEclubs.middleware).concat(commonApi.middleware),
});

setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export default store;
