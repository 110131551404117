import commonApi from '../../redux/common-api';

export const ebookApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        getEbooks: builder.query({
            query: () => `ebook`,
            providesTags: [{ type: "ebook" }, { type: "ad" }],
        }),
        getMyEbooks: builder.query({
            query: (id: string) => `ebook/${id}`,
            providesTags: ["ebook", "ad"],
        }),
        createEbook: builder.mutation({
            query: (ebook) => ({
                url: `ebook`,
                method: "POST",
                body: ebook,
            }),
            invalidatesTags: ["ebook"],
           
        }),
        buyEbook: builder.mutation<any, any>({
            query: (data) => ({
                url: `ebook/buy-ebook`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["ebook"],
            
        }),
        getEClubs: builder.query<any, void>({
            query: () => `ebook/e-clubs`,
            providesTags: ["e-club"],
        }),
    }),
});

export const { useGetEbooksQuery, useGetMyEbooksQuery, useCreateEbookMutation, useBuyEbookMutation, useGetEClubsQuery } = ebookApi;
