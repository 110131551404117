import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import DragDropFile from "../../components/common/ui/drag-drop-upload";
import { useGetProfileQuery, useUpdateDataMutation } from "../../features/slices/api-slices";
import { Link } from "react-router-dom";
import ProfileSettingsAddressForm from "../../components/common/forms/profile-settings-address-form";
import { useToast } from "../../components/shared/ToastContext";


interface FormValues {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    bio: string;
}


export default function ProfileSettingsPage() {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const [avatar, setAvatar] = useState<File | null>(null);
    const { showToast } = useToast();
    const [updateData, { isLoading, isError }] = useUpdateDataMutation();
    const { data: user } = useGetProfileQuery();

    const [files, setFiles] = useState<File[]>([]);

    const handleFilesAdded = (newFiles: File[]) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };
    
    const onSubmit = async (data: any) => {
        try {
            await updateData({
                ...data,
                avatar
            }).unwrap();
            showToast("Data updated successfully", "success", "Your Profile has been successfully updated.");
        } catch (error) {
            showToast("Error updating data", "error", "Error updating data");
        }
    };

    useEffect(() => {
        if (isError) {
            showToast("Error fetching data", "error", "Error fetching data");
        }

        if (user) {
            setValue("first_name", user.name?.split(" ")[0]);
            setValue("last_name", user.name?.split(" ")[1]);
            setValue("email", user.email);
            setValue("phone", user.phone);
            setValue("bio", user.bio);
            setAvatar(user.avatar);
        }

    }, [user, setValue, isError]);
    
    return (
        <>
            <div className="w-full bg-white p-8 lg:rounded-3xl">
                <DragDropFile onFileAdded={handleFilesAdded} user={user} setAvatar={setAvatar} />
                <div className="px-4">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug capitalize">{user.name}</h2>
                    <p className="text-left text-zinc-800 text-base font-normal leading-snug capitalize">@{user.name}</p>
                </div>
                {files.length > 0 && (
                    <div>
                        <h2 className="text-xl font-bold">Files</h2>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}

                <form className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.first_name ? "text-red-500" : "text-zinc-800"}`}>First Name *</label>
                        <input type="text" {...register("first_name", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.first_name ? "border-red-500" : "border-gray-200"}`} />
                        {errors.first_name?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">First Name is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.last_name ? "text-red-500" : "text-zinc-800"}`}>Last Name *</label>
                        <input type="text" {...register("last_name", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.last_name ? "border-red-500" : "border-gray-200"}`} />
                        {errors.last_name?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Last Name is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.email ? "text-red-500" : "text-zinc-800"}`}>Email Address *</label>
                        <input type="text" {...register("email", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.email ? "border-red-500" : "border-gray-200"}`} />
                        {errors.email?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Email Address is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.phone ? "text-red-500" : "text-zinc-800"}`}>Contact Number *</label>
                        <input type="text" {...register("phone", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.phone ? "border-red-500" : "border-gray-200"}`} />
                        {errors.phone?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Contact Number is required</p>}
                    </div>
                    <div className="w-full relative 2xl:col-span-2 xl:col-span-2 md:col-span-2 lg:col-span-2 col-span-1">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.bio ? "text-red-500" : "text-zinc-800"}`}>Description *</label>
                        <textarea rows={5} cols={20} {...register("bio", { required: true })} placeholder="bio Description Write Here" className={`w-full resize-none border border-solid p-4 rounded-md outline-none px-4 ${errors.bio ? "border-red-500" : "border-gray-200"}`} />
                        {errors.bio?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">bio is required</p>}
                    </div>
                    <div className="flex justify-end gap-8 2xl:col-span-2 xl:col-span-2 md:col-span-2 lg:col-span-2 col-span-1">
                        <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            {
                                isLoading ? 'Loading...' : 'Save'
                            }
                        </button>
                        <Link to={`/user-panel/profile`} className="w-fit h-[46px] 2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </Link>
                    </div>
                </form>
            </div>

            <ProfileSettingsAddressForm />

            {/* <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Social Profile Links</h2>
                    <h2 className="text-black text-[15px] font-medium mt-6 leading-snug">+ Add Profile</h2>
                </div>
                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <img loading="lazy" src="/assets/images/facebook.png" alt="" />
                                    <label htmlFor="" className="text-sm bg-white font-normal">Facebook</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative">
                        <input type="text" placeholder="Add Link Here......" className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4" />
                    </div>
                    <div className="flex justify-end gap-8 col-span-3">
                        <button type="button" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            Save
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    )
}
