
const PaymentInformation = () => {
    return (
        <div className="flex flex-col gap-6 w-full p-6 rounded-[10px] border-gray-100 border-[1px] shadow-sm font-normal">
            <h1 className="text-xl font-bold">Payment information</h1>
            <div className='gap-5 flex flex-col'>
                <div className=''>
                    <p className='font-semibold'>Direct bank transfer</p>
                    <p className="text-sm mt-2">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                </div>
                <hr />
                <div className=''>
                    <p className='font-semibold'>Check Payment</p>
                </div>
                <hr />
                <div className=''>
                    <p className='font-semibold'>Cash on delivery</p>
                </div>
            </div>
        </div>
    )
}

export default PaymentInformation