export default function TotalClub() {
    return (
        <div className="bg-white rounded-3xl col-span-1">
            <div className="w-full h-full p-8 flex flex-col">
                <h3 className="text-3xl md:text-5xl font-bold mb-3">335</h3>
                <h4 className="text-sm font-medium text-neutral-500">
                    Total Club
                </h4>
                <div className="flex gap-2 mt-5 md:mt-auto">
                    <div className="bg-yellow-400 p-1.5 w-2/6 rounded-full"></div>
                    <div className="bg-[#D9D9D9] p-1.5 w-4/6 rounded-full"></div>
                </div>
            </div>
        </div>
    );
}
