import { useForm } from 'react-hook-form';
import AddSimpleProductForm from '../../components/common/ui/forms/add-simple-product-form';
import AddProductDescriptionForm from '../../components/common/ui/forms/add-product-description-form';
import VendorProductTypeForm from '../../components/common/ui/forms/vendor-product-type-form';
import ShopCategoryForm from '../../components/common/ui/forms/shop-category-form';

export default function AddProductPage() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);
    return (
        <>
            <div className='w-full p-8 pt-5 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>Create New Product</h2>

                <h6 className="text-black text-base mt-4 font-normal leading-snug">Featured Image</h6>

                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 pt-5'>
                    <img loading="lazy" className="w-28 h-28 rounded-lg border border-black/opacity-10" alt='Cart' src="https://via.placeholder.com/112x112" />
                    <div className="w-full h-fit py-4 rounded-lg relative flex justify-center items-center bg-zinc-100 border border-dashed border-zinc-300">
                        <div className="flex flex-col gap-1.5 items-center">
                            <img loading="lazy" src="/assets/images/svg/upload.svg" alt="" />
                            <p className="text-neutral-500">
                                <span className="text-black">Upload your image</span> or drag and drop.
                            </p>
                            <span className="text-sm font-normal text-neutral-500">(PNG, JPEG are supported) 400px max size.</span>
                        </div>
                    </div>
                </div>

                <h6 className="text-black text-base mt-4 font-normal leading-snug">Product Gallery</h6>

                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 pt-5'>
                    <img loading="lazy" className="w-28 h-28 rounded-lg border border-black/opacity-10" alt='Cart' src="https://via.placeholder.com/112x112" />
                    <div className="w-full h-fit py-4 rounded-lg relative flex justify-center items-center bg-zinc-100 border border-dashed border-zinc-300">
                        <div className="flex flex-col gap-1.5 items-center">
                            <img loading="lazy" src="/assets/images/svg/upload.svg" alt="" />
                            <p className="text-neutral-500">
                                <span className="text-black">Upload your image</span> or drag and drop.
                            </p>
                            <span className="text-sm font-normal text-neutral-500">(PNG, JPEG are supported) 400px max size.</span>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-8 pt-8">
                    <button type="button" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                        Save
                    </button>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug mb-5'>Video Title</h2>
                <div className="w-full relative">
                    <input type="text" placeholder='Add Video Link Here......' {...register("videotitle", { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.videotitle ? "border-red-500" : "border-gray-200"}`} />
                    {errors.videotitle?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Video Title is required</p>}
                </div>
                <div className="flex justify-end gap-8 w-full pt-8">
                    <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                        Save
                    </button>
                    <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                        Cancel
                    </button>
                </div>
            </form>

           <ShopCategoryForm />
           <AddProductDescriptionForm />
           <VendorProductTypeForm />
            <AddSimpleProductForm />
            <div className="flex justify-end gap-8 pt-8">
                <button type="button" className="w-fit h-[46px] text-center text-white text-xl font-medium leading-snug px-10 py-3 bg-zinc-800 rounded justify-center items-center gap-2.5 inline-flex">
                    Publish
                </button>
                <button type="button" className="w-fit h-[46px] px-10 py-3 bg-transparent text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                    Draft
                </button>
            </div>
        </>
    )
}
