
import { Link } from "react-router-dom";
import Coupon from "../../components/common/dashboard/Coupon";
import SalesComponent from "../../components/common/dashboard/SalesComponent";
import TotalClub from "../../components/common/dashboard/TotalClub";
import SmallPieChart from "../../components/common/dashboard/SmallPieChart";
import CouponRow from "../../components/common/dashboard/CouponRow";
import ProductCard from "../../components/common/product-card";
import Graph from "../../components/common/dashboard/Graph";


export default function Dashboad() {
    return (
        <>
                <div className="grid grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-7">
                    <Graph />
                    <div className="bg-black rounded-3xl col-span-1 row-span-1 md:row-span-2 p-8 h-80 md:h-auto">
                        <div className="w-full h-full flex flex-col">
                            <h2 className="text-4xl md:text-6xl text-yellow-500 font-bold">
                                35%
                            </h2>
                            <h3 className="text-xl text-white mt-2">Object Name</h3>
                            <Link
                                to={""}
                                className="bg-yellow-400 p-2 w-full rounded text-center mt-auto font-bold"
                            >
                                <button>Get Now</button>
                            </Link>
                        </div>
                    </div>
                    <Coupon
                        couponName="Fitness Club Coupon"
                        amount={23456}
                        subHeading={"Total Members"}
                        callToAction="View More Details"
                        heading="Enjoy a 20% Discount on Your Next Visit!"
                    />
                    <SalesComponent />
                    <TotalClub />
                    <Coupon
                        couponName="Silver Membership"
                        amount={15684}
                        subHeading={"Total Members"}
                        callToAction="View More Details"
                        heading="Enjoy a 20% Discount on Your Next Visit!"
                    />
                    <SmallPieChart />
                    <div />
                </div>
                <h2 className="text-2xl md:text-3xl font-bold mb-5">
                    E-Discount Coupon
                </h2>
                <div className="w-full overflow-x-auto mb-5">
                    <CouponRow />
                </div>
                <h2 className="text-2xl md:text-3xl font-bold mb-5">
                    Top Trending Products
                </h2>
                <div className="w-full overflow-x-auto">
                    <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-5 mb-4">
                        <ProductCard
                            productName={"Nike"}
                            amount={200}
                            imageUrl={
                                "https://placehold.co/200x150?text=Placeholder"
                            }
                            ratings={4.3}
                        />
                        <ProductCard
                            productName={"Puma"}
                            amount={120}
                            imageUrl={
                                "https://placehold.co/200x150?text=Placeholder"
                            }
                            ratings={4.8}
                        />
                        <ProductCard
                            productName={"Apple"}
                            amount={300}
                            imageUrl={
                                "https://placehold.co/200x150?text=Placeholder"
                            }
                            ratings={4}
                        />
                        <ProductCard
                            productName={"Zara"}
                            amount={210}
                            imageUrl={
                                "https://placehold.co/200x150?text=Placeholder"
                            }
                            ratings={5}
                        />
                    </div>
                </div>
        </>
    );
}
