import React from 'react'

export default function AnalyticsCard({ smallTitle, title, week, icon }: any) {
  return (
    <>
        <div className='w-full h-fit bg-white rounded-xl p-5'>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-1.5'>
                    <h6 className='text-sm'>
                        {smallTitle}
                    </h6>
                    <h3 className='text-2xl font-bold'>
                        {title}
                    </h3>
                    <p className='text-xs text-gray-500'>
                        {week}
                    </p>
                </div>
                <div className='size-16 bg-yellow-400 flex justify-center items-center rounded-lg'>
                    <img loading="lazy" src={icon} className='size-8' alt={title} />
                </div>
            </div>
        </div>
    </>
  )
}
