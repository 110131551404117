import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";

const data = [
    { name: "Mon", amt: 1000 },
    { name: "Tue", amt: 3000 },
    { name: "Wed", amt: 2000 },
    { name: "Thurs", amt: 4000 },
    { name: "Fri", amt: 3000 },
    { name: "Sat", amt: 5000 },
];

export default function LineChartComponent() {
    return (
        <ResponsiveContainer width="100%" height={250}>
            {/* <LineChart width={500} height={250} data={data} className=""> */}
            <LineChart data={data} className="">
                <Line
                    type="monotone"
                    dataKey="amt"
                    stroke="#FEDF40"
                    strokeWidth={4}
                />
                <XAxis dataKey="name" strokeOpacity={0} />
                <YAxis strokeOpacity={0} />
                <Tooltip />
                <CartesianGrid
                    horizontal={true}
                    vertical={false}
                    stroke="#E0E0E0"
                />
            </LineChart>
        </ResponsiveContainer>
    );
}
