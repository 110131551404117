"use client";
import React, { Fragment } from "react";
import { CiMail } from "react-icons/ci";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedin,
    FaTwitter,
    FaApple,
} from "react-icons/fa6";
import { GrAndroid } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { socialMediaLinks } from "../../utils/links";
import { currencies } from "../../utils/utils";
import { AppState } from "../../redux/store";
import { changeCurrency } from "../../features/slices/auth-slice";
import SubscribeInput from "../common/ui/subscribe-input";
import { Link } from "react-router-dom";
import { CustomSelect } from "../common/custom/select";

const Footer = () => {
    const pathname = window.location.pathname;
    const dispatch = useDispatch();
    const { currency } = useSelector((state: AppState) => state.auth);

    const handleCurrency = (e: string) => {
        dispatch(changeCurrency(e));
    };

    return (
        <Fragment>
            <div
                className={`${(pathname.includes("auth") ||
                    pathname.includes("register") ||
                    pathname.includes("dashboard") ||
                    pathname.includes("profile") ||
                    pathname.includes("admin")) &&
                    "hidden"
                    } w-full bg-gray-100 py-8 px-5 mt-7 border-t border-gray-300`}
            >
                <SubscribeInput />
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 max-w-5xl mx-auto place-items-start gap-16 my-10">
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold">Contact Us</h1>
                        <div className="flex gap-4 items-start">
                            <img loading="lazy" src="/phone.svg" alt="phone" width={30} height={30} />
                            <div className="flex text-xs flex-col gap-2">
                                <p className="font-semibold">M-F 9AM-5PM</p>
                                <p>+1 (209) 227-9427</p>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <CiMail size={30} />
                            <div className="flex text-xs flex-col gap-2">
                                <p className="font-semibold">Need help with your order?</p>
                                <p>support@damndeal.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold">About Damndeal</h1>
                        <ul className="flex text-xs flex-col gap-4">
                            <li>
                                <Link to="#">Track Your Order</Link>
                            </li>
                            <li>
                                <Link to="#">Product Guides</Link>
                            </li>
                            <li>
                                <Link to="/wishlist">Wishlist</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-and-conditions">Terms & Conditions</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold">Customer Support</h1>
                        <ul className="flex text-xs flex-col gap-4">
                            <li>
                                <Link to="/profile/help">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/profile/help">Help Centre </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold text-sm">Mobile Apps</h1>
                        <ul className="flex text-xs flex-col gap-3">
                            <li className="flex gap-2">
                                <FaApple /> iOS App
                            </li>
                            <li className="flex gap-2">
                                <GrAndroid /> Android App
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold text-sm">Follow us</h1>
                        <ul className="flex text-xs gap-3">
                            <li>
                                <Link to={socialMediaLinks.facebook}>
                                    <FaFacebookF />
                                </Link>
                            </li>
                            <li>
                                <Link to={socialMediaLinks.twitter}>
                                    <FaTwitter />
                                </Link>
                            </li>
                            <li>
                                <Link to={socialMediaLinks.instagram}>
                                    <FaInstagram />
                                </Link>
                            </li>
                            <li>
                                <Link to={socialMediaLinks.linkedin}>
                                    <FaLinkedin />
                                </Link>
                            </li>
                        </ul>
                        <h1 className="font-semibold text-sm">Payment methods</h1>
                        <img
                            src="/pay.png"
                            alt="pay"
                            className="w-[250px] h-auto"
                            width={300}
                            height={300}
                        />
                        {/* <AuthorizeNetSeal /> */}
                    </div>
                </div>
                <hr className="w-full" />
                <div className="text-sm gap-3 flex justify-between flex-col-reverse md:flex-row items-center max-w-5xl mx-auto -mb-8">
                    <div className="gap-6 flex items-center">
                        <Link to="#">
                            © {new Date().getFullYear()} Damndeal. All Rights Reserved
                        </Link>
                        <div className="flex gap-3">
                            <Link to="/privacy-policy">Privacy</Link>
                            <span>.</span>
                            <Link to="/terms-and-conditions">Terms</Link>
                            <span>.</span>
                            <Link to="/site-map">Sitemap</Link>
                        </div>
                    </div>
                    <ul className="flex items-center justify-between gap-5 px-5 my-3">
                        <CustomSelect
                            placeholder=""
                            options={currencies}
                            defaultOption={currency}
                            onChange={handleCurrency}
                        />
                        {/* <li className="flex gap-1 justify-center items-center border border-[#EAEAEA] rounded-lg pl-2"> */}

                        {/* </li> */}
                        {/* <li className="flex gap-1 justify-center items-center border border-[#EAEAEA] rounded-lg pl-2"> */}
                        <CustomSelect placeholder="" options={[]} defaultOption="English" onChange={() => { }} />
                        {/* </li> */}
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
