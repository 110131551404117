import { Link } from "react-router-dom";
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Tooltip,
    Cell,
    Label,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const data = [
    {
        name: "Done",
        value: 700,
    },
    {
        name: "Not Done",
        value: 300,
    },
];
const colors = ["#FEDF40", "#D2D2D2"];

export default function SalesComponent() {
    return (
        <div className="bg-white rounded-3xl col-span-1 row-span-2 p-5 flex flex-col justify-between">
            <h2 className="text-2xl font-bold text-center mb-4">Sales</h2>
            <div className="w-full h-52">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#82ca9d"
                            paddingAngle={5}
                            cornerRadius={8}
                            stroke="none"
                        >
                            {data.map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            ))}
                            <Label
                                value="Hello"
                                position="center"
                                className="text-2xl font-semibold"
                                fill="#000000"
                            />
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <p className="text-sm text-center text-[#797979] mb-5 md:mb-0">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            </p>
            <Link to="" className="flex gap-2 w-full justify-center mt-auto">
                <div className="bg-primary rounded-full w-12 h-12 flex hover:scale-110 duration-300 shadow">
                    <FontAwesomeIcon icon={faPlus} className="m-auto h-4" />
                </div>
            </Link>
        </div>
    );
}
