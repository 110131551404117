import React, { useState } from 'react'
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import CurrencyDropdown from '../dropdown/currency-dropdown';
import { FaLinkedin } from 'react-icons/fa6';
import { useSalesfrontCollectionsQuery } from '../../../features/api/products-api';

export default function Footer() {
    const currencies = [
        { code: 'USD', name: 'USD', symbol: '$' },
        { code: 'EUR', name: 'EURO', symbol: '€' },
        { code: 'INR', name: 'INR', symbol: '₹' },
        // Add more currencies as needed
    ];
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);

    const { data } = useSalesfrontCollectionsQuery();
    return (
        <>
            <footer className='w-full p-12 bg-black overflow-x-hidden'>
                <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-2'>
                    <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 grid-cols-1 2xl:gap-40 xl:gap-40 gap-10'>
                        <div className='w-full relative'>
                            <div className='w-0.5 h-6 bg-white rounded-3xl absolute -right-20 top-0'></div>
                            <div className='flex gap-4 items-center'>
                                <img src="/assets/icons/others/free_shipping.svg" alt="" />
                                <p className='text-base uppercase text-white'>
                                    Free Shipping over $99
                                </p>
                            </div>

                            <Link to={`/`}>
                                <img src="/assets/icons/others/logo-white.svg" className='mt-16 mb-6' alt="" /></Link>
                            <p className='text-lg text-primary'>
                                +1 (209) 227-9427
                            </p>
                            <ul className='pt-6 flex flex-col gap-3'>
                                <li className='flex items-center gap-4 text-white'>
                                    <img src="/assets/icons/others/location-yellow.svg" alt="" />
                                    5106 Bay View Cir, Stockton CA 95219
                                </li>
                                <li className='flex items-center gap-4 text-white'>
                                    <img src="/assets/icons/others/mail-yellow.svg" alt="" />
                                    support@damndeal.com
                                </li>
                            </ul>
                            <ul className='flex items-center gap-5 mt-8'>
                                <li>
                                    <Link to={`https://www.twitter.com/damndealinc`} className='size-12 flex justify-center items-center rounded-full border border-solid border-white hover:bg-primary group transition-all ease-in-out duration-500'>
                                        <FaTwitter color='white' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`https://www.facebook.com/damndealinc`} className='size-12 flex justify-center items-center rounded-full border border-solid border-white hover:bg-primary group transition-all ease-in-out duration-500'>
                                        <FaFacebookF color='white' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`https://www.instagram.com/damndeal2020`} className='size-12 flex justify-center items-center rounded-full border border-solid border-white hover:bg-primary group transition-all ease-in-out duration-500'>
                                        <FaInstagram color='white' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`https://www.linkedin.com/company/damndealinc`} className='size-12 flex justify-center items-center rounded-full border border-solid border-white hover:bg-primary group transition-all ease-in-out duration-500'>
                                        <FaLinkedin color='white' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='w-full flex flex-col relative'>
                            <div className='w-0.5 h-6 bg-white rounded-3xl absolute -right-20 top-1'></div>
                            <div className='flex gap-4 justify-start items-center'>
                                <IoMdRefresh className='text-primary' size={25} />
                                <p className='text-base text-white uppercase'>
                                    30 Days money back
                                </p>
                            </div>
                            <p className='text-base mt-11 font-bold text-left text-white'>
                                Top Categories
                            </p>
                            <ul className='flex flex-col gap-y-4 items-start mt-6'>
                                {data?.collections?.map((item: any) => {
                                    const searchQuery = item.handle === 'frontpage' ? 'women' : item.handle;
                                    return (
                                        <li key={item.id}>
                                            <Link to={`/shop?search=${searchQuery}`} className='text-white hover:underline'>
                                                {item.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className='w-full relative'>
                            <div className='w-0.5 h-6 bg-white rounded-3xl absolute -right-20 top-1'></div>
                            <div className='flex gap-4 items-center'>
                                <img src="/assets/icons/others/security.svg" alt="" />
                                <p className='text-base uppercase text-white'>
                                    100% Secure Payment
                                </p>
                            </div>

                            <div className='flex justify-between w-full'>
                                <ul className='flex flex-col gap-y-4 items-start mt-11'>
                                    <h5 className='text-white font-bold mb-5'>Useful Links</h5>
                                    <li>
                                        <Link to="/privacy-policy" className='text-white hover:underline'>Privacy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-and-conditions" className='text-white hover:underline'>Terms</Link>
                                    </li>
                                    <li>
                                        <Link to="/magic-club" className='text-white hover:underline'>Magic Club</Link>
                                    </li>
                                    <li>
                                        <Link to="/buy-ebooks" className='text-white hover:underline'>Buy Ebook</Link>
                                    </li>
                                    <li>
                                        <Link to="/site-map" className='text-white hover:underline'>Sitemap</Link>
                                    </li>
                                    <li>
                                        <Link to="/user-panel/order-details" className='text-white hover:underline'>Order Details</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className='w-full relative'>
                            <div className='flex gap-4 items-center'>
                                <img src="/assets/icons/others/message.svg" alt="" />
                                <p className='text-base uppercase text-white'>
                                    24/7 Dedicated Support
                                </p>
                            </div>
                            <div className='flex flex-col gap-6 items-end'>
                                <h5 className='text-white font-bold mt-10'>Download App</h5>
                                <img src="/assets/icons/others/google_play.png" alt="" />
                                <img src="/assets/icons/others/app_store.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='w-full bg-white py-5'>
                <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5'>
                    <div className='flex 2xl:flex-row xl:flex-row flex-col gap-4 justify-between items-center'>
                        <p>
                            Copyright 2024 Damn Deal. All Rights Reserved.
                        </p>
                        <img src="/assets/icons/others/icons_payment.svg" alt="" />
                        <div className='flex gap-3 cursor-pointer items-center'>
                            <div className='flex items-center gap-2'>
                                <CurrencyDropdown
                                    currencies={currencies}
                                    selectedCurrency={selectedCurrency}
                                    onChange={setSelectedCurrency}
                                />
                            </div>
                            <div className='w-[1px] h-6 bg-black'></div>
                            <div className='flex cursor-pointer items-center gap-2'>
                                <img src="/assets/icons/others/eng.svg" alt="" />
                                <p className='font-thin text-sm'>Eng</p>
                                <MdKeyboardArrowDown />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
