import React from 'react'
import { useForm } from 'react-hook-form';
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function AddSimpleProductForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);
    return (
        <>
            <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                <h2 className='text-black text-[22px] font-medium leading-snug'>Simple Product Information</h2>

                <form onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.price ? "text-red-500" : "text-zinc-800"}`}>Price *</label>
                        <input type="number" placeholder='Furniture Shop' {...register("price",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.price ? "border-red-500" : "border-gray-200"}`} />
                        {errors.price?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Price is required</p>}
                    </div>
                    <div className='w-full relative'>
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.quantity ? "text-red-500" : "text-zinc-800"}`}>Quantity *</label>
                        <div  {...register("quantity",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.quantity ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm text-neutral-400 bg-white font-normal">Choose your category here</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.quantity?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Quantity is required</p>}
                    </div>

                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.saleprice ? "text-red-500" : "text-zinc-800"}`}>Sale Price *</label>
                        <input type="text" placeholder='' {...register("saleprice",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.saleprice ? "border-red-500" : "border-gray-200"}`} />
                        {errors.saleprice?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Sale Price is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.sku ? "text-red-500" : "text-zinc-800"}`}>SKU *</label>
                        <input type="text" placeholder='' {...register("sku",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.sku ? "border-red-500" : "border-gray-200"}`} />
                        {errors.sku?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">SKU is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.width ? "text-red-500" : "text-zinc-800"}`}>Width *</label>
                        <input type="text" placeholder='' {...register("width",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.width ? "border-red-500" : "border-gray-200"}`} />
                        {errors.width?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Width is required</p>}
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.height ? "text-red-500" : "text-zinc-800"}`}>Height *</label>
                        <input type="text" placeholder='' {...register("height",  { required: true })} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.height ? "border-red-500" : "border-gray-200"}`} />
                        {errors.height?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Height is required</p>}
                    </div>

                    <div className="flex justify-between items-center gap-8 col-span-2">
                        <div className='flex items-center gap-2'>
                            <div className='flex items-center gap-2'>
                                <input type="checkbox" />
                                <label htmlFor="" className='text-xs text-neutral-500'>Is Digital</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <input type="checkbox" />
                                <label htmlFor="" className='text-xs text-neutral-500'>Is External</label>
                            </div>
                        </div>
                        <div className='flex items-center gap-8'>
                            <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                Save
                            </button>
                            <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
