
export default function OrderPage() {
    return (
        <>
            <div className="w-full bg-white p-8 pt-7 rounded-3xl mb-8">
                <div className='flex 2xl:flex-row xl:flex-row flex-col justify-between gap-10'>
                    <h2 className="text-black text-[22px] font-medium leading-snug">Order Status</h2>
                    <div className="2xl:w-[544px] xl:w-[544px] h-[46px] px-[25px] py-[15px] bg-neutral-100 rounded-[5px] border border-black/10 justify-start items-center gap-[350px] inline-flex">
                        <input type='text' className="h-4 text-neutral-500 text-sm font-normal border-none outline-none bg-transparent" placeholder='Search By Order ID' />
                        <img loading="lazy" src="/assets/images/svg/search.svg" alt="" />
                    </div>
                    <div className="w-[231px] h-[46px] px-2.5 py-[13px] bg-white rounded-[5px] border border-black/opacity-10 justify-end items-center gap-2.5 inline-flex">
                        <div className="flex-col justify-start items-end inline-flex">
                            <div className="text-black text-sm font-semibold leading-tight">06 June 24 – 06 Aug 24</div>
                        </div>
                        <div className="w-5 h-5 relative">
                            <img loading="lazy" src="/assets/images/svg/arrow-down.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white p-8 pt-7 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium leading-snug">Order lists</h2>
                </div>
                <div className='flex justify-center items-center min-h-screen'>
                    <p>No Orders Yet!</p>
                </div>
                {/* <OrderTable /> */}
            </div>
        </>
    )
}
