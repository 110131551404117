export const socialMediaLinks = {
    'facebook': 'https://www.facebook.com/damndealinc',
    'twitter': 'https://twitter.com/damndealinc',
    'instagram': 'https://www.instagram.com/damndeal2020',
    'linkedin': 'https://www.linkedin.com/company/damndealinc',
};

export const SOCIAL_MEDIA = {
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
    LINKEDIN: 'linkedin',
};

export const userDropdownLinks = [
    {
        title: 'Profile',
        path: '/user-panel/profile',
    },
    {
        title: 'Orders',
        path: '/user-panel/my-orders',
    },
    {
        title: 'Magic Club',
        path: '/user-panel/magic-club',
    },
    {
        title: 'Help',
        path: '/user-panel/need-help',
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

export const vendorDropdownLinks = [
    {
        title: 'Profile',
        path: '/vendor-panel/profile',
    },
    {
        title: 'Coupons',
        path: '/vendor-panel/coupons',
    },
    {
        title: 'Business Management',
        path: '/vendor-panel/business-management',
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

export const adminDropdownLinks = [
    {
        title: 'Profile',
        path: '/admin-panel/profile',
    },
    {
        title: 'Coupons',
        path: '/admin-panel/create',
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

export const influencerDropdownLinks = [
    {
        title: 'Profile',
        path: '/influencer-panel',
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

export const getDropdownLinks = (role: number) => {
    if (role === 2) {
        return vendorDropdownLinks;
    } else if (role === 0) {
        return userDropdownLinks;
    } else if (role === 1) {
        return adminDropdownLinks;
    } else if (role === 3) {
        return influencerDropdownLinks;
    }

    return [];
}