import { Link } from "react-router-dom";
import VendorTable from "../../components/common/ui/vendor-table";
import { useGetMyCouponsQuery } from "../../features/slices/api-slices";
import { useEffect } from "react";
import { useToast } from "../../components/shared/ToastContext";

export default function VendorCouponsPage() {
  const { isLoading, data, error } = useGetMyCouponsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true
  });
  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast("Failed to fetch data. Please try again later.", "error", "Failed to fetching data");
    }
  }, [error])
  
  
  return (
    <>
      <div className="w-full bg-white p-8 pt-7 rounded-3xl">
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <h2 className="text-black text-[22px] font-medium leading-snug">Coupons</h2>
          <button type='button' className="px-5 py-2 bg-yellow-300 rounded-full border justify-start items-start flex">
            <Link to={`/vendor-panel/create-coupons`} className="text-black text-sm font-normal">Create Coupon</Link>
          </button>
        </div>  

        <VendorTable isLoading={isLoading} data={data} />
      </div>
    </>
  )
}
