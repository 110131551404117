import React from 'react'
import MainHeader from '../../components/common/header/main-header'
import BannerSlider from '../../components/common/banner-slider'
import { CouponDealsSection } from '../../components/common/coupons-deals-section'
import { Link } from 'react-router-dom'
import ShopByCategorySection from '../../components/common/ui/Cards/shop-by-categoties'
import BestSellerCategories from '../../components/common/ui/Cards/best-seller'
import TopDealsCard from '../../components/common/ui/Cards/to-deals-card'
import SubscribeBanenr from '../../components/common/ui/Cards/subscribe-card'
import Footer from '../../components/common/footer/footer'
import WeeklyDealsSection from '../../components/common/weekly-deals-section'
import ProductsByTypesCatalog from '../../components/common/products-types-catalog'
import MainBanner from '../../components/common/ui/banners/full-size-banner'
import { BANNERS } from '../../utils/data'
import BannerWrapper from '../../components/common/ui/banners/banner-wrapper'

export default function HomePageMain() {
    return (
        <>
            {/* ======= Header component ======= */}
            <MainHeader />
            {/* ======== Banner slider ===== */}
            <BannerSlider />

            {/* ==== sone electronic cards ====== */}
            <CouponDealsSection />

            {/* ======= Best Weekly Deals ====== */}
            <WeeklyDealsSection />
            <BannerWrapper>
                <MainBanner title={BANNERS.HOME.MAGIC_CLUB.title} bannerImage={BANNERS.HOME.MAGIC_CLUB.bannerUrl} link={BANNERS.HOME.MAGIC_CLUB.link} />
            </BannerWrapper>
            {/* ======== Top selling product ===== */}
            <ProductsByTypesCatalog />

            <ShopByCategorySection />

            <BannerWrapper>
                <MainBanner title={BANNERS.HOME.OUR_EBOOKS.title} bannerImage={BANNERS.HOME.OUR_EBOOKS.bannerUrl} link={BANNERS.HOME.OUR_EBOOKS.link} />
            </BannerWrapper>

            {/* ======= flat 50% of product ====== */}
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-6'>
                <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-6'>
                    <div className='w-full rounded-lg bg-gray-100 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1 className='2xl:text-xl  font-medium'>
                                Flat 50% Off On Electric Products
                            </h1>
                            <Link to={`/shop?search=electronics`} className='text-sm text-gray-500 border-b border-dashed border-gray-500'>View All</Link>
                        </div>
                        <div className='grid grid-cols-2 gap-5 pt-4'>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k1.png" alt="" />
                                <p>
                                    Kitchen Appliances
                                </p>
                            </div>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k2.png" alt="" />
                                <p>
                                    Headphones
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-lg bg-gray-100 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1 className='2xl:text-xl  font-medium'>
                                Up to 50% Off On Beauty
                            </h1>
                            <Link to={`/shop?search=beauty`} className='text-sm text-gray-500 border-b border-dashed border-gray-500'>View All</Link>
                        </div>
                        <div className='grid grid-cols-2 gap-5 pt-4'>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k3.png" alt="" />
                                <p>
                                    Makeup
                                </p>
                            </div>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k4.png" alt="" />
                                <p>
                                    Skin Care
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-lg bg-gray-100 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1 className='2xl:text-xl  font-medium'>
                                Min 50% Off Women s Cothing
                            </h1>
                            <Link to={`/shop?search=women`} className='text-sm text-gray-500 border-b border-dashed border-gray-500'>View All</Link>
                        </div>
                        <div className='grid grid-cols-2 gap-5 pt-4'>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k5.png" alt="" />
                                <p>
                                    Kurtis
                                </p>
                            </div>
                            <div className='flex flex-col gap-3 items-start w-full'>
                                <img src="/assets/images/banner/k6.png" alt="" />
                                <p>
                                    Skin Care
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BestSellerCategories />

            {/* ==== top deals section ====== */}
            <TopDealsCard />
            <SubscribeBanenr />

            <Footer />
        </>
    )
}
