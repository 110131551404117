import React from 'react'
import MainHeader from '../../../components/common/header/main-header'
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft } from "react-icons/md";
import Footer from '../../../components/common/footer/footer';

export default function NGOProfilePage() {
    return (
        <>
            <MainHeader />
            <main className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5 pt-14'>
                <div className='flex 2xl:flex-row xl:flex-row flex-col 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-6'>
                    <img src="/assets/images/profile-2.png" className='size-24' alt="" />
                    <div className='flex flex-col'>
                        <h2 className='2xl:text-xl xl:text-xl text-lg'>
                            Roberto Harrison
                        </h2>
                        <p className='text-gray-500 text-xs'>
                            Unity for Change
                        </p>
                        <ul className='flex 2xl:flex-nowrap xl:flex-nowrap flex-wrap  items-center 2xl:gap-8 xl:gap-8 gap-5 pt-2'>
                            <li className='flex items-center text-xs gap-2'>
                                <IoLocationOutline className='text-lg' />
                                321 Farm Lane, Austin, TX
                            </li>
                            <li className='flex items-center text-xs gap-2'>
                                <HiOutlineMail className='text-lg' />
                                janedoe@freshbites.com
                            </li>
                            <li className='flex items-center text-xs gap-2'>
                                <LuPhoneCall className='text-lg' />
                                (512) 555-1234
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='max-w-screen-lg pt-10'>
                    <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-6'>
                        <div className='w-full p-6 border rounded-md border-solid border-gray-300'>
                            <h2 className='font-thin text-xl'>
                                Today Income
                            </h2>
                            <h5 className='text-lg font-medium mt-2'>
                                $5000
                            </h5>
                        </div>
                        <div className='w-full p-6 border rounded-md border-solid border-gray-300'>
                            <h2 className='font-thin text-xl'>
                                Total Donations
                            </h2>
                            <h5 className='text-lg font-medium mt-2'>
                                $35,000
                            </h5>
                        </div>
                        <div className='w-full p-6 border rounded-md border-solid border-gray-300'>
                            <h2 className='font-thin text-xl'>
                                Follow Us
                            </h2>
                            <div className='flex items-center gap-3 pt-4'>
                                <img src="/assets/images/svg/facebook.svg" className='size-8' alt="" />
                                <img src="/assets/images/svg/instagram.svg" className='size-8' alt="" />
                                <img src="/assets/images/svg/twitter.svg" className='size-8' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className='text-2xl font-medium mt-28 text-center'>
                    Recent Donations
                </h1>
                <div className="w-full h-auto p-8 bg-[#ffefd2] rounded-xl mt-16 relative">
                    <Table />
                </div>
                <div className='flex justify-center pt-10 pb-32'>
                    <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4">
                        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                            <li>
                                <Link to="" className="flex items-center justify-center px-6 h-8 mx-2 leading-tight text-gray-500 bg-white border border-gray-200 rounded">
                                    <MdKeyboardDoubleArrowLeft />
                                    First</Link>
                            </li>
                            <li>
                                <Link to="" className="flex items-center justify-center px-3 h-8 mr-2 leading-tight text-gray-500 bg-white border border-gray-300">1</Link>
                            </li>
                            <li>
                                <Link to="" className="flex items-center justify-center px-3 h-8 mr-2 leading-tight text-gray-500 bg-white border border-gray-300">2</Link>
                            </li>
                            <li>
                                <Link to="" aria-current="page" className="flex items-center justify-center px-3 h-8 mr-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</Link>
                            </li>
                            <li>
                                <Link to="" className="flex items-center justify-center px-3 h-8 mr-2 leading-tight text-gray-500 bg-white border border-gray-300">4</Link>
                            </li>
                            <li>
                                <Link to="" className="flex items-center justify-center px-3 h-8 mr-2 leading-tight text-gray-500 bg-white border border-gray-300">5</Link>
                            </li>
                            <li>
                                <Link to="" className="flex items-center justify-center px-6 h-8 mr-2 leading-tight text-gray-500 bg-white border border-gray-200 rounded">Last
                                    <MdKeyboardDoubleArrowRight />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </main>

            <Footer />
        </>
    )
}




export const Table = () => {
    return (
        <>
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead className="text-xs text-gray-700">
                        <tr>
                            <th className="px-6 text-base py-3">
                                Date
                            </th>
                            <th className="px-6 text-base py-3">
                                Personal Name
                            </th>
                            <th className="px-6 text-base py-3">
                                Club Name
                            </th>
                            <th className="px-6 text-base py-3">
                                Amount
                            </th>
                            <th className="px-6 text-base py-3">
                                Donate Purpose
                            </th>
                            <th className="px-6 text-base py-3">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 15, 2024
                            </th>
                            <td className="px-6 py-4">
                                Josh Sebstani
                            </td>
                            <td className="px-6 py-4">
                                Helping Hands Club
                            </td>
                            <td className="px-6 py-4">
                                $600
                            </td>
                            <td className='px-6 py-4'>
                                Environmental Conservation
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 16, 2024
                            </th>
                            <td className="px-6 py-4">
                                Sarah Johnson
                            </td>
                            <td className="px-6 py-4">
                                Education First Club
                            </td>
                            <td className="px-6 py-4">
                                $1500
                            </td>
                            <td className='px-6 py-4'>
                                Medical Supplies for Rural Areas
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 17, 2024
                            </th>
                            <td className="px-6 py-4">
                                John Doe
                            </td>
                            <td className="px-6 py-4">
                                Future Builders Club
                            </td>
                            <td className="px-6 py-4">
                                $3,0000
                            </td>
                            <td className='px-6 py-4'>
                                Youth Empowerment Programs
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 18, 2024
                            </th>
                            <td className="px-6 py-4">
                                Maria Gonzalez
                            </td>
                            <td className="px-6 py-4">
                                Healthy Living Club
                            </td>
                            <td className="px-6 py-4">
                                $800
                            </td>
                            <td className='px-6 py-4'>
                                Nutrition Awareness Campaign
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 19, 2024
                            </th>
                            <td className="px-6 py-4">
                                Thompson Family
                            </td>
                            <td className="px-6 py-4">
                                Education First Club
                            </td>
                            <td className="px-6 py-4">
                                $5600
                            </td>
                            <td className='px-6 py-4'>
                                Shelter and Care for Stray Animals
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 16, 2024
                            </th>
                            <td className="px-6 py-4">
                                John Doe
                            </td>
                            <td className="px-6 py-4">
                                Future Builders Club
                            </td>
                            <td className="px-6 py-4">
                                $3000
                            </td>
                            <td className='px-6 py-4'>
                                Youth Empowerment Programs
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 15, 2024
                            </th>
                            <td className="px-6 py-4">
                                Josh Sebstani
                            </td>
                            <td className="px-6 py-4">
                                Helping Hands Club
                            </td>
                            <td className="px-6 py-4">
                                $600
                            </td>
                            <td className='px-6 py-4'>
                                Environmental Conservation
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 16, 2024
                            </th>
                            <td className="px-6 py-4">
                                Sarah Johnson
                            </td>
                            <td className="px-6 py-4">
                                Education First Club
                            </td>
                            <td className="px-6 py-4">
                                $1500
                            </td>
                            <td className='px-6 py-4'>
                                Medical Supplies for Rural Areas
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 17, 2024
                            </th>
                            <td className="px-6 py-4">
                                John Doe
                            </td>
                            <td className="px-6 py-4">
                                Future Builders Club
                            </td>
                            <td className="px-6 py-4">
                                $3,0000
                            </td>
                            <td className='px-6 py-4'>
                                Youth Empowerment Programs
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 18, 2024
                            </th>
                            <td className="px-6 py-4">
                                Maria Gonzalez
                            </td>
                            <td className="px-6 py-4">
                                Healthy Living Club
                            </td>
                            <td className="px-6 py-4">
                                $800
                            </td>
                            <td className='px-6 py-4'>
                                Nutrition Awareness Campaign
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 19, 2024
                            </th>
                            <td className="px-6 py-4">
                                Thompson Family
                            </td>
                            <td className="px-6 py-4">
                                Education First Club
                            </td>
                            <td className="px-6 py-4">
                                $5600
                            </td>
                            <td className='px-6 py-4'>
                                Shelter and Care for Stray Animals
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                        <tr className="">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Aug 16, 2024
                            </th>
                            <td className="px-6 py-4">
                                John Doe
                            </td>
                            <td className="px-6 py-4">
                                Future Builders Club
                            </td>
                            <td className="px-6 py-4">
                                $3000
                            </td>
                            <td className='px-6 py-4'>
                                Youth Empowerment Programs
                            </td>
                            <td className='px-6 py-4'>
                                <span className='text-sm underline cursor-pointer'>View Details</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </>
    )
}