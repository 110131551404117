import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

interface Currency {
  code: string;
  name: string;
  symbol: string;
}

interface CurrencyDropdownProps {
  currencies: Currency[];
  selectedCurrency: Currency;
  onChange: (currency: Currency) => void;
}

const CurrencyDropdown: React.FC<CurrencyDropdownProps> = ({ currencies, selectedCurrency, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (currency: Currency) => {
    onChange(currency);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-center items-center gap-x-1 w-full px-4 pr-0 py-2 text-sm font-thin text-gray-700 bg-white border-none focus:outline-none"
        >
         {selectedCurrency.symbol}  {selectedCurrency.name}
         <MdKeyboardArrowDown className='text-lg' />
        </button>
      </div>
      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-28 origin-top-right bg-white rounded-lg focus:outline-none">
          <div className="p-1">
            {currencies.map((currency) => (
              <button
                key={currency.code}
                onClick={() => handleSelect(currency)}
                className="block w-full px-4 py-2 text-xs text-gray-900 hover:bg-gray-100 focus:outline-none"
              >
               {currency.symbol} {currency.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyDropdown;
