import { useForm } from 'react-hook-form';
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function VendorProductTypeForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);
    return (
        <>
            <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                <div className="flex justify-between items-center">
                    <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Product Type</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">

                    <div className='w-full relative col-span-2'>
                        <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.producttype ? "text-red-500" : "text-zinc-800"}`}>Product Type *</label>
                        <div  {...register("producttype", { required: true })}  className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.producttype ? "border-red-500" : "border-gray-200"}`}>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center h-14 gap-2">
                                    <label htmlFor="" className="text-sm text-neutral-400 bg-white font-normal">Simple Product</label>
                                </div>
                                <MdKeyboardArrowDown className="text-xl" />
                            </div>
                        </div>
                        {errors.producttype?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Product Type is required</p>}
                    </div>
                    
                    <div className="flex justify-end gap-8 col-span-2">
                        <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug px-10 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                            Save
                        </button>
                        <button type="button" className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
