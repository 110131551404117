import React, { useState } from 'react'
import MainHeader from '../../../components/common/header/main-header'
import Footer from '../../../components/common/footer/footer';
import { useGetAllCouponsQuery } from '../../../features/slices/api-slices';
import ProductSkeleton from '../../../components/shared/skeleton/ProductSkeleton';
import moment from 'moment';

export default function ShopSmartPage() {
    const [activeTab, setActiveTab] = useState(1);
    const { isLoading, data } = useGetAllCouponsQuery()

    console.log(data);

    // Function to render content based on the active tab

    return (
        <>
            <MainHeader />
            <main className='w-full relative'>
                <img src="/assets/images/shop-smart.png" className='w-full 2xl:h-auto xl:h-auto h-36' alt="" />
                <div className='absolute top-0 left-0 right-0 2xl:pt-32 xl:pt-28 pt-10'>
                    <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-10 px-5'>
                        <h1 className='2xl:text-4xl text-white xl:text-4xl text-2xl font-bold 2xl:w-[582px] xl:w-[582px] 2xl:leading-[55px] xl:leading-[55px] w-full'>
                            Shop Smart with Our Best Coupon Deals!
                        </h1>
                    </div>
                </div>
            </main>

            {/* ======= Tabs section code here ======= */}
            <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-10 px-5 pt-10 pb-24'>
                <div className='flex items-center gap-6 border-b border-solid border-primary px-4'>
                    <button onClick={() => setActiveTab(1)} className={`px-8 py-2.5 text-sm rounded-t-xl border border-solid border-b-0 ${activeTab === 1 ? "active border-primary" : "bg-gray-100 border-gray-200"}`}>
                        All
                    </button>
                    <button onClick={() => setActiveTab(2)} className={`px-8 py-2.5 text-sm rounded-t-xl border border-solid border-b-0 ${activeTab === 2 ? "active border-primary" : "bg-gray-100 border-gray-200"}`}>
                        In Store
                    </button>
                    <button onClick={() => setActiveTab(3)} className={`px-8 py-2.5 text-sm rounded-t-xl border border-solid border-b-0 ${activeTab === 3 ? "active border-primary" : "bg-gray-100 border-gray-200"}`}>
                        Online
                    </button>
                </div>
                {/* ===== content here ========= */}
                {activeTab === 1 && (
                    <>
                     <img src="/assets/images/banner6.png" className='w-full mt-8' alt="" />
                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 gap-4 mt-8'>
                            {isLoading ?
                                // Add skeletons here
                                Array(4).fill(0).map((_, i) => (
                                    <ProductSkeleton key={i} />
                                ))
                                : data?.slice(0, 19)?.map((item: any, index: number) => (
                                    <>
                                        <div className="w-full h-fit bg-amber-400 rounded-lg p-5 mb-4 overflow-hidden">
                                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-4 justify-between items-start w-full relative'>
                                                <span className={`text-white text-base font-normal flex items-center gap-2`}>Code: {item._id}</span>
                                                <span className={`text-white text-sm font-normal flex items-center gap-2`}>Expire On:  {moment(item.couponExpiry).format('DD-MM-YYYY')} </span>
                                             
                                            </div>

                                            <div className="w-[211px] text-zinc-800 text-2xl font-medium relative mt-3">
                                                {item.productTitle}
                                            </div>
                                            <div className='flex items-center gap-6 pt-6'>
                                                <div className='flex flex-col'>
                                                    <h4 className=''>
                                                        Online Store: Amazon
                                                    </h4>
                                                    <p className='text-xs'>
                                                        Website: www.amazon.in
                                                    </p>
                                                </div>
                                                <button type="button" className='w-fit px-6 py-2 bg-black text-sm text-white rounded-full'>Redeem Now</button>
                                            </div>
                                        </div>
                                    </>


                                ))
                            }
                        </div>
                        <img src="/assets/images/banner2.png" className='w-full mt-8' alt="" />
                    </>
                )}
                {activeTab === 2 && (
                    <>
                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 gap-4 mt-8'>
                            {isLoading ?
                                // Add skeletons here
                                Array(4).fill(0).map((_, i) => (
                                    <ProductSkeleton key={i} />
                                ))
                                : data?.slice(0, 19)?.map((item: any, index: number) => (
                                    <>
                                        <div className="w-full h-fit bg-amber-400 rounded-lg p-5 mb-4 overflow-hidden">
                                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-4 justify-between items-start w-full relative'>
                                                <span className={`text-white text-base font-normal flex items-center gap-2`}>Code: {item._id}</span>
                                                <span className={`text-white text-sm font-normal flex items-center gap-2`}>Expire On:  {moment(item.couponExpiry).format('DD-MM-YYYY')} </span>
                                               
                                            </div>

                                            <div className="w-[211px] text-zinc-800 text-2xl font-medium relative mt-3">
                                                {item.productTitle}
                                            </div>
                                            <div className='flex items-center gap-6 pt-6'>
                                                <div className='flex flex-col'>
                                                    <h4 className=''>
                                                        Online Store: Amazon
                                                    </h4>
                                                    <p className='text-xs'>
                                                        Website: www.amazon.in
                                                    </p>
                                                </div>
                                                <button type="button" className='w-fit px-6 py-2 bg-black text-sm text-white rounded-full'>Redeem Now</button>
                                            </div>
                                        </div>
                                    </>


                                ))
                            }
                        </div>
                    </>
                )}
                {activeTab === 3 && (
                    <>
                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 gap-4 mt-8'>
                            {isLoading ?
                                // Add skeletons here
                                Array(4).fill(0).map((_, i) => (
                                    <ProductSkeleton key={i} />
                                ))
                                : data?.slice(0, 19)?.map((item: any, index: number) => (
                                    <>
                                        <div className="w-full h-fit bg-amber-400 rounded-lg p-5 mb-4 overflow-hidden">
                                            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-4 justify-between items-start w-full relative'>
                                                <span className={`text-white text-base font-normal flex items-center gap-2`}>Code: {item._id}</span>
                                                <span className={`text-white text-sm font-normal flex items-center gap-2`}>Expire On:  {moment(item.couponExpiry).format('DD-MM-YYYY')} </span>
                                             
                                            </div>

                                            <div className="w-[211px] text-zinc-800 text-2xl font-medium relative mt-3">
                                                {item.productTitle}
                                            </div>
                                            <div className='flex items-center gap-6 pt-6'>
                                                <div className='flex flex-col'>
                                                    <h4 className=''>
                                                        Online Store: Amazon
                                                    </h4>
                                                    <p className='text-xs'>
                                                        Website: www.amazon.in
                                                    </p>
                                                </div>
                                                <button type="button" className='w-fit px-6 py-2 bg-black text-sm text-white rounded-full'>Redeem Now</button>
                                            </div>
                                        </div>
                                    </>


                                ))
                            }
                        </div>
                    </>
                )}
            </div>


            <Footer />
        </>
    )
}
