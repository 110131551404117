import Divider from "../../components/common/ui/divider";
import { useShopifyCustomerOrdersListQuery } from "../../features/api/products-api";

export default function MyOrderPage() {
    const { data } = useShopifyCustomerOrdersListQuery();
    console.log(data);
    
    return (
            <div className='w-full h-fit'>
                <div className="flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-10">
                    <div className='2xl:w-2/3 xl:w-2/3 md:w-2/3 lg:w-2/3 w-full'>
                        <div className="w-full p-5 2xl:h-96 xlh-96 h-fit rounded-3xl bg-white">
                            <div className="flex justify-between items-center">
                                <h2 className="text-black text-[18px] font-medium leading-snug">Order No : 35748564BD</h2>
                                <p className="text-neutral-400 border-b border-dashed cursor-pointer">
                                    View More
                                </p>
                            </div>
                            <div className="text-neutral-500 text-lg font-normal mt-3 leading-snug">Date: May 4, 2024</div>

                            <div className="grid 2xl:grid-cols-3 pt-8 xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10">
                                <div className="w-full h-[134px] px-5 pt-[25px] pb-10 bg-white rounded-[10px] border border-black/10 flex-col justify-start items-start gap-[15px] inline-flex">
                                    <div className="text-zinc-800 text-base font-normal leading-snug">Shipping Address</div>
                                    <div className="text-neutral-500 text-sm font-normal">332 Prospect Avenue, Port Lenna 57906</div>
                                </div>
                                <div className="w-full h-[134px] px-5 pt-[25px] pb-10 bg-white rounded-[10px] border border-black/10 flex-col justify-start items-start gap-[15px] inline-flex">
                                    <div className="text-zinc-800 text-base font-normal leading-snug">Billing Address</div>
                                    <div className="text-neutral-500 text-sm font-normal">332 Prospect Avenue, Port Lenna 57906</div>
                                </div>
                                <div className="w-full h-[134px] px-5 pt-[25px] pb-10 bg-white rounded-[10px] border border-black/10 flex-col justify-start items-start gap-[15px] inline-flex">
                                    <div className="text-zinc-800 text-base font-normal leading-snug">Payment Method</div>
                                    <div className="text-neutral-500 text-sm font-normal">Cash on Payment</div>
                                </div>
                            </div>

                            <div className="w-full relative pt-12">
                                <Divider />
                                <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 relative grid-cols-5 2xl:gap-20 xl:gap-20 gap-10">
                                    <div className="flex flex-col w-full">
                                        <div className="size-12 flex justify-center items-center bg-yellow-300 text-xl font-bold relative -top-6 rounded-lg">
                                            1
                                        </div>
                                        <span className="text-xs">Confirm Order</span>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="size-12 flex justify-center items-center bg-white border border-dashed border-gray-300 text-xl font-bold relative -top-6 rounded-lg">
                                            2
                                        </div>
                                        <span className="text-xs text-neutral-500">Processing</span>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="size-12 flex justify-center items-center bg-white border border-dashed border-gray-300 text-xl font-bold relative -top-6 rounded-lg">
                                            3
                                        </div>
                                        <span className="text-xs text-neutral-500">Product Disp</span>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="size-12 flex justify-center items-center bg-white border border-dashed border-gray-300 text-xl font-bold relative -top-6 rounded-lg">
                                            4
                                        </div>
                                        <span className="text-xs text-neutral-500">Out For Deliv</span>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="size-12 flex justify-center items-center bg-white border border-dashed border-gray-300 text-xl font-bold relative -top-6 rounded-lg">
                                            5
                                        </div>
                                        <span className="text-xs text-neutral-500">Completed</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full p-5 rounded-3xl bg-white mt-8">
                            <h2 className="text-black text-[18px] font-medium leading-snug">Order Item Details</h2>

                            <div className="relative overflow-x-auto mt-6 sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500">
                                    <thead className="text-base font-normal text-gray-700 capitalize bg-white">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Default
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Product Name
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Price/Unit
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Quantity
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Total Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border" src="https://via.placeholder.com/70x70" alt="Default" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $10.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $10.00
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border" src="https://via.placeholder.com/70x70" alt="Default" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $10.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $100.00
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border" alt="Default" src="https://via.placeholder.com/70x70" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $200.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $60.00
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border" alt="Default" src="https://via.placeholder.com/70x70" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $80.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $200.00
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border"  alt="Default" src="https://via.placeholder.com/70x70" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $400.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $600.00
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <td className="p-4">
                                                <img className="w-[70px] h-[70px] rounded-[7px] border" alt="Default" src="https://via.placeholder.com/70x70" />
                                            </td>
                                            <td className="px-6 py-4 text-gray-900 flex flex-col">
                                                <span>CASHMERE-01 HEEL</span>
                                                <span className='text-neutral-400'>Footwear Shop</span>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900">
                                                $10.00
                                            </td>
                                            <td className="px-6 py-4">
                                                <span>1</span>
                                            </td>
                                            <td className='px-6 py-4 font-medium text-gray-900'>
                                                $20.00
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div className="2xl:w-2/5 xl:w-2/5 md:w-2/5 lg:w-2/5 w-full">
                        <div className="w-full bg-white p-14 rounded-3xl">
                            <h2 className="text-black text-[18px] font-medium leading-snug">Order Payment Details</h2>
                            <div className="flex justify-between items-center pt-6">
                                <span>Sub Total : </span>
                                <span className="text-neutral-500">$20</span>
                            </div>
                            <div className="flex justify-between items-center pt-3">
                                <span>Tax : </span>
                                <span className="text-neutral-500">$0.0</span>
                            </div>
                            <div className="flex justify-between items-center pt-3">
                                <span>Shipping Charge : </span>
                                <span className="text-neutral-500">$50.00</span>
                            </div>
                            <div className="flex justify-between items-center pt-3">
                                <span>Discount :  </span>
                                <span className="text-neutral-500">$0.0</span>
                            </div>
                            <div className="flex justify-between items-center pt-6">
                                <span>Order Total :  </span>
                                <span className="text-neutral-500">$70.00</span>
                            </div>
                        </div>

                        <div className="w-full bg-white p-12 pt-10 rounded-3xl mt-12">
                            <div className="flex justify-between items-center">
                                <h2 className="text-black text-[18px] font-medium leading-snug">My Order</h2>
                                <p className="text-neutral-400 border-b border-dashed cursor-pointer">
                                    View More
                                </p>
                            </div>
                            <div className="w-full h-fit p-4 border border-solid border-gray-300 mt-6 rounded-3xl">
                                <h2 className="text-black text-[15px] font-medium leading-snug">Order No : 12345678BG</h2>
                                <div className="w-fit h-[21px] px-[15px] mt-4 py-[5px] bg-white rounded-[100px] border border-red-500 justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-center text-red-500 text-[9px] font-normal">On Processing</div>
                                </div>
                                <div className="flex justify-between items-center pt-6">
                                    <span>Order Date : </span>
                                    <span className="text-neutral-500">June 3, 2024</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Delivery Date : </span>
                                    <span className="text-neutral-500">Express</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Amount : </span>
                                    <span className="text-neutral-500">$20.00</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Total Price :  </span>
                                    <span className="text-neutral-500">$70.00</span>
                                </div>

                            </div>
                            <div className="w-full h-fit p-4 border border-solid border-gray-300 mt-6 rounded-3xl">
                                <h2 className="text-black text-[15px] font-medium leading-snug">Order No : 12345678BG</h2>
                                <div className="w-fit h-[21px] px-[15px] mt-4 py-[5px] bg-white rounded-[100px] border border-red-500 justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-center text-red-500 text-[9px] font-normal">On Processing</div>
                                </div>
                                <div className="flex justify-between items-center pt-6">
                                    <span>Order Date : </span>
                                    <span className="text-neutral-500">June 3, 2024</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Delivery Date : </span>
                                    <span className="text-neutral-500">Express</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Amount : </span>
                                    <span className="text-neutral-500">$20.00</span>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <span>Total Price :  </span>
                                    <span className="text-neutral-500">$70.00</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
