export const API_URL = process.env.REACT_APP_API_URL || "https://damndeal.com/api"
export const currencies = ["USD", "EUR", "INR"];
export const getCurrencySymbol = (currency: String) => {
    switch (currency) {
        case "USD":
            return "$";
        case "EUR":
            return "€";
        case "INR":
            return "₹";
        // case "AUD":
        //   return "A$";
        // case "CAD":
        //   return "C$";
        default:
            return "$";
    }
}

export const formatCurrency = (amount: number, currency = "USD") => {
    const formatted = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: currency,
    }).format(amount);
    const currencySymbol = getCurrencySymbol(currency);
    return formatted.replace("$", currencySymbol);
}

export const TAX_PERCENTAGE = 0.09;

export const getProductTax = (price: number) => {
    return price * TAX_PERCENTAGE;
}

export const getCouponAddress = (coupon: any) => {
    let address1 = coupon?.address1 || coupon?.address_1;
    let address2 = coupon?.address2 || coupon?.address_2;
    let zipcode = coupon?.zipcode || coupon?.zip;
    return `${address1}, ${address2?.trim() ? address2 + ", " : ""}${coupon?.city}, ${coupon?.state}, ${zipcode}`;
}

export const getCouponDiscount = (discount: any, discountType: string) => {
    if (discountType === "percentage") {
        return `${discount}% off`;
    }
    else if (discountType === "free") {
        return "Free";
    }
    else {
        return `${formatCurrency(parseInt(discount))} off`;
    }
}
export const getSubtotal = (cart: any) => {
    return cart.reduce((acc: any, item: any) => acc + item.price * item.quantity, 0);
}
