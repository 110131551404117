import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Checkbox from "../ui/checkbox";
import { useShopContext } from "../../ShopContext";
import { useGetAvailableFiltersQuery } from "../../../features/api/products-api";

type CheckboxItem = {
    id: string;
    label: string;
};


const priceRanges: CheckboxItem[] = [
    { id: '0-10', label: 'Under $10' },
    { id: '0-50', label: 'Under $50' },
    { id: '0-100', label: 'Under $100' },
    { id: '0-200', label: 'Under $200' },
    { id: '0-500', label: 'Under $500' },
    { id: '0-3000', label: 'Under $3000' },
];


const ratings: CheckboxItem[] = [
    { id: 'rating_2_and_above', label: '2.0 and above' },
    { id: 'rating_3_and_above', label: '3.0 and above' },
    { id: 'rating_3_5_and_above', label: '3.5 and above' },
    { id: 'rating_4_and_above', label: '4.0 and above' },
    { id: 'rating_4_5_and_above', label: '4.5 and above' },
];

export const CategoryFilters: React.FC = () => {
    const { filters, applyFilter } = useShopContext();
    const { isLoading, data, isError } = useGetAvailableFiltersQuery()

    const handleCheckboxChange = (filterType: keyof typeof filters, id: string) => {
        applyFilter(filterType, id);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error while loading filters</div>;
    }
    
    return (
        <div className='w-full bg-gray-100 rounded-2xl p-5 mt-6'>
            <FilterSection
                title="Category"
                items={data.productTypes?.map((type: any) => ({ id: type, label: type }))}
                isChecked={(id: string) => filters.category.includes(id)}
                handleCheckboxChange={(id) => handleCheckboxChange('category', id)}
            />
            <FilterSection
                title="Price Range"
                items={priceRanges}
                isChecked={(id: string) => filters.priceRange.includes(id)}
                handleCheckboxChange={(id) => handleCheckboxChange('priceRange', id)}
            />
            <FilterSection
                title="Brands"
                items={data.brands?.map((type: any) => ({ id: type, label: type }))}
                isChecked={(id: string) => filters.brands.includes(id)}
                handleCheckboxChange={(id) => handleCheckboxChange('brands', id)}
            />
            <FilterSection
                title="Outer Material Type"
                items={data.materialType?.map((type: any) => ({ id: type, label: type }))}
                isChecked={(id: string) => filters.materialType.includes(id)}
                handleCheckboxChange={(id) => handleCheckboxChange('materialType', id)}
            />
            <FilterSection
                title="Rating"
                items={ratings}
                isChecked={(id: string) => filters.rating.includes(id)}
                handleCheckboxChange={(id) => handleCheckboxChange('rating', id)}
            />
        </div>
    );
};


interface FilterSectionProps {
    title: string;
    items: { id: string, label: string }[];
    isChecked: (id: string) => boolean;
    handleCheckboxChange: (id: string) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({ title, items, isChecked, handleCheckboxChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div className='w-full'>
            <div className='flex justify-between items-center cursor-pointer' onClick={toggleOpen}>
                <h3 className='text-lg font-medium tracking-wider'>{title}</h3>
                {isOpen ? <IoIosArrowUp className='text-lg' /> : <IoIosArrowDown className='text-lg' />}
            </div>
            {isOpen && (
                <div className="flex flex-col gap-y-4 mt-4">
                    {items?.map((item) => (
                        <Checkbox
                            key={item.id}
                            checked={isChecked(item.id)}
                            id={item.id}
                            label={item.label}
                            onChange={() => handleCheckboxChange(item.id)}
                        />
                    ))}
                </div>
            )}
            <div className='border-t border-solid border-gray-300 mt-4 mb-3'></div>
        </div>
    );
};

export default FilterSection;

