import { HiOutlineMail } from "react-icons/hi";
import { useToast } from "../../shared/ToastContext";
import { useState } from "react";

const h1 = "Subscribe for daily updates";

const SubscribeInput = () => {
    const { showToast } = useToast()
    const [email, setEmail] = useState("");

    const handleSubscribe = () => {
        if (!email) {
            return showToast("Email is required", "error", "Email is required")
        }
        if (!email.includes("@")) {
            return showToast("Invalid email address", "error", "Invalid email address")
        }
        setEmail("");
        showToast("Subscribed successfully", "success", "Subscribed successfully")
    }

    return (
        <div className=" md:w-[500px] mx-auto space-y-3 text-center">
            <h1 className="text-xl font-semibold">{h1}</h1>
            <div className="w-full relative">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email address"
                    className="bg-white h-14 border border-solid border-black/10 bg-transparent focus:outline-none w-full py-3 px-5 rounded-lg text-sm"
                />
                <button className="text-sm font-semibold absolute top-0 -right-0 bg-custom-yellow w-fit inline-flex gap-3 items-center justify-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-14 px-4 py-2 rounded-[7px]" onClick={handleSubscribe}>
                    <HiOutlineMail className="text-lg" />
                    Subscribe Now</button>
            </div>
        </div>
    );
};

export default SubscribeInput;
