import React from 'react'
import { DialogProps } from '../../../../../interfaces/interface'

const DeleteDialog: React.FC<DialogProps> = ({ open, title, onClose, onConfirm, children }) => {
    if (!open) return null;
    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                    <div className="flex justify-between items-center p-4">
                        <h2 className="text-xl font-medium">{title}</h2>
                        <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="p-4">
                        {children}
                    </div>
                    <div className="flex justify-end gap-6 p-4 pt-8">
                        <button 
                            type="button" 
                            onClick={onConfirm} 
                            className="w-fit h-[46px] text-center text-white text-xl font-medium leading-snug px-10 py-3 bg-red-500 rounded justify-center items-center gap-2.5 inline-flex"
                        >
                            Confirm
                        </button>
                        <button 
                            type="button" 
                            onClick={onClose} 
                            className="w-fit h-[46px] px-10 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteDialog