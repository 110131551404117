import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRegisterMutation } from "../../../features/slices/api-slices";
import Select from "../ui/select/select";
import { countryDropdownOptions } from "../../../utils/constant";
import { Link } from "react-router-dom";
import { useToast } from '../../shared/ToastContext';
const schema = z.object({
    first_name: z.string().nonempty("First name is required"),
    last_name: z.string().nonempty("Last name is required"),
    email: z.string().email("Invalid email address"),
    password: z.string().min(6, "Password must be at least 6 characters"),
    confirm_password: z.string().min(6, "Confirm password is required"),
    country: z.string().nonempty("Country is required"),
    address_1: z.string().nonempty("Address is required"),
    address_2: z.string().optional(),
    city: z.string().nonempty("City is required"),
    state: z.string().nonempty("State is required"),
    zip: z.string().nonempty("Zip code is required"),
    phone: z.string().nonempty("Phone number is required"),
});

type FormData = z.infer<typeof schema>;

const VendorRegisterationForm: React.FC = () => {
    const { register, handleSubmit, formState, control } = useForm<FormData>({
        resolver: zodResolver(schema),
        mode: "onBlur",
    });
    const { errors } = formState;

    const [registerUser, { isLoading, isSuccess, isError, error }] = useRegisterMutation();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const refId = params.get("refId");
    const { showToast } = useToast();
    useEffect(() => {
        if (refId) {
            window.localStorage.setItem("refId", refId);
        }
    }, [refId]);

    const onSubmit = async (data: FormData) => {
        await registerUser({
            ...data,
            role: 2
        });
    };

    useEffect(() => {
        if (isSuccess) {
            showToast("Registration successful. You can now sign in.", "success", "User registration successful");
            navigate("/auth/signin");
        } else if (isError) {
            showToast(`${(error as any)?.data?.msg}`, "error", "User registration failed");
        }
    }, [isSuccess, isError, error, navigate]);

    return (
        <div className="flex justify-between min-h-screen w-full">
            <div className="flex flex-col md:w-1/2 mx-auto w-full p-5 my-20 md:my-0 lg:py-5 lg:px-10 md:shadow-lg rounded-[10px]">
                <Link to="/" className="flex w-full justify-center items-center my-3">
                    <img loading="lazy" src={'/logo.gif'} alt="logo" className="w-[300px] h-[100px] object-cover" />
                </Link>
                <h3 className="text-xl md:text-4xl text-center font-bold tracking-wide capitalize my-3">Register As Vendor</h3>
                {/* <h1 className="text-xl md:text-4xl font-bold tracking-wide capitalize">Register</h1> */}
                <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-5">
                    <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-4">
                        <div>
                            <label htmlFor="first_name">First Name</label>
                            <input
                                id="first_name"
                                {...register("first_name")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="text"
                            />
                            {errors.first_name && (
                                <span className="text-red-500 text-sm">{errors.first_name.message}</span>
                            )}
                        </div>
                        <div>
                            <label htmlFor="last_name">Last Name</label>
                            <input
                                id="last_name"
                                {...register("last_name")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="text"
                            />
                            {errors.last_name && (
                                <span className="text-red-500 text-sm">{errors.last_name.message}</span>
                            )}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            {...register("email")}
                            className="rounded p-2 border border-gray-300 w-full"
                            type="email"
                        />
                        {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                    </div>
                    <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-4">
                        <div>
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                {...register("password")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="password"
                            />
                            {errors.password && (
                                <span className="text-red-500 text-sm">{errors.password.message}</span>
                            )}
                        </div>
                        <div>
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <input
                                id="confirm_password"
                                {...register("confirm_password")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="password"
                            />
                            {errors.confirm_password && (
                                <span className="text-red-500 text-sm">{errors.confirm_password.message}</span>
                            )}
                        </div>
                    </div>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                            <Select
                                options={countryDropdownOptions}
                                placeholder='Select your country'
                                label='Country *'
                                selectedOption={field.value}
                                setSelectedOption={(value) => field.onChange(value)} />
                        )}
                    />
                    <div>
                        <label htmlFor="address_1">Address</label>
                        <input
                            id="address_1"
                            {...register("address_1")}
                            className="rounded p-2 border border-gray-300 w-full"
                            type="text"
                        />
                        {errors.address_1 && (
                            <span className="text-red-500 text-sm">{errors.address_1.message}</span>
                        )}
                    </div>
                    <div>
                        <label htmlFor="address_2">Address 2 (optional)</label>
                        <input
                            id="address_2"
                            {...register("address_2")}
                            className="rounded p-2 border border-gray-300 w-full"
                            type="text"
                        />
                    </div>

                    <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-4">
                        <div>
                            <label htmlFor="city">City</label>
                            <input
                                id="city"
                                {...register("city")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="text"
                            />
                            {errors.city && (
                                <span className="text-red-500 text-sm">{errors.city.message}</span>
                            )}
                        </div>
                        <div>
                            <label htmlFor="state">State</label>
                            <input
                                id="state"
                                {...register("state")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="text"
                            />
                            {errors.state && (
                                <span className="text-red-500 text-sm">{errors.state.message}</span>
                            )}
                        </div>
                        <div>
                            <label htmlFor="zip">Zip Code</label>
                            <input
                                id="zip"
                                {...register("zip")}
                                className="rounded p-2 border border-gray-300 w-full"
                                type="text"
                            />
                            {errors.zip && (
                                <span className="text-red-500 text-sm">{errors.zip.message}</span>
                            )}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            id="phone"
                            {...register("phone")}
                            className="rounded p-2 border border-gray-300 w-full"
                            type="text"
                        />
                        {errors.phone && (
                            <span className="text-red-500 text-sm">{errors.phone.message}</span>
                        )}
                    </div>

                    <button type="submit" className="bg-[#FF5F00] text-white self-center rounded-full px-10 mt-5 py-3">
                        {isLoading ? "SIGNING UP..." : "SIGN UP"}
                    </button>

                    <div className="flex justify-center items-center gap-1">
                        <span className="text-gray-400">Already have an account?</span>
                        <Link to="/auth/signin" className="text-[#FF5F00]">Sign in</Link>
                    </div>

                    <div className="flex justify-center items-center gap-1">
                        Register as
                        <Link to="/auth/signup?regType=0" className="text-blue-500 underline ml-3">USER</Link>
                        <Link to="/auth/signup?regType=2" className="text-blue-500 underline ml-3">VENDOR</Link>
                        <Link to="/auth/signup?regType=3" className="text-blue-500 underline ml-3">INFLUENCER</Link>
                    </div>
                </form>
            </div>
            <div className="hidden md:inline-flex">
                <img loading="lazy" src="/auth.png" alt="auth" className="h-full object-cover" />
            </div>
        </div>
    );
};

export default VendorRegisterationForm;