import useLocalStorage from '../../../hooks/useLocalStorage';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import { setCartLength } from '../../../features/slices/products-slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import { useState } from 'react';
import DeleteDialog from './forms/dialog/delete-dialog';
import { useToast } from '../../shared/ToastContext';

export default function CartTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cart, setCart] = useLocalStorage("my_cart", []);
    const { cartLength } = useSelector((state: AppState) => state.product);
    const user = useSelector((state: AppState) => state.auth.user);
    const totalAmount = cart.reduce((total: any, item: any) => total + (item.price * item.quantity), 0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const { showToast } = useToast();

    const handleDeleteClick = (id: any) => {
        setIsDialogOpen(true);
        setSelectedItemId(id);
    }

    const handleRemoveCart = async (product: any) => {
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (selectedItemId) {
            try {
                if (cart.find((item: any) => item.id === product.id)) {
                    dispatch(setCartLength(cartLength - 1));
                    setCart((prev: any[]) => prev.filter((item) => item.id !== product.id));
                    return showToast("Product Remove from Cart", "success", "Product Removed!");
                }
            } catch (error) {
                showToast("Error removing product from cart", "error", "Error removing product from cart. Please try again.");
            } finally {
                setIsDialogOpen(false);
                setSelectedItemId(null);
            }
        }

    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setSelectedItemId(null);
    }

    const handleIncreaseQuantity = (product: any) => {
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item.id === product.id)) {
            if (product.quantity >= 10) {
                return console.log("Maximum 10 quantity allowed");
            }
            dispatch(setCartLength(cartLength + 1));
            setCart((prev: any[]) => prev.map((item) => item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item));
            return console.log("Product quantity increased");
        }
    }

    const handleDecreaseQuantity = (product: any) => {
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item.id === product.id)) {
            if (product.quantity <= 1) {
                return console.log("Minimum 1 quantity allowed");
            }
            dispatch(setCartLength(cartLength - 1));
            setCart((prev: any[]) => prev.map((item) => item.id === product.id ? { ...item, quantity: item.quantity - 1 } : item));
            return console.log("Product quantity decreased");
        }
    }


    const handleCheckout = () => {
        if (user) navigate("/checkout")
        else navigate("/auth/signin?redirect=/checkout");
    }
    return (
        <>
            {cart?.length === 0 ? (
                <>
                    <div className="text-center mt-20 flex justify-center items-center h-[70vh]">
                        <div className='flex flex-col items-center gap-5'>
                            <p>Your Cart is empty, Please add one Cart</p>
                            <div className='flex justify-center items-center'>
                                <Link to={`/`} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm flex items-center gap-2'>Back to Home <FaArrowRight /></Link>
                            </div>
                        </div>
                    </div>

                </>
            ) : (
                <>
                    <div className="relative overflow-x-auto mt-6 sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-base font-normal text-gray-700 capitalize bg-white">
                                <tr>
                                    <th className="px-6 py-3">
                                        Image
                                    </th>
                                    <th className="px-6 py-3">
                                        Product Name
                                    </th>
                                    <th className="px-6 py-3">
                                        Price/Unit
                                    </th>
                                    <th className="px-6 py-3">
                                        Quantity
                                    </th>
                                    <th className="px-6 py-3">
                                        Total Amount
                                    </th>
                                    <th className="px-6 py-3">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cart.map((data: any) => (
                                        <>
                                            <tr className="bg-white border-b" key={data.id}>
                                                <td className="p-4">
                                                    <img className="w-[70px] h-[70px] rounded-[7px] border" src={data.src} alt="Data" />
                                                </td>
                                                <td className="px-6 py-4 text-gray-900">
                                                    <span>{data.title.slice(0, 30) + '....'}</span>
                                                    {/* <span className='text-neutral-400'>Footwear Shop</span> */}
                                                </td>
                                                <td className="px-6 py-4 font-semibold text-gray-900">
                                                    ${data.price}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <button className="inline-flex items-center justify-center border-r-0 p-1 text-sm font-medium w-11 px-4 h-9 text-gray-500 bg-white border border-black rounded-l-lg focus:outline-none" type="button" onClick={() => handleDecreaseQuantity(data)}>
                                                            <span className="sr-only">Quantity button</span>
                                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                                            </svg>
                                                        </button>
                                                        <div className="ms-0">
                                                            <input type="text" id="first_product" className="h-9 outline-none w-12 text-center border border-gray-900 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1" placeholder={data.quantity} />
                                                        </div>
                                                        <button className="inline-flex items-center justify-center w-11 h-9 p-1 px-4 text-sm font-medium text-white bg-black border border-black rounded-r-lg border-l-0 focus:outline-none" type="button" onClick={() => handleIncreaseQuantity(data)}>
                                                            <span className="sr-only">Quantity button</span>
                                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 font-medium text-gray-900'>
                                                    ${Number(data.price * data.quantity).toFixed(2)}
                                                </td>
                                                <td onClick={() => handleDeleteClick({ id: data.id })}>
                                                    <div className="size-[39px] cursor-pointer bg-neutral-100 flex justify-center items-center rounded-full">
                                                        <img loading="lazy" src="/assets/images/svg/delete.svg" alt="Data" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <DeleteDialog
                                                open={isDialogOpen}
                                                title="Confirm Deletion"
                                                onClose={handleClose}
                                                onConfirm={() => handleRemoveCart({ id: data.id })}>
                                                <p>Are you sure you want to remove this item from your cart?</p>
                                            </DeleteDialog>
                                        </>
                                    ))
                                }

                            </tbody>
                        </table>

                        <div className='flex justify-end pt-5'>
                            <div>
                                <div className='flex gap-2'>
                                    <span>Subtotal:
                                        <strong> ${Number(totalAmount).toFixed(2)}</strong>
                                    </span>
                                </div>

                                <div className='flex justify-end gap-3 my-5'>
                                    <button onClick={handleCheckout} className='w-fit px-6 py-3.5 rounded-lg bg-black text-white font-medium text-sm flex items-center gap-2'>Proceed to Checkout <FaArrowRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            )}


        </>
    )
}
